import React, { useMemo, useState } from "react"
import PropTypes from "prop-types"

import _ from "lodash"

import { FieldLabel, FormField } from "."
import Tabs from "components/Common/Tabs"

import { MULTILANG_TABS } from "helpers/cms_helper"

const MultiLangFormField = (props) => {
  const { label, name, type, formValues, required, onError, submitted } = props
  const [currentTab, setCurrentTab] = useState("EN")
  const [errors, setErrors] = useState({}) // {fieldName1: "errorMsg1", ...}

  const tabErrors = useMemo(() => {
    // e.g. {EN: true, TC: false, SC: true}
    const errorsClone = _.cloneDeep(errors)
    for (const key in errorsClone) {
      errorsClone[key.slice(-2)] = !!errorsClone[key]
      delete errorsClone[key]
    }
    return errorsClone
  }, [errors])

  const hasError = useMemo(() => {
    for (const tab in tabErrors) {
      if (tabErrors[tab] === true) return true
    }
    return false
  })

  const multiLangFields = useMemo(
    () =>
      MULTILANG_TABS.map((tab) => {
        let field = _.cloneDeep(props)
        field.lang = tab.value
        field.name = `${field?.name}.${field?.lang}`
        return field
      }),
    [props]
  )

  const handleChangeTab = (tab) => {
    setCurrentTab(tab)
  }

  const handleError = (fieldName, errorMsg) => {
    setErrors((prevState) => ({ ...prevState, [fieldName]: errorMsg }))
    onError(fieldName, errorMsg)
  }

  return (
    <>
      {label && (
        <FieldLabel
          name={name}
          label={label}
          required={required}
          hasError={submitted && hasError}
        />
      )}
      <Tabs
        type="field"
        tabs={MULTILANG_TABS}
        currentTab={currentTab}
        onChangeTab={handleChangeTab}
        className={
          ["image", "component"].indexOf(type) !== -1 ? "mb-2" : "mb-0 border-0"
        }
        errors={submitted ? tabErrors : {}}
      />
      {multiLangFields.map((field) => (
        <div
          key={field.name}
          className={
            (field.className ? field.className : "") +
            (field.lang !== currentTab ? " d-none" : "")
          }
        >
          <FormField
            {...field}
            fieldType="form"
            formValues={formValues}
            onError={(errorMsg) => handleError(field.name, errorMsg)}
            hasError={submitted && !!errors[field.name]}
            submitted={submitted}
          />
          {submitted && errors[field.name] && (
            <span className="text-danger">{errors[field.name]}</span>
          )}
        </div>
      ))}
    </>
  )
}

MultiLangFormField.propTypes = {
  type: PropTypes.string,
  formValues: PropTypes.object,
  onError: PropTypes.func,
  submitted: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string,
  required: PropTypes.bool,
}

MultiLangFormField.defaultProps = {
  submitted: false,
}

export default MultiLangFormField

import React from "react"
import PropTypes from "prop-types"

const FieldLabel = props => {
  const { name, label, required, hasError, renderLabel } = props
  const hasRenderFn = typeof renderLabel === "function"

  return (
    <label htmlFor={name} className={hasError ? "text-danger" : ""}>
      {hasRenderFn ? renderLabel() : label}
      <span className="text-danger">{required && "*"}</span>
    </label>
  )
}

FieldLabel.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  required: PropTypes.bool,
  hasError: PropTypes.bool,
  renderLabel: PropTypes.func,
}

FieldLabel.defaultProps = {
  required: false,
  hasError: false,
}

export default FieldLabel

import {
  PROFILE_ERROR,
  PROFILE_SUCCESS,
  EDIT_PROFILE,
  UPDATE_PROFILE,
} from "./actionTypes"
import { getUser } from "helpers/localstorage/auth"

const initialState = {
  me: getUser() || null,
  error: "",
  success: "",
}

const profile = (state = initialState, action) => {
  switch (action.type) {
    case EDIT_PROFILE:
      state = { ...state }
      break
    case PROFILE_SUCCESS:
      state = { ...state, success: action.payload }
      break
    case PROFILE_ERROR:
      state = { ...state, error: action.payload }
      break
    case UPDATE_PROFILE:
      state = { ...state, me: action.payload }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default profile

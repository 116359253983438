const STORAGE_KEY = "apiTable"

function getApiTableSettings(tableName) {
  return (JSON.parse(localStorage.getItem(STORAGE_KEY)) || {})[tableName] || {}
}

function setApiTableSettings(tableName, params) {
  // params e.g. { hits: 10 }
  const existingAllTableValues =
    JSON.parse(localStorage.getItem(STORAGE_KEY)) || {}
  const existingTableValue = getApiTableSettings(tableName)

  localStorage.setItem(
    STORAGE_KEY,
    JSON.stringify({
      ...existingAllTableValues,
      [tableName]: { ...existingTableValue, ...params },
    })
  )
}

export { getApiTableSettings, setApiTableSettings }

import React, { useCallback, useEffect, useState } from "react"
import PropTypes from "prop-types"
import { Field } from "redux-form"
import { Input } from "reactstrap"

import { isEmpty, numberWithCommas, roundNumber } from "helpers/cms_helper"

import _ from "lodash"

const TextInput = (props) => {
  const {
    type,
    value,
    onChange,
    required,
    disabled,
    className,
    minLength,
    maxLength,
    min,
    max,
    isInt,
    onError,
    onChangeCustom,
    onBlur, // onBlur must be used together with touched in order to make touched work
    touched,
    maxDecimalPlaces,
  } = props
  const [priceValue, setPriceValue] = useState({ display: "", value: null })

  const fieldProps = _.pick(props, [
    "name",
    "placeholder",
    "required",
    "disabled",
    "style",
    "maxLength",
  ])

  useEffect(() => {
    if (type === "price" && !_.isObject(value))
      setPriceValue({ display: numberWithCommas(value), value: value })

    if (!isEmpty(onError)) validate(value)
    if (!isEmpty(onChangeCustom)) onChangeCustom(value, touched)
  }, [value])

  const validate = (val) => {
    if (disabled) return onError(null)

    const valIsEmpty = isEmpty(val)
    if (required && valIsEmpty) return onError("Required")

    if (!valIsEmpty) {
      if (minLength && String(val).length < minLength)
        return onError(`Should contain at least ${minLength} characters`)

      if (type === "email") {
        // https://stackoverflow.com/a/46181
        const validEmail = String(val)
          .toLowerCase()
          .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          )
        if (!validEmail) return onError("Incorrect email format")
      }

      if (type === "tel") {
        const validTel = String(val).match(/^[0-9 ]+$/)
        if (!validTel) return onError("Invalid phone number")
      }

      if (["number", "percentage", "price"].indexOf(type) !== -1) {
        if (isInt && !Number.isInteger(Number(val)))
          return onError(`Should be an integer`)

        let minTmp = min
        if (isEmpty(minTmp) && type === "percentage") minTmp = 0
        if (
          !isEmpty(minTmp) &&
          ((["number", "price"].indexOf(type) !== -1 && val < minTmp) ||
            (type === "percentage" && val < roundNumber(minTmp / 100)))
        )
          return onError(`Should be greater than or equal to ${minTmp}`)

        let maxTmp = max
        if (isEmpty(maxTmp) && type === "percentage") maxTmp = 100
        if (
          !isEmpty(maxTmp) &&
          ((["number", "price"].indexOf(type) !== -1 && val > maxTmp) ||
            (type === "percentage" && val > roundNumber(maxTmp / 100)))
        )
          return onError(`Should be less than or equal to ${maxTmp}`)

        let decimalPlaces = (value?.toString()?.split(".")[1] || "")?.length
        if (decimalPlaces > maxDecimalPlaces)
          return onError("value limit to 1 decimal place")
      }
    }

    return onError(null)
  }

  let inputType = type
  let inputValue = value
  if (type === "percentage") {
    inputType = "number"
    inputValue = isEmpty(value) ? value : roundNumber(value * 100)
  } else if (type === "price") {
    inputType = "text"
    inputValue = isEmpty(priceValue?.display) ? "" : priceValue.display
  }

  const handleChange = (e, oriValue) => {
    if (["number", "percentage", "price"].indexOf(type) !== -1) {
      if (e.target.value === "") onChange(null)
      else {
        if (type === "percentage") {
          onChange(roundNumber(Number(e.target.value) / 100))
        } else if (type === "price") {
          const oriVal = oriValue.replaceAll(",", "")

          // if input more than 1 decimal point, ignore
          if (e.nativeEvent.data === "." && oriVal.indexOf(".") !== -1) return
          // if input "-", make value negative
          if (e.nativeEvent.data === "-") return onChange(Number(oriVal) * -1)

          // set value
          let newVal = ("" + e.target.value)
            .replace(/[^\d.-]/g, "") // remove non-numeric char
            .replaceAll(",", "")
          if (newVal === "-") newVal = ""
          const newValNum = newVal ? Number(newVal) : null
          const newValDisplay = numberWithCommas(newVal)
          setPriceValue({
            display: newValDisplay,
            value: newValNum,
          })
          onChange(newValNum)

          // position the caret after the inputted key
          let caret = e.target.selectionStart
          const stringAfterCaret = e.target.value.slice(
            caret,
            e.target.value.length
          )
          caret = newValDisplay.lastIndexOf(stringAfterCaret)
          window.requestAnimationFrame(() => {
            e.target.selectionStart = caret
            e.target.selectionEnd = caret
          })
        } else {
          onChange(Number(e.target.value))
        }
      }
    } else onChange(e.target.value)

    if (!isEmpty(onBlur)) onBlur()
  }

  return (
    <>
      <Input
        {...fieldProps}
        type={inputType}
        className={
          disabled
            ? (className || "").replaceAll("border-danger", "")
            : className || ""
        }
        value={inputValue}
        onChange={(e) => handleChange(e, inputValue)}
        onWheel={(e) => e.target.blur()}
      />
      {!isEmpty(maxLength) && (
        <span className="float-right character-count">
          {value.length} / {maxLength} character{maxLength > 1 && "s"}
        </span>
      )}
    </>
  )
}

const TextField = (props) => {
  // for types: text (default), email, password, number, textarea, percentage, price
  const { fieldType, value, onChange, onError, hasError } = props

  const fieldProps = _.pick(props, [
    "name",
    "type",
    "required",
    "disabled",
    "className",
    "style",
    "placeholder",
    "minLength",
    "maxLength",
    "isInt",
    "min",
    "max",
    "onChangeCustom",
    "maxDecimalPlaces",
  ])
  fieldProps.className =
    (fieldProps.className || "") +
    " form-control rounded-0" +
    (hasError ? " border-danger" : "")
  fieldProps.style = {
    maxWidth: fieldType !== "filter" ? "540px" : null,
    ...fieldProps.style,
  }

  const renderTextInput = useCallback(
    ({ input, meta: { touched } }) => (
      <TextInput
        {...fieldProps}
        fieldType={fieldType}
        value={input.value}
        onChange={(val) => input.onChange(val)}
        onError={onError}
        onBlur={input.onBlur}
        touched={touched}
      />
    ),
    [props.disabled] // add dynamic props here
  )

  const renderCustomTextInput = useCallback(
    ({ input }) => (
      <TextInput
        {...fieldProps}
        value={input.value}
        onChange={(val) => {
          input.onChange(val)
          onChange(input.value)
        }}
        onError={onError}
      />
    ),
    [props.disabled] // add dynamic props here
  )

  if (fieldType === "form")
    return (
      <div className={"field-wrapper" + (hasError ? " has-error" : "")}>
        <Field component={renderTextInput} {...fieldProps} />
      </div>
    )
  else if (fieldType === "filter")
    return <Field component={renderTextInput} {...fieldProps} />
  else if (fieldType === "customValue")
    return (
      <TextInput
        {...fieldProps}
        value={isEmpty(value) ? "" : value}
        onChange={(val) => onChange(val)}
        onError={onError}
      />
    )
  else return <Field component={renderCustomTextInput} {...fieldProps} />
}

TextInput.propTypes = {
  type: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  maxLength: PropTypes.number,
  minLength: PropTypes.number,
  min: PropTypes.number,
  max: PropTypes.number,
  isInt: PropTypes.bool,
  onError: PropTypes.func,
  onChangeCustom: PropTypes.func,
  onBlur: PropTypes.any, // onBlur must be used together with touched in order to make touched work
  touched: PropTypes.any,
  maxDecimalPlaces: PropTypes.number,
}

TextField.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.object,
  fieldType: PropTypes.string,
  maxLength: PropTypes.number,
  minLength: PropTypes.number,
  min: PropTypes.number,
  max: PropTypes.number,
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onError: PropTypes.func,
  isInt: PropTypes.bool,
  onChangeCustom: PropTypes.func,
  hasError: PropTypes.bool,
}

TextField.defaultProps = {
  fieldType: "form",
  required: false,
  disabled: false,
  isInt: false,
  hasError: false,
}

export default TextField

import React from "react"
import PropTypes from "prop-types"

import CustomCheckbox from "components/Common/CustomCheckbox"

const DataTableHead = props => {
  const { columns, draggable, canEditRows, selectable, onSelect, selectedAll } =
    props

  const thClassNames = "border-top-0"

  return (
    <thead>
      <tr>
        {draggable && (
          <th className={thClassNames}>
            <div>Reorder</div>
          </th>
        )}
        {canEditRows && (
          <th className={thClassNames}>
            <div>Delete</div>
          </th>
        )}
        {selectable && (
          <th style={{ width: "131px" }}>
            <div>
              <CustomCheckbox
                label="Select All / Unselect All"
                value={selectedAll}
                onChange={state => onSelect(state, "all")}
              />
            </div>
          </th>
        )}
        {columns.map(
          (col, idx) =>
            !col.hidden && (
              <th key={idx} className={thClassNames}>
                <div>{col.title}</div>
              </th>
            )
        )}
      </tr>
    </thead>
  )
}

DataTableHead.propTypes = {
  columns: PropTypes.array.isRequired,
  draggable: PropTypes.bool,
  canEditRows: PropTypes.bool,
  selectable: PropTypes.bool,
  onSelect: PropTypes.func,
  selectedAll: PropTypes.bool,
}

DataTableHead.defaultProps = {
  draggable: false,
  canEditRows: false,
  selectable: false,
  selectedAll: false,
}

export default DataTableHead

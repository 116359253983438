import React, { useEffect, useRef } from "react"

import { isEmpty } from "helpers/cms_helper"

// https://stackoverflow.com/a/42234988

function useSidebarWrapper(ref) {
  useEffect(() => {
    function handleClickOutside(event) {
      const clickedToggleIcon =
        $(event.target).hasClass("fa-bars") ||
        $(event.target).hasClass("logo-sm") ||
        $(event.target).hasClass("navbar-brand-box") ||
        $(event.target).attr("fill") === "currentColor"
      if (
        ref.current &&
        !ref.current.contains(event.target) &&
        !clickedToggleIcon
      ) {
        // clicked outside of Sidebar, but not the toggle menu icon
        const menu = (document.getElementsByClassName("vertical-menu") || [
          null,
        ])[0]
        if (menu) {
          const menuOpened =
            !isEmpty(menu.style.display) && menu.style.display !== "none"
          if (menuOpened) menu.style.display = "none"
        }
      }
    }
    document.addEventListener("mousedown", handleClickOutside)
    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [ref])
}

function SidebarWrapper(props) {
  const wrapperRef = useRef(null)
  useSidebarWrapper(wrapperRef)
  return <div ref={wrapperRef}>{props.children}</div>
}

export default SidebarWrapper

// Login
export const POST_LOGIN = "/cms/login"
export const POST_FAKE_JWT_PASSWORD_FORGET = "/jwt-forget-pwd"

// Resource
export const GET_UPLOAD_URL = "/cms/resource/signed-url/upload"
export const GET_USER_UPLOAD_URL = "/cms/resource/signed-url/upload"
export const GET_SIGNED_URL = "/cms/resource/view_files"
export const GET_CONVERT_URL = "/cms/resource/signed-url/convert"

// Profile
export const GET_ME = "/cms/admins/me"
export const PUT_ME = "/cms/admins/me"
export const PUT_ME_PASSWORD = "/cms/me/password"
export const PUT_PROFILE = "/me"

// Admins
export const GET_ADMINS = "/cms/admins"
export const POST_ADMINS = "/cms/admins"
export const PUT_ADMIN = "/cms/admins"
export const DELETE_ADMIN = "/cms/admins"

// User
export const GET_USERS = "/cms/users"
export const POST_USERS = "/cms/users"
export const PUT_USER = "/cms/users"
export const DELETE_USER = "/cms/users"

export const POST_RESET_PASSWORD_EMAIL = "/cms/reset_password"

export const PUT_UPDATE_PASSWORD = "/cms/admins/me/password"
export const POST_RESET_PASSWORD = "/cms/admins/reset_password"

// Tree list
export const GET_TREE = "/cms/tree/listing"
export const PUT_TREE = "/cms/tree/listing"

// Tree species
export const GET_TREE_SPECIES = "/cms/tree/species"
export const PUT_TREE_SPECIES = "/cms/tree/species"

// Static Page
export const GET_STATIC_PAGES = "/cms/static-pages"
export const PUT_STATIC_PAGES = "/cms/static-pages"

// FAQ
export const GET_FAQ = "/cms/faq"
export const POST_FAQ = "/cms/faq"
export const PUT_FAQ = "/cms/faq"
export const DELETE_FAQ = "/cms/faq"

// Tree Issue
export const GET_TREE_ISSUES = "/cms/tree/issues"
export const PUT_TREE_ISSUES = "/cms/tree/issues"

// App Setting
export const GET_APP_SETTING = "/cms/app-setting"
export const PUT_APP_SETTING = "/cms/app-setting"

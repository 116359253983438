import React, { useEffect, useRef, useState } from "react"

import BaseLayout from "components/BaseLayout"
import { ApiForm } from "components/Common/Form"

import { getAppSetting, editAppSetting } from "helpers/backend_helper"
import { toast } from "helpers/cms_helper"

const AppSetting = () => {
  const [detail, setDetail] = useState({})
  const formRef = useRef(null)

  const fields = [
    {
      title: "Android",
      label: "Minimal app version",
      name: "minimalVersionAndroid",
      required: true,
    },
    {
      label: "Deadline to update app",
      name: "updateDeadlineAndroid",
      type: "datetime",
      required: true,
    },
    {
      title: "IOS",
      label: "Minimal app version",
      name: "minimalVersionIOS",
      required: true,
    },
    {
      label: "Deadline to update app",
      name: "updateDeadlineIOS",
      type: "datetime",
      required: true,
    },
  ]

  async function fetchAppSetting() {
    const res = await getAppSetting()
    setDetail(res)
  }

  async function handleSubmit(values) {
    await editAppSetting(values)
    await getAppSetting
    await fetchAppSetting()

    toast("Updated")
  }

  useEffect(() => {
    fetchAppSetting()
  }, [])

  return (
    <BaseLayout title="App version management">
      <ApiForm
        ref={formRef}
        name="app-setting"
        fields={fields}
        data={detail}
        style={{ maxWidth: "585px" }}
        onSubmit={handleSubmit}
        titlesStyle={{ textAlign: "center" }}
      />
    </BaseLayout>
  )
}

export default AppSetting

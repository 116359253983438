import React, { useCallback, useEffect, useState } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import { getMonth, getYear } from "date-fns"
import { Field } from "redux-form"
import { Input } from "reactstrap"
import DatePicker from "react-datepicker"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons"

import { dateToISO, dateToStr, isEmpty } from "helpers/cms_helper"

const CalendarContainer = ({ children }) => {
  return ReactDOM.createPortal(children, document.body)
}

const CustomTimeInput = ({ date, value, onChange, onClose }) => {
  const selectedHour = value ? ("0" + date.getHours()).slice(-2) : "00"
  const selectedMinute = value ? ("0" + date.getMinutes()).slice(-2) : "00"

  return (
    <div className="row justify-content-center align-items-center">
      <div className="col col-sm-5">
        <Input
          type="number"
          className="date-picker-time-input"
          value={selectedHour}
          onChange={e => {
            e.preventDefault()
            onChange(`${e.target.value}:${selectedMinute}`)
          }}
          onKeyPress={e => {
            if (e.key === "Enter") {
              e.preventDefault()
              onClose()
            }
          }}
          onFocus={e => e.target.select()}
        ></Input>
      </div>
      <div className="col col-sm-1 text-center p-0">:</div>
      <div className="col col-sm-5">
        <Input
          type="number"
          className="date-picker-time-input"
          value={selectedMinute}
          onChange={e => {
            e.preventDefault()
            onChange(`${selectedHour}:${e.target.value}`)
          }}
          onKeyPress={e => {
            if (e.key === "Enter") {
              e.preventDefault()
              onClose()
            }
          }}
          onKeyDown={e => {
            if (e.key === "Tab") {
              e.preventDefault()
            }
          }}
          onFocus={e => e.target.select()}
        ></Input>
      </div>
    </div>
  )
}

const DateTimeInput = props => {
  const {
    isOpen,
    onOpen,
    value,
    onChange,
    showTime,
    returnFormat,
    placeholder,
    required,
    disabled,
    dateFormat,
    includeDates,
    closeOnSelect,
    onError,
    onChangeCustom,
    onBlur, // onBlur must be used together with touched in order to make touched work
    touched,
    fieldType,
    ...rest
  } = props
  const fieldProps = _.pick(props, [
    "className",
    "style",
    "disabled",
    "minDate",
    "maxDate",
  ])

  useEffect(() => {
    if (!isEmpty(onError)) validate(value)
    if (!isEmpty(onChangeCustom)) onChangeCustom(value, touched)
  }, [value])

  const validate = val => {
    if (disabled) return onError(null)

    if (required && isEmpty(val)) return onError("Required")
    return onError(null)
  }

  const renderCustomHeader = ({
    date,
    decreaseMonth,
    increaseMonth,
    prevMonthButtonDisabled,
    nextMonthButtonDisabled,
  }) => {
    const months = [
      "JAN",
      "FEB",
      "MAR",
      "APR",
      "MAY",
      "JUN",
      "JUL",
      "AUG",
      "SEP",
      "OCT",
      "NOV",
      "DEC",
    ]

    return (
      <div
        className="row justify-content-between align-items-center font-weight-bold"
        style={{
          margin: "0 12px 10px 12px",
        }}
      >
        <button
          onClick={e => {
            e.preventDefault()
            decreaseMonth(e)
          }}
          disabled={prevMonthButtonDisabled}
          className="text-button"
        >
          <FontAwesomeIcon icon={faAngleLeft} />
        </button>
        <span>
          {months[getMonth(date)]}&nbsp;{getYear(date)}
        </span>
        <button
          onClick={e => {
            e.preventDefault()
            increaseMonth(e)
          }}
          disabled={nextMonthButtonDisabled}
          className="text-button"
        >
          <FontAwesomeIcon icon={faAngleRight} />
        </button>
      </div>
    )
  }

  return (
    <DatePicker
      selected={value ? new Date(value) : null}
      onChange={date => {
        onChange(
          returnFormat
            ? dateToStr(date, returnFormat)
            : dateToISO(date, showTime)
        )
        if (!isEmpty(onBlur)) onBlur()
      }}
      dateFormat={
        dateFormat
          ? dateFormat
          : showTime
          ? "dd MMM yyyy HH:mm:ss"
          : "dd MMM yyyy"
      }
      placeholderText={placeholder}
      popperContainer={CalendarContainer}
      renderCustomHeader={renderCustomHeader}
      formatWeekDay={nameOfDay => nameOfDay[0]}
      showTimeInput={showTime}
      timeInputLabel=""
      customTimeInput={<CustomTimeInput onClose={() => onOpen(false)} />}
      showPopperArrow={false}
      shouldCloseOnSelect={closeOnSelect}
      isClearable={!disabled && !required}
      open={isOpen}
      includeDates={includeDates}
      onCalendarOpen={() => onOpen(true)}
      onCalendarClose={() => onOpen(false)}
      onClickOutside={() => onOpen(false)}
      onKeyDown={e => {
        // press Enter to close calendar
        if (e.key === "Enter") onOpen(false)
      }}
      wrapperClassName={fieldType !== "filter" ? "has-max-width" : null}
      {...fieldProps}
    />
  )
}

const DateTimeField = props => {
  const { fieldType, onError, value, onChange, hasError } = props
  const [isOpen, setIsOpen] = useState(false)

  const fieldProps = _.pick(props, [
    "fieldType",
    "name",
    "required",
    "disabled",
    "placeholder",
    "className",
    "style",
    "showTime",
    "returnFormat",
    "minDate",
    "maxDate",
    "dateFormat",
    "includeDates",
    "closeOnSelect",
    "onChangeCustom",
  ])
  fieldProps.className =
    (fieldProps.className || "") +
    " form-control rounded-0" +
    (hasError ? " border-danger" : "")

  if (fieldType !== "custom") {
    fieldProps.className = (fieldProps.className || "").replaceAll(
      "border-danger",
      ""
    )
  }

  const renderDateTimeInput = useCallback(
    ({ input, meta: { touched } }) => {
      return (
        <DateTimeInput
          {...fieldProps}
          isOpen={isOpen}
          onOpen={val => setIsOpen(val)}
          value={input.value}
          onChange={input.onChange}
          onError={onError}
          onBlur={input.onBlur}
          touched={touched}
        />
      )
    },
    [props.disabled, isOpen] // add dynamic props here
  )

  if (fieldType === "form")
    return (
      <div className={"field-wrapper" + (hasError ? " has-error" : "")}>
        <Field component={renderDateTimeInput} {...fieldProps} />
      </div>
    )
  else if (fieldType === "filter")
    return <Field component={renderDateTimeInput} {...fieldProps} />
  else
    return (
      <DateTimeInput
        {...fieldProps}
        isOpen={isOpen}
        onOpen={val => setIsOpen(val)}
        value={value}
        onChange={onChange}
        onError={onError}
      />
    )
}

DateTimeField.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  dateFormat: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.object,
  fieldType: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.string, // ISO format (default), e.g. 2022-02-15T01:39:51.000Z, OR in the format specified in returnFormat
  showTime: PropTypes.bool,
  returnFormat: PropTypes.string, // return value in this format (e.g. YYYY-MM-DD), default ISO format
  includeDates: PropTypes.array, // array of js Date objects
  onError: PropTypes.func,
  onChangeCustom: PropTypes.func,
  closeOnSelect: PropTypes.bool,
  hasError: PropTypes.bool,
}

DateTimeField.defaultProps = {
  fieldType: "form",
  showTime: true,
  required: false,
  disabled: false,
  closeOnSelect: false,
  hasError: false,
}

export default DateTimeField

import React from "react"
import PropTypes from "prop-types"
import { Button } from "reactstrap"

const Modal = props => {
  const { isOpen, title, children, onToggle, style, size } = props

  function close() {
    onToggle(false)
  }

  return (
    <React.Fragment>
      <div
        className={"modal fade " + (isOpen ? "show" : "")}
        style={{ display: isOpen ? "block" : "none" }}
      >
        <div
          className={"modal-dialog" + (size ? ` modal-${size}` : "")}
          onClick={e => e.stopPropagation()}
        >
          <div className="modal-content rounded-0" style={style}>
            <div className="modal-header">
              {title && <h5 className="modal-title">{title}</h5>}
              <Button close onClick={() => close()} />
            </div>
            <div className="modal-body">{children}</div>
          </div>
        </div>
      </div>
      {isOpen ? <div className="modal-backdrop fade show"></div> : ""}
    </React.Fragment>
  )
}

Modal.propTypes = {
  isOpen: PropTypes.bool,
  onToggle: PropTypes.func,
  title: PropTypes.string,
  style: PropTypes.object,
  size: PropTypes.string,
}

export default Modal

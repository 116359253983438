import React from "react"
import PropTypes from "prop-types"
import { Container } from "reactstrap"

import Header from "components/VerticalLayout/Header"

const BaseLayout = props => {
  const { children, title, headerButtons } = props

  return (
    <>
      <Header title={title}>{headerButtons}</Header>
      <div className="page-content">
        <Container fluid style={{ padding: "0.75rem 2rem 0" }}>
          {children}
        </Container>
      </div>
      <div id="page-content-append"></div>
    </>
  )
}

BaseLayout.propTypes = {
  title: PropTypes.string,
  headerButtons: PropTypes.oneOfType([PropTypes.object, PropTypes.elementType]),
}

export default BaseLayout

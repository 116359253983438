import React, { useEffect, useMemo, useRef, useState } from "react"
import PropTypes from "prop-types"
import { reduxForm, getFormValues, isDirty } from "redux-form"
import { compose } from "redux"
import { connect } from "react-redux"
import _ from "lodash"
import CustomButton from "components/Common/CustomButton"
import { FieldLabel, FormField, MultiLangFormField } from "."

import { MULTILANG_TABS, toast } from "helpers/cms_helper"

const beautifyData = (obj) => {
  Object.keys(obj).forEach((key) => {
    if (typeof obj[key] === "object" && obj[key] !== null)
      beautifyData(obj[key])
    if (obj[key] === "") obj[key] = null
    else if (_.isString(obj[key])) obj[key] = obj[key].trim()
  })
}

const parseNestedData = (payload, fields) => {
  let data = _.cloneDeep(payload)
  fields.forEach((field) => {
    if (field.type === "component" && field.propagate) {
      // propagate is true: set value (an object) directly to data (instead of being nesting inside data[field.name])
      data = { ...data, ...data[field.name] }
      delete data[field.name]
    }
  })
  beautifyData(data)
  return data
}

const ApiForm = (props) => {
  const {
    children,
    // redux-form props
    handleSubmit,
    reset,
    formValues,
    // custom props
    name,
    type,
    mode,
    fields,
    data,
    onSubmit,
    submitButtonText,
    submitButtonClassName,
    extraSubmitButtons,
    extraSubmitButtonsLeft,
    style,
    titlesStyle,
    onError,
    delay,
    isDisabled,
  } = props
  const [errors, setErrors] = useState({}) // {fieldName1: "errorMsg1", fieldName2: null, ...}
  const [hidden, setHidden] = useState(true)
  const [submitting, setSubmitting] = useState(false)
  const [submitted, setSubmitted] = useState(false)
  const mounted = useRef(false)

  useEffect(() => {
    mounted.current = true
    return () => {
      mounted.current = false
    }
  }, [])

  useEffect(() => {
    const delayTime = delay || 100 // delay a tiny bit of time to wait for props to be ready
    const timer = setTimeout(() => {
      setHidden(false)
    }, delayTime)
    return () => clearTimeout(timer)
  }, [delay])

  useEffect(() => {
    if (onError) onError(errors, submitted)
  }, [errors, submitted])

  const formHasError = useMemo(() => {
    for (const key in errors) {
      const field = _.find(fields, { name: key })
      if (!field?.hidden && !!errors[key]) return true
    }
    return false
  }, [fields, errors])

  const handleError = (fieldName, errorMsg) => {
    setErrors((prevState) => ({ ...prevState, [fieldName]: errorMsg }))
  }

  let formContent = null
  if (type === "filter") {
    formContent = children
  } else {
    formContent = (
      <div style={style}>
        {fields.map((field, idx) => {
          if (mode === "view") field.disabled = true

          if (field.if !== undefined) {
            if (field.if === false) return
            else if (typeof field.if === "function" && !field.if(data)) return
          }

          let fieldHidden = false
          if (field.multilangHidden) fieldHidden = true
          else if (field.hidden) {
            if (typeof field.hidden === "function")
              fieldHidden = field.hidden(data)
            // boolean
            else fieldHidden = field.hidden
          }

          const fieldHasError = !!errors[field.name]

          return (
            <React.Fragment key={field.name}>
              {!!field.title && !fieldHidden && (
                <>
                  <h4
                    className={"title" + (idx > 0 ? " mt-5" : "")}
                    style={titlesStyle}
                  >
                    {field.title}
                  </h4>
                  <hr />
                </>
              )}
              {!!field.subtitle && !fieldHidden && (
                <h5
                  className={"sub-title mb-3" + (idx > 0 ? " mt-4" : "")}
                  style={titlesStyle}
                >
                  {field.subtitle}
                </h5>
              )}
              <div
                className={"mb-3" + (fieldHidden ? " d-none" : "")}
                style={field.style}
              >
                {field.multilang === true ? (
                  <MultiLangFormField
                    {...field}
                    formName={name}
                    fieldType="form"
                    formValues={formValues}
                    onError={(fieldName, errorMsg) =>
                      handleError(fieldName, errorMsg)
                    }
                    submitted={submitted}
                  />
                ) : (
                  <>
                    {field.label && (
                      <FieldLabel
                        name={field.name}
                        label={field.label}
                        required={field.required}
                        hasError={submitted && fieldHasError}
                        renderLabel={field.renderLabel}
                      />
                    )}
                    <FormField
                      {...field}
                      formName={name}
                      fieldType="form"
                      formValues={formValues}
                      onError={(errorMsg) => handleError(field.name, errorMsg)}
                      hasError={submitted && fieldHasError}
                      submitted={submitted}
                    />
                    {submitted &&
                    fieldHasError &&
                    _.isString(errors[field.name]) ? (
                      <span className="text-danger">{errors[field.name]}</span>
                    ) : null}
                  </>
                )}
              </div>
            </React.Fragment>
          )
        })}
        <div className="buttons-row justify-content-start mt-4">
          {extraSubmitButtonsLeft}
          {mode !== "view" && (
            <CustomButton
              className={submitButtonClassName || "btn-dark"}
              type="button"
              onClick={handleSubmit((data) => {
                setSubmitted(true)
                if (formHasError)
                  return toast("Please check highlighted fields", true)

                setSubmitting(true)
                if (type === "filter")
                  onSubmit(data).finally(() => {
                    // set state only if the component is not unmounted
                    if (mounted.current === true) setSubmitting(false)
                  })
                else {
                  data = parseNestedData(data, fields)
                  onSubmit(data)
                    .then(() => {
                      if (mode === "create") reset() // clear form after create
                      setSubmitted(false)
                    })
                    .catch((errMsg) => toast(errMsg, true))
                    .finally(() => {
                      // set state only if the component is not unmounted
                      if (mounted.current === true) setSubmitting(false)
                    })
                }
              })}
              disabled={(submitted && formHasError) || isDisabled}
              loading={submitting}
            >
              {submitButtonText ? submitButtonText : "Save"}
            </CustomButton>
          )}
          {extraSubmitButtons}
        </div>
      </div>
    )
  }

  return hidden ? null : <form autoComplete="off">{formContent}</form>
}

ApiForm.propTypes = {
  name: PropTypes.string.isRequired,
  fields: PropTypes.array.isRequired,
  onSubmit: PropTypes.func.isRequired,
  type: PropTypes.string,
  mode: PropTypes.string,
  data: PropTypes.object,
  submitButtonText: PropTypes.string,
  submitButtonClassName: PropTypes.string,
  extraSubmitButtons: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.elementType,
  ]),
  extraSubmitButtonsLeft: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.elementType,
  ]),
  style: PropTypes.object,
  titlesStyle: PropTypes.object,
  onError: PropTypes.func,
  isDisabled: PropTypes.bool,
  children: PropTypes.node,
  handleSubmit: PropTypes.func,
  reset: PropTypes.any,
  formValues: PropTypes.any,
  delay: PropTypes.number,
}

ApiForm.defaultProps = {
  mode: "create",
  isDisabled: false,
}

// https://stackoverflow.com/questions/40509754/how-do-you-pass-in-a-dynamic-form-name-in-redux-form
const reduxFormProps = (state, props) => {
  const { name, data, fields, type, onSubmit } = props
  let dataToSet = {}
  if (data) {
    if (type === "filter") {
      dataToSet = data
    } else {
      // init API values for edit (only init fields in the form)
      fields.map((field) => {
        dataToSet[field.name] = {}
        if (field.multilang) {
          MULTILANG_TABS.map((tab) => {
            // const fieldName = field.name + tab.value
            // dataToSet[fieldName] = data[fieldName]
            dataToSet[field?.name][tab?.value] =
              data?.[field?.name]?.[tab?.value]
          })
        } else if (field.name.match(/\./)) {
          _.set(dataToSet, field.name.split("."), _.get(data, field.name))
        } else {
          dataToSet[field.name] = data[field.name]
        }
      })
    }
  } else {
    // init default values for create
    fields.map((field) => {
      if (field.defaultValue) {
        dataToSet[field.name] = field.defaultValue
      } else if (field.type === "toggle") {
        // toggle field default false if not specified
        dataToSet[field.name] = false
      }
    })
  }

  const formValues = getFormValues(name)(state)
  const dirty = isDirty(name)(state)

  return {
    form: name,
    initialValues: dataToSet,
    enableReinitialize: true,
    onSubmit: onSubmit,
    formValues: formValues,
    getField: (field) => _.get(dirty ? formValues : dataToSet, field),
    submit: () => {
      if (type === "filter") return onSubmit(formValues)
      else {
        const params = parseNestedData(formValues, fields)
        return onSubmit(params) // pls handle then & catch manually in caller
      }
    },
  }
}

export default compose(
  connect(reduxFormProps, null, null, { forwardRef: true }),
  reduxForm({})
)(ApiForm)

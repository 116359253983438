import { takeEvery, fork, put, all, call } from "redux-saga/effects"

import { RESET_PASSWORD } from "./actionTypes"
import { userResetPasswordSuccess, userResetPasswordError } from "./actions"
import { updateAdminPwd } from "helpers/backend_helper"

function* resetPassword({ payload: { user, token } }) {
  try {
    const response = yield call(updateAdminPwd, token, {
      password: user.password,
    })
    if (response) {
      yield put(userResetPasswordSuccess("Reset password success"))
    }
  } catch (error) {
    yield put(userResetPasswordError(error))
  }
}

function* watchResetPasswordSaga() {
  yield takeEvery(RESET_PASSWORD, resetPassword)
}

function* resetPasswordSaga() {
  yield all([fork(watchResetPasswordSaga)])
}

export default resetPasswordSaga

import axios from "axios"
import { getJwt, unsetUser } from "./localstorage/auth"
import { isEmpty, toast } from "./cms_helper"

//pass new generated access token here
const token = getJwt()
//apply base url for axios
const API_URL = `${process.env.REACT_APP_API_HOST}/api/`

export const axiosApi = axios.create({
  baseURL: API_URL,
})

if (token) {
  axiosApi.defaults.headers.common["Authorization"] = token
}

axiosApi.interceptors.response.use(
  response => response,
  error => {
    const status = error?.response?.status
    if (status == 401) {
      unsetUser()
    }
    return Promise.reject(error?.response || error)
  }
)

function handleError(e) {
  const errStatus = e?.data?.code ? e.data.code : e?.status
  const errMsg = e?.data?.message
    ? e.data.message
    : e?.data?.error
    ? e.data.error
    : e.data
  if (isEmpty(errStatus) && isEmpty(errMsg))
    toast(`API error: ${e.message}`, true)
  else
    toast(`API error: ${errStatus || "unknown"} - ${errMsg || "unknown"}`, true)
  throw e
}

export async function get(url, config = {}) {
  return await axiosApi
    .get(url, { ...config })
    .then(response => response.data)
    .catch(e => (config.ignoreError ? null : handleError(e)))
}

export async function post(url, data, config = {}) {
  return axiosApi
    .post(url, data, { ...config })
    .then(response => response.data)
    .catch(e => handleError(e))
}

export async function put(url, data, config = {}) {
  return axiosApi
    .put(url, data, { ...config })
    .then(response => response.data)
    .catch(e => handleError(e))
}

export async function del(url, config = {}) {
  return await axiosApi
    .delete(url, { ...config })
    .then(response => response.data)
    .catch(e => handleError(e))
}

import { call, put, takeEvery } from "redux-saga/effects"

// Login Redux States
import { LOGIN_USER, LOGOUT_USER } from "./actionTypes"
import { apiError, loginSuccess } from "./actions"

//Include Both Helper File with needed methods
import { postLogin, getMe } from "../../../helpers/backend_helper"
import { setUser, unsetUser } from "helpers/localstorage/auth"

function* loginUser({ payload: { user } }) {
  try {
    const response = yield call(postLogin, {
      email: user.email,
      password: user.password,
    })
    const me = yield call(getMe, {
      headers: {
        Authorization: `Bearer ${response.token}`,
      },
    })

    setUser({ ...me, ...response })
    yield put(loginSuccess(response))
    window.location.pathname = "/cms"
  } catch (error) {
    yield put(apiError(error))
  }
}

function* logoutUser({ payload: { history } }) {
  try {
    unsetUser()

    history.push("/login")
  } catch (error) {
    yield put(apiError(error))
  }
}

function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser)
  yield takeEvery(LOGOUT_USER, logoutUser)
}

export default authSaga

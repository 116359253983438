import React from "react"
import UserList from "./UserList"

const User = () => {
  return (
    <>
      <UserList />
    </>
  )
}

export default User

import React from "react"
import PropTypes from "prop-types"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons"

import {
  TextField,
  SelectField,
  ComponentField,
  CKEditorField,
  DateTimeField,
  ImageField,
  ImagesField,
  ToggleField,
  TagsField,
  CheckboxField,
  SearchField,
  FileField,
  FilesField,
  CanvasField,
} from "."

const icons = {
  search: faMagnifyingGlass,
}

const FormField = props => {
  const {
    fieldType,
    type,
    formValues,
    row,
    required,
    disabled,
    style,
    prefix,
    suffix,
    icon,
    fieldButtons,
    onError,
    hasError,
  } = props

  const handleError = errorMsg => {
    if (onError) onError(errorMsg)
  }

  let field = null
  const parsedProps = {
    ...props,
    onError: handleError,
    required: typeof required === "function" ? required(formValues) : required,
    disabled:
      typeof disabled === "function" ? disabled(formValues, row) : disabled,
  }

  switch (type) {
    case "select":
      field = <SelectField {...parsedProps} />
      break

    case "toggle":
      // return true or false
      field = <ToggleField {...parsedProps} />
      break

    case "checkbox":
      // return array of checked values
      field = <CheckboxField {...parsedProps} />
      break

    case "datetime":
      field = <DateTimeField {...parsedProps} />
      break
    case "date":
      field = <DateTimeField showTime={false} {...parsedProps} />
      break

    case "tags":
      field = <TagsField {...parsedProps} />
      break

    case "ckeditor":
      field = <CKEditorField {...parsedProps} />
      break

    case "image":
      field = <ImageField {...parsedProps} />
      break

    case "images":
      field = <ImagesField {...parsedProps} />
      break

    case "file":
      field = <FileField {...parsedProps} />
      break

    case "files":
      field = <FilesField {...parsedProps} />
      break

    case "search":
      field = <SearchField {...parsedProps} />
      break

    case "canvas":
      field = <CanvasField {...parsedProps} />
      break

    case "component":
      field = <ComponentField {...parsedProps} />
      break

    default:
      field = <TextField {...parsedProps} />
  }
  const hasPrefix = !!prefix
  const hasSuffix = icon || type === "percentage" || !!suffix
  if (hasPrefix || hasSuffix)
    field = (
      <div
        className={
          "input-group " +
          (hasPrefix ? "prefix " : "") +
          (hasSuffix ? "suffix " : "")
        }
        style={{ maxWidth: fieldType !== "filter" ? "540px" : null }}
      >
        {hasPrefix && (
          <div className="input-group-prepend">
            <span
              className={
                "input-group-text prefix text-secondary form-control rounded-0 " +
                (disabled ? "disabled " : "") +
                (hasError ? "border-danger " : "") +
                parsedProps.className
              }
              style={style}
            >
              {prefix}
            </span>
          </div>
        )}
        <div className="input-group-field">{field}</div>
        {hasSuffix && (
          <div className="input-group-append">
            <span
              className={
                "input-group-text suffix text-secondary form-control rounded-0 " +
                (disabled ? "disabled " : "") +
                (hasError ? "border-danger " : "") +
                parsedProps.className
              }
              style={style}
            >
              {suffix ? (
                suffix
              ) : icon ? (
                <FontAwesomeIcon icon={icons[icon]} />
              ) : type === "percentage" ? (
                "%"
              ) : null}
            </span>
          </div>
        )}
      </div>
    )

  if (fieldButtons) {
    const fieldBtns =
      typeof fieldButtons === "function" ? fieldButtons(props) : fieldButtons
    if (fieldBtns)
      field = (
        <div className="row">
          <div className="col">{field}</div>
          <div className="col" style={{ maxWidth: "fit-content" }}>
            {fieldBtns}
          </div>
        </div>
      )
  }

  return field
}

FormField.propTypes = {
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  formName: PropTypes.string,
  type: PropTypes.string,
  formValues: PropTypes.object,
  required: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  disabled: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  placeholder: PropTypes.string,
  options: PropTypes.array,
  component: PropTypes.elementType,
  propagate: PropTypes.bool,
  className: PropTypes.string,
  style: PropTypes.object,
  prefix: PropTypes.string,
  suffix: PropTypes.string,
  icon: PropTypes.string,
  fieldType: PropTypes.string,
  mode: PropTypes.string, // view
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
    PropTypes.array,
    PropTypes.object,
  ]),
  fieldButtons: PropTypes.oneOfType([PropTypes.object, PropTypes.elementType]),
  onError: PropTypes.func,
  hasError: PropTypes.bool,
}

FormField.defaultProps = {
  fieldType: "form",
  formValues: {},
  required: false,
  disabled: false,
  propagate: false,
  hasError: false,
}

export default FormField

import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { Table } from "reactstrap"
import { DragDropContext, Droppable } from "react-beautiful-dnd"

import CustomButton from "../CustomButton"
import DataTableHead from "./DataTableHead"
import DataTableRows from "./DataTableRows"

import { isEmpty } from "helpers/cms_helper"

// notes:
// 1. DO NOT use this component inside a scrollable component (e.g. Modal)

// react-beautiful-dnd drag and drop table example
// https://codesandbox.io/s/react-table-with-react-beautiful-dnd-0bj5d?file=/src/App.js:9260-9462

const DragAndDropTable = props => {
  // draggable is true: allow drag & drop table rows to reorder
  // canEditRows is true: allow adding & deleting rows
  const {
    title,
    rows,
    columns,
    rowButtons,
    draggable,
    canEditRows,
    onSave,
    onChange,
  } = props
  const [editedRows, setEditedRows] = useState([])

  useEffect(() => {
    setEditedRows(rows)
  }, [JSON.stringify(rows || [])])

  useEffect(() => {
    if (!isEmpty(onChange)) onChange(editedRows)
  }, [editedRows])

  const reorderData = (startIndex, endIndex) => {
    const newRows = _.cloneDeep(editedRows)
    const [movedRow] = newRows.splice(startIndex, 1)
    newRows.splice(endIndex, 0, movedRow)
    setEditedRows(newRows)
  }

  const handleDragEnd = e => {
    const { source, destination } = e
    if (!destination) return
    reorderData(source.index, destination.index)
  }

  const handleDelete = index => {
    const newRows = _.cloneDeep(editedRows)
    newRows.splice(index, 1)
    setEditedRows(newRows)
  }

  async function handleSave() {
    return onSave(
      editedRows.map((row, idx) => {
        row.ordering = idx + 1
        return row
      })
    )
  }

  return (
    <>
      {title && <span className="title">{title}</span>}
      <div className="buttons-row mb-3">
        {canEditRows && (
          // TODO
          <CustomButton className="btn-success">+ Add</CustomButton>
        )}
        <CustomButton type="api" className="btn-dark" onClick={handleSave}>
          Save
        </CustomButton>
      </div>
      <Table responsive className="data-table align-middle">
        <DataTableHead
          columns={columns}
          draggable={draggable}
          canEditRows={canEditRows}
        />

        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId="table-body">
            {(provided, snapshot) => (
              <tbody ref={provided.innerRef} {...provided.droppableProps}>
                <DataTableRows
                  type="reorder"
                  rows={editedRows}
                  columns={columns}
                  rowButtons={rowButtons}
                  draggable={draggable}
                  canEditRows={canEditRows}
                  onDelete={handleDelete}
                />
                {provided.placeholder}
              </tbody>
            )}
          </Droppable>
        </DragDropContext>
      </Table>
    </>
  )
}

DragAndDropTable.propTypes = {
  rows: PropTypes.array,
  columns: PropTypes.array.isRequired,
  rowButtons: PropTypes.oneOfType([PropTypes.object, PropTypes.elementType]),
  draggable: PropTypes.bool,
  canEditRows: PropTypes.bool,
  onSave: PropTypes.func,
  onChange: PropTypes.func,
  title: PropTypes.string,
}

DragAndDropTable.defaultProps = {
  draggable: true,
  canEditRows: false,
}

export default DragAndDropTable

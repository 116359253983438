import React, { useMemo, useState, Fragment } from "react"
import PropTypes from "prop-types"
import { Table, Spinner } from "reactstrap"

import { DataTableHead, DataTableRows } from "."
import Modal from "../Modal"

const DataTable = props => {
  const { loading, rows, onRowUpdate } = props
  const [modalContent, setModalContent] = useState(null)
  const modalShow = useMemo(() => !!modalContent, [modalContent])

  const handleRowUpdate = (row, name, index, val) => {
    _.set(row, name, val)
    const rowsClone = _.cloneDeep(rows)
    rowsClone[index] = row
    onRowUpdate(rowsClone)
  }

  function toggleModal(val) {
    console.log(val)
    if (!val) setModalContent(null)
  }

  return (
    <>
      <Table responsive className="data-table align-middle">
        <DataTableHead {...props} />

        <Fragment>
          <tbody className="position-relative">
            {loading ? (
              <div className="dataTables__spinner">
                <Spinner>Loading...</Spinner>
              </div>
            ) : null}
            {rows?.length ? (
              <DataTableRows
                {...props}
                onModalDisplay={setModalContent}
                onRowUpdate={handleRowUpdate}
              />
            ) : (
              <tr>
                <td
                  colSpan="999"
                  className="text-center pt-3 pb-1"
                  style={{ fontStyle: "italic" }}
                >
                  <div className="py-4" />
                </td>
              </tr>
            )}
          </tbody>
        </Fragment>
      </Table>
      <Modal isOpen={modalShow} onToggle={toggleModal}>
        {modalContent}
      </Modal>
    </>
  )
}

DataTable.propTypes = {
  type: PropTypes.string,
  rows: PropTypes.array,
  columns: PropTypes.array.isRequired,
  loading: PropTypes.bool,
  rowButtons: PropTypes.oneOfType([PropTypes.object, PropTypes.elementType]),
  selectable: PropTypes.bool,
  onSelect: PropTypes.func,
  selected: PropTypes.array,
  selectedAll: PropTypes.bool,
  initSelected: PropTypes.array,
  onRowUpdate: PropTypes.func,
}

DataTable.defaultProps = {
  type: "table",
  loading: false,
  selectable: false,
}

export default DataTable

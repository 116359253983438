import React, { useEffect, useMemo, useRef, useState } from "react"
import PropTypes from "prop-types"
import { Spinner } from "reactstrap"

import { isEmpty, isMobile } from "helpers/cms_helper"

const CustomButton = props => {
  const {
    children,
    type,
    className,
    style,
    onClick,
    disabled,
    mobileContent,
    loading,
  } = props
  const [submitting, setSubmitting] = useState(false)
  const [width, setWidth] = useState(null)
  const mounted = useRef(false)

  useEffect(() => {
    mounted.current = true
    return () => {
      mounted.current = false
    }
  }, [])

  let classNames = "btn waves-effect rounded-0 text-uppercase " + className
  if (classNames.indexOf("btn-sm") === -1) classNames += " px-3 py-2"

  const buttonDisabled = disabled || loading || submitting
  const buttonStyle = useMemo(
    () => ({
      cursor: buttonDisabled ? "default" : "pointer",
      minWidth: width ? width : null,
      ...style,
    }),
    [width, buttonDisabled]
  )
  const buttonText = mobileContent && isMobile ? mobileContent : children

  return (
    <button
      type={type ? (type === "api" ? "button" : type) : "button"}
      className={classNames}
      style={buttonStyle}
      onClick={e => {
        if (!isEmpty(onClick)) {
          setWidth(e.target.offsetWidth)
          if (type === "api") {
            setSubmitting(true)
            onClick(e).finally(() => {
              // set state only if the component is not unmounted
              if (mounted.current === true) setSubmitting(false)
            })
          } else {
            onClick(e)
          }
        }
      }}
      disabled={buttonDisabled}
    >
      {loading || submitting ? (
        <Spinner style={{ width: "13px", height: "13px" }}>Loading...</Spinner>
      ) : (
        buttonText
      )}
    </button>
  )
}

CustomButton.propTypes = {
  type: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func,
  style: PropTypes.object,
  disabled: PropTypes.bool,
  mobileContent: PropTypes.string,
}

CustomButton.defaultProps = {
  disabled: false,
}

export default CustomButton

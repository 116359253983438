import React from "react"
// import { Link } from "react-router-dom"

import BaseLayout from "components/BaseLayout"
import { ApiTable } from "components/Common/Table"
// import CustomButton from "components/Common/CustomButton"

import { getStaticPages } from "helpers/backend_helper"

import { STATIC_PAGES_DISPLAY } from "."

const StaticPages = () => {
  const columns = [
    {
      title: "Page",
      value: (row) =>
        STATIC_PAGES_DISPLAY[row.page]
          ? STATIC_PAGES_DISPLAY[row.page]
          : row.page,
    },
    { title: "Last Updated on", value: "uupdatedAt", format: "date" },
  ]

  async function fetch(params) {
    return getStaticPages({ ...params })
  }

  return (
    <BaseLayout title="Static Pages">
      <ApiTable
        name="static-pages"
        fetch={fetch}
        columns={columns}
        // rowButtons={row => (
        //   <Link to={`/dynamic-content/${row.item}`} >
        //     <CustomButton className="btn-outline-dark">Edit</CustomButton>
        //   </Link>
        // )}
      />
    </BaseLayout>
  )
}

export default StaticPages

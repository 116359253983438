import React, { useCallback, useEffect, useState } from "react"
import PropTypes from "prop-types"
import SignatureCanvas from "react-signature-canvas"
import { Field } from "redux-form"

import CustomButton from "../CustomButton"
import { isEmpty, isMobile } from "helpers/cms_helper"

/*
PLEASE NOTE:

CanvasField returns a base64 data url.

If you need to upload it to cloud, pls do the following manually:
1. convert the base64 data url to a file object by calling resource_helper.js > b64ToFile()
2. call your suitable upload function in resource_helper.js (uploadImage / uploadFile / uploadPrivate)

You may refer to OrderDetailsDelivery.js > handleStatusUpdate() for example
*/

const CanvasInput = props => {
  const { mode, value, onChange, required, disabled, onError } = props
  const [sigCanvas, setSigCanvas] = useState(null)
  const [showPlaceholder, setShowPlaceholder] = useState(true)

  useEffect(() => {
    if (sigCanvas && disabled) sigCanvas.off()
  }, [sigCanvas, disabled])

  useEffect(() => {
    if (sigCanvas) {
      if (value) {
        if (sigCanvas.isEmpty())
          sigCanvas.fromDataURL(value, {
            width: isMobile ? 338 : 538,
            height: 198,
          })
        setShowPlaceholder(false)
        if (!isEmpty(onError)) validate(value)
      } else {
        clear()
      }
    }
  }, [value, sigCanvas])

  const validate = val => {
    if (disabled) return onError(null)

    if (required && isEmpty(val) && sigCanvas.isEmpty())
      return onError("Required")

    return onError(null)
  }

  const handleInput = () => {
    const url = sigCanvas.toDataURL("image/png")
    // console.log(url)
    onChange(url)
  }

  const clear = () => {
    onChange(null)
    if (sigCanvas) sigCanvas.clear()
    setShowPlaceholder(true)
    if (!isEmpty(onError)) validate(value)
  }

  return (
    <div>
      <span
        className="canvas-field-placeholder no-select"
        style={{
          left: (isMobile ? 338 : 538) / 2 - 30,
          display: showPlaceholder ? "inline" : "none",
        }}
      >
        Please sign here
      </span>
      <SignatureCanvas
        ref={ref => setSigCanvas(ref)}
        canvasProps={{
          width: isMobile ? 338 : 538,
          height: 198,
          className: "sigCanvas",
        }}
        backgroundColor={
          disabled || mode === "view" ? "#f7f7f7" : "rgba(0, 0, 0, 0)"
        }
        onBegin={() => setShowPlaceholder(false)}
        onEnd={handleInput}
      />
      {mode !== "view" && (
        <div
          className="text-right"
          style={{ position: "absolute", width: isMobile ? "338px" : "538px" }}
        >
          <CustomButton
            className="btn-outline-secondary btn-sm"
            onClick={() => clear()}
            disabled={disabled}
          >
            Clear
          </CustomButton>
        </div>
      )}
    </div>
  )
}

const CanvasField = props => {
  // for type: canvas
  const { fieldType, value, onChange, onError, hasError } = props

  const fieldProps = _.pick(props, [
    "fieldType",
    "mode",
    "name",
    "className",
    "required",
    "disabled",
  ])
  fieldProps.className =
    (fieldProps.className || "") +
    " form-control rounded-0" +
    (hasError ? " border-danger" : "")

  const renderCanvasInput = useCallback(
    field => (
      <CanvasInput
        {...fieldProps}
        value={field.input.value}
        onChange={field.input.onChange}
        onError={onError}
      />
    ),
    [props.disabled] // add dynamic props here
  )

  if (fieldType === "form")
    return (
      <div
        className={
          "canvas-field field-wrapper" + (hasError ? " has-error" : "")
        }
      >
        <Field component={renderCanvasInput} {...fieldProps} />
      </div>
    )
  else if (fieldType === "filter")
    return <Field component={renderCanvasInput} {...fieldProps} />
  else
    return (
      <CanvasInput
        {...fieldProps}
        value={value}
        onChange={onChange}
        onError={onError}
      />
    )
}

CanvasField.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  style: PropTypes.object,
  fieldType: PropTypes.string,
  mode: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.string,
  onError: PropTypes.func,
  hasError: PropTypes.bool,
}

CanvasField.defaultProps = {
  fieldType: "form",
  required: false,
  disabled: false,
  hasError: false,
}

export default CanvasField

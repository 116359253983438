import React, { useCallback, useEffect, useState } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import { Button } from "reactstrap"
import { Field } from "redux-form"

import CustomButton from "components/Common/CustomButton"
import Modal from "components/Common/Modal"
import { ApiTable } from "components/Common/Table"

import { isEmpty } from "helpers/cms_helper"

const ModalContainer = ({ children }) => {
  return ReactDOM.createPortal(children, document.body)
}

const SearchInput = props => {
  const {
    formName,
    name,
    modalTitle,
    fetch,
    columns,
    filters,
    display,
    value,
    onChange,
    required,
    disabled,
    onError,
    defaultParams,
  } = props
  const [modalOpen, setModalOpen] = useState(false)

  useEffect(() => {
    if (!isEmpty(onError)) validate(value)
  }, [value])

  const validate = val => {
    if (disabled) return onError(null)

    if (required && isEmpty(val)) return onError("Required")

    return onError(null)
  }

  const toggleModal = (open = true) => {
    setModalOpen(open)
  }

  const handleSelect = row => {
    onChange(row)
    toggleModal(false)
  }

  return (
    <div>
      <CustomButton
        className="btn-outline-dark mr-2"
        onClick={() => toggleModal(true)}
      >
        Search
      </CustomButton>
      <div className="d-inline-block">
        <span>{_.isObject(value) ? display(value) : value}</span>
        {!isEmpty(value) && !required && (
          <>
            <span>&nbsp;</span>
            <Button close disabled={required} onClick={() => onChange(null)} />
          </>
        )}
      </div>

      <ModalContainer>
        <Modal
          title={modalTitle}
          isOpen={modalOpen}
          onToggle={toggleModal}
          size="xl"
        >
          <ApiTable
            name={`${formName}-search-field-${name}`}
            columns={columns}
            filters={filters}
            fetch={params => fetch({ ...params, ...defaultParams, hits: 9999 })}
            fetchOnMount={false}
            pagination={false}
            rowButtons={row => (
              <CustomButton
                className="btn-success"
                onClick={() => handleSelect(row)}
              >
                Select
              </CustomButton>
            )}
          />
          {/* if multi-select is needed, can make use of SearchAndSelectTable */}
        </Modal>
      </ModalContainer>
    </div>
  )
}

const SearchField = props => {
  // for type: search
  const { fieldType, value, onChange, onError, hasError } = props

  const fieldProps = _.pick(props, [
    "fieldType",
    "formName",
    "name",
    "modalTitle",
    "fetch",
    "columns",
    "filters",
    "display",
    "className",
    "style",
    "required",
    "disabled",
    "defaultParams",
  ])
  fieldProps.className =
    (fieldProps.className || "") +
    " form-control rounded-0" +
    (hasError ? " border-danger" : "")

  const renderSearchInput = useCallback(
    field => (
      <SearchInput
        {...fieldProps}
        value={field.input.value}
        onChange={field.input.onChange}
        onError={onError}
      />
    ),
    [props.disabled] // add dynamic props here
  )

  if (fieldType === "form")
    return (
      <div
        className={
          "search-field field-wrapper" + (hasError ? " has-error" : "")
        }
      >
        <Field component={renderSearchInput} {...fieldProps} />
      </div>
    )
  else if (fieldType === "filter")
    return <Field component={renderSearchInput} {...fieldProps} />
  else
    return (
      <SearchInput
        {...fieldProps}
        value={value}
        onChange={onChange}
        onError={onError}
      />
    )
}

SearchField.propTypes = {
  formName: PropTypes.string,
  fieldType: PropTypes.string,
  type: PropTypes.string,
  name: PropTypes.string.isRequired,
  columns: PropTypes.array.isRequired,
  filters: PropTypes.array,
  fetch: PropTypes.func,
  modalTitle: PropTypes.string,
  display: PropTypes.func,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  style: PropTypes.object,
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.object,
  ]),
  onChange: PropTypes.func,
  onError: PropTypes.func,
  hasError: PropTypes.bool,
  defaultParams: PropTypes.object,
}

SearchField.defaultProps = {
  fieldType: "form",
  required: false,
  disabled: false,
  hasError: false,
}

export default SearchField

import React, { useCallback, useEffect, useState } from "react"
import PropTypes from "prop-types"
import { Field } from "redux-form"

import { FilePreview, ResourceUploader } from "./ResourceComponents"

import { isEmpty } from "helpers/cms_helper"

const FileInput = props => {
  const [file, setFile] = useState(null)
  const {
    name,
    value,
    onChange,
    required,
    disabled,
    onError,
    isPrivate,
    userId,
    accept = "*",
    fileName = "",
  } = props

  useEffect(() => {
    if (!isEmpty(onError)) validate(value)
    setFile({ url: value })
  }, [value])

  const validate = val => {
    if (disabled) return onError(null)

    if (required && isEmpty(val)) return onError("Required")

    return onError(null)
  }

  const handleUpload = response => {
    if (response?.url) onChange(response.url)
  }

  const handleClear = () => {
    onChange(null)
  }

  return (
    <div>
      {file && file.url ? (
        <FilePreview file={file} disabled={disabled} onClear={handleClear} />
      ) : (
        <ResourceUploader
          {...props}
          type="file"
          accept={accept}
          disabled={disabled}
          onUpload={handleUpload}
          isPrivate={isPrivate}
          userId={userId}
        />
      )}
    </div>
  )
}

const FileField = props => {
  // for type: file (single file upload)
  const { fieldType, value, onChange, onError, hasError, accept = "*" } = props

  const fieldProps = _.pick(props, [
    "fieldType",
    "name",
    "className",
    "style",
    "required",
    "disabled",
    "isPrivate",
    "userId",
    // hardcode for Dynamic Content File Name
    "fileName",
  ])
  fieldProps.className =
    (fieldProps.className || "") + (hasError ? " border border-danger" : "")

  const renderFileInput = useCallback(
    field => (
      <FileInput
        {...fieldProps}
        value={field.input.value}
        onChange={field.input.onChange}
        onError={onError}
        accept={accept}
      />
    ),
    [props.disabled, props.userId] // add dynamic props here
  )

  if (fieldType === "form")
    return (
      <div
        className={"file-field field-wrapper" + (hasError ? " has-error" : "")}
      >
        <Field component={renderFileInput} {...fieldProps} />
      </div>
    )
  else if (fieldType === "filter")
    return <Field component={renderFileInput} {...fieldProps} />
  else
    return (
      <FileInput
        {...fieldProps}
        value={value}
        onChange={onChange}
        onError={onError}
        accept={accept}
      />
    )
}

FileField.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  style: PropTypes.object,
  fieldType: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.string,
  onError: PropTypes.func,
  isPrivate: PropTypes.bool,
  userId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  hasError: PropTypes.bool,
}

FileField.defaultProps = {
  fieldType: "form",
  required: false,
  disabled: false,
  isPrivate: false,
  hasError: false,
}

export default FileField

import PropTypes from "prop-types"
import React, { useRef } from "react"
import { Row, Col, Alert, Container } from "reactstrap"

import { connect } from "react-redux"
import { withRouter, Link, useLocation } from "react-router-dom"

import { AvForm, AvField } from "availity-reactstrap-validation"

import { userResetPassword } from "store/auth/resetpwd/actions"
import useFocus from "hooks/useFocus"

function ResetPasswordPage(props) {
  const { search } = useLocation()
  const params = new URLSearchParams(search)
  const token = params.get("token")
  const ref = useRef()
  const { isFocused: focusPwd, ...restFocusPwd } = useFocus()
  const { isFocused: focusPwdAgain, ...restFocusPwdAgain } = useFocus()

  function handleValidSubmit(_, values) {
    props.userResetPassword(values, props.history, token)
  }

  function myValidation(_, ctx) {
    const isEqual = ctx.password === ctx.passwordAgain

    return isEqual
  }

  function validatePasswordAgain() {
    ref.current.validateInput("passwordAgain")
  }

  function validatePassword() {
    ref.current.validateInput("password")
  }

  return (
    <div className="bg-pattern">
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2" />
        </Link>
      </div>
      <div className="account-pages pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <div className="pt-6">
                <div className="p-2">
                  <p className="text-uppercase pb-4 zinc_500 bold">
                    shipping portal
                  </p>
                  <p className="pb-4 h3 bold">Reset Password</p>
                  <p>Enter your new password</p>
                  {props.resetError && props.resetError ? (
                    <Alert color="danger" style={{ marginTop: "13px" }}>
                      {props.resetError?.data?.error}
                    </Alert>
                  ) : null}
                  {props.resetSuccessMsg ? (
                    <Alert color="success" style={{ marginTop: "13px" }}>
                      {props.resetSuccessMsg}
                    </Alert>
                  ) : null}

                  <AvForm
                    className="form-horizontal"
                    onValidSubmit={(e, v) => handleValidSubmit(e, v)}
                    ref={ref}
                  >
                    <div
                      className={`form-group ${
                        focusPwd ? "login_focused" : ""
                      }`}
                    >
                      <AvField
                        name="password"
                        label="New Password"
                        className="form-control rounded"
                        placeholder="Enter new password"
                        type="password"
                        required
                        onChange={validatePasswordAgain}
                        errorMessage="Passwords are not matched"
                        validate={{
                          pattern: {
                            value: "^(?=.*[0-9])(?=.*[A-Za-z])[A-Za-z0-9]+$",
                            errorMessage:
                              "Incorrect format, please include both numbers and letters",
                          },
                          minLength: {
                            value: 8,
                            errorMessage:
                              "Password should be least 8 characters long",
                          },
                          myValidation,
                        }}
                        {...restFocusPwd}
                      />
                      <div
                        className={`form-group ${
                          focusPwdAgain ? "login_focused" : ""
                        }`}
                      >
                        <AvField
                          name="passwordAgain"
                          label="Re-enter New Password"
                          className="form-control rounded"
                          placeholder="Enter new password again"
                          type="password"
                          required
                          onChange={validatePassword}
                          errorMessage="Passwords are not matched"
                          validate={{
                            pattern: {
                              value: "^(?=.*[0-9])(?=.*[A-Za-z])[A-Za-z0-9]+$",
                              errorMessage:
                                "Incorrect format, please include both numbers and letters",
                            },
                            minLength: {
                              value: 8,
                              errorMessage:
                                "Password should be least 8 characters long",
                            },
                            myValidation,
                          }}
                          {...restFocusPwdAgain}
                        />
                      </div>
                    </div>
                    <div className="mt-4">
                      <button
                        className="btn sky text-white login_btn waves-effect waves-light rounded-pill"
                        type="submit"
                      >
                        Save
                      </button>
                    </div>
                  </AvForm>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  )
}

ResetPasswordPage.propTypes = {
  resetError: PropTypes.any,
  resetSuccessMsg: PropTypes.any,
  history: PropTypes.object,
  userResetPassword: PropTypes.func,
}

const mapStatetoProps = state => {
  const { resetError, resetSuccessMsg } = state.ResetPassword
  return { resetError, resetSuccessMsg }
}

export default withRouter(
  connect(mapStatetoProps, { userResetPassword })(ResetPasswordPage)
)

import _ from "lodash"

import { getSignedUrl, getUploadUrl, getStorageUrl } from "./backend_helper"
import { toast } from "./cms_helper"

const readFileAsync = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()

    reader.onload = (event) => {
      resolve(event.target.result)
    }

    reader.onerror = (error) => {
      reject(error)
    }

    reader.readAsDataURL(file)
  })
}

export async function uploadImage(file) {
  return await upload(file, "image")
}

export async function uploadFile(file, customUploadFileName) {
  return await upload(file, "file", null, customUploadFileName)
}

export async function uploadPrivate(file, userId) {
  return await upload(file, "private", userId)
}

async function upload(file) {
  const params = {
    filename: file?.name,
  }

  try {
    const res = await getUploadUrl(params)

    if (res?.signedUrl) {
      const imageDataURL = await readFileAsync(file)
      await getStorageUrl(res?.signedUrl, file)

      return {
        signedUrl: res?.signedUrl,
        filename: file?.name,
        imageDataUrl: imageDataURL,
      }
    } else {
      toast("Upload failed", true)
    }
  } catch (e) {
    toast("Upload failed", true)
  }
  return
}

// https://stackoverflow.com/a/38935990
export function b64ToFile(b64Data, filename) {
  var arr = b64Data.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = window.atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n)

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n)
  }

  return new File([u8arr], filename, { type: mime })
}

export function isPrivate() {
  // if (url) return url.search("pii") !== -1
  return false
}

export async function getSignedResourceUrl(url) {
  const res = await getSignedUrl(url)
  return _.get(_.nth(res, 0), "signedUrl")
}

export async function getResourceUrl(url) {
  if (isPrivate(url)) return await getSignedResourceUrl(url)
  else return url
}

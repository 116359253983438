import React from "react"
import { Redirect } from "react-router-dom"

// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import ForgetPwd from "../pages/Authentication/ForgetPassword"
import ResetPassword from "pages/Authentication/ResetPassword"

import { Profile } from "../pages/ProfilePage"

import {
  Admin,
  ADMIN_PERMISSIONS,
  ADMIN_ROLE_SUPER,
} from "../pages/AdminManagement"
import { User } from "pages/User"
import {
  TreeSubmissionList,
  TreeSubmissionDetail,
  TreeApprovalList,
  TreeRegistryList,
} from "pages/TreeSubmission"
import { TreeSpecies } from "pages/TreeSpecies"
import { StaticPages, StaticPagesEdit } from "pages/StaticPages"
import { TreeIssue, TreeIssueDetail } from "pages/ReportIssue"
import { AppSetting } from "pages/AppSetting"

import Page403 from "pages/Authentication/Page403"
import { Faq, FaqDetail } from "pages/Faq"

import { getUser } from "helpers/localstorage/auth"

const me = getUser()

const userRoutes = [
  { path: "/profile", component: Profile, title: "Profile" },

  // Admin Management
  {
    path: "/admins",
    component: Admin,
    title: "Admins",
    permission: ADMIN_PERMISSIONS.adminManagement,
  },
  {
    path: "/users",
    component: User,
    title: "Users",
    permission: ADMIN_PERMISSIONS.user,
  },
  // Tree Species
  {
    path: "/tree-species-listings",
    component: TreeSpecies,
    title: "Tree Species",
    permission: ADMIN_PERMISSIONS.treeSpecies,
  },
  // Tree List
  {
    path: "/tree-listings/all-records",
    component: TreeSubmissionList,
    title: "All Records",
    permission: ADMIN_PERMISSIONS.tree,
  },
  {
    path: "/tree-listings/pending-records",
    component: TreeApprovalList,
    title: "Pending Records",
    permission: ADMIN_PERMISSIONS.tree,
  },
  {
    path: "/tree-listings/verified",
    component: TreeRegistryList,
    title: "Verified",
    permission: ADMIN_PERMISSIONS.tree,
  },
  {
    path: "/tree-listings/:id",
    component: TreeSubmissionDetail,
    title: "Tree Submission",
    permission: ADMIN_PERMISSIONS.tree,
  },
  // Static Pages
  {
    path: "/static-pages",
    component: StaticPages,
    title: "Static Pages",
    permission: ADMIN_PERMISSIONS.staticPage,
  },
  {
    path: "/static-pages/faq",
    component: Faq,
    title: "Static Page | FAQ",
    permission: ADMIN_PERMISSIONS.staticPage,
  },
  {
    path: "/static-pages/faq/:id",
    component: FaqDetail,
    title: "Static Page | FAQ",
    permission: ADMIN_PERMISSIONS.staticPage,
  },
  {
    path: "/static-pages/:page",
    component: StaticPagesEdit,
    title: "Static Pages Edit",
    permission: ADMIN_PERMISSIONS.staticPage,
  },
  // Tree Issue
  {
    path: "/tree-issue",
    component: TreeIssue,
    permission: ADMIN_PERMISSIONS.treeIssue,
  },
  {
    path: "/tree-issue/:id",
    component: TreeIssueDetail,
    permission: ADMIN_PERMISSIONS.treeIssue,
  },
  // App Setting
  {
    path: "/app-setting",
    component: AppSetting,
    permission: ADMIN_PERMISSIONS.appSetting,
  },
  // Others
  { path: "/403", component: Page403, title: "Forbidden" },

  // this route should be at the end of all other routes
  {
    path: "/",
    exact: true,
    component: () => (
      <Redirect
        to={
          me?.accountType === ADMIN_ROLE_SUPER
            ? "/admins"
            : "/tree-species-listings"
        }
      />
    ),
  },
  {
    path: "*",
    component: () => <Redirect to={"/"} />,
  },
]

const authRoutes = [
  { path: "/logout", component: Logout, title: "Logout" },
  { path: "/login", component: Login, title: "Login" },
  { path: "/forgot-password", component: ForgetPwd, title: "Forgot Password" },
  {
    path: "/reset_password",
    component: ResetPassword,
    title: "Reset Password",
  },
]

export { userRoutes, authRoutes }

import PropTypes from "prop-types"
import React from "react"
import { Row, Col, Alert, Container } from "reactstrap"

// Redux
import { connect } from "react-redux"
import { withRouter, Link } from "react-router-dom"

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation"

// action
import { userForgetPassword, userInitForgetPassword } from "../../store/actions"
import useFocus from "hooks/useFocus"

const ForgetPasswordPage = props => {
  const { isFocused: focus, ...restFocus } = useFocus()

  function handleValidSubmit(_, values) {
    props.userForgetPassword(values, props.history)
  }

  return (
    <div className="bg-pattern">
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2" />
        </Link>
      </div>
      <div className="account-pages pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <div className="pt-6">
                <div className="p-2">
                  <p className="text-uppercase pb-4 zinc_500 bold">
                    shipping portal
                  </p>
                  <p className="pb-4 h3 bold">Reset Password</p>
                  <p>
                    Enter the email address associated with your account and
                    we`ll send you a link to reset your password.
                  </p>
                  {props.forgetError && props.forgetError ? (
                    <Alert color="danger" style={{ marginTop: "13px" }}>
                      {props.forgetError}
                    </Alert>
                  ) : null}
                  {props.forgetSuccessMsg ? (
                    <Alert color="success" style={{ marginTop: "13px" }}>
                      {props.forgetSuccessMsg}
                    </Alert>
                  ) : null}

                  <AvForm
                    className="form-horizontal"
                    onValidSubmit={(e, v) => handleValidSubmit(e, v)}
                  >
                    <div
                      className={`form-group position-relative ${
                        focus ? "login_focused" : ""
                      }`}
                    >
                      <Link
                        to="/login"
                        className="text_sky position-absolute right remove_hover"
                        onClick={props.userInitForgetPassword}
                      >
                        Back to Login
                      </Link>
                      <AvField
                        name="email"
                        label="Email"
                        className="form-control rounded"
                        placeholder="Enter email"
                        type="email"
                        required
                        {...restFocus}
                      />
                    </div>
                    <div className="mt-4">
                      <button
                        className="btn sky text-white login_btn waves-effect waves-light rounded-pill"
                        type="submit"
                      >
                        Reset
                      </button>
                    </div>
                  </AvForm>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  )
}

ForgetPasswordPage.propTypes = {
  forgetError: PropTypes.any,
  forgetSuccessMsg: PropTypes.any,
  history: PropTypes.object,
  userForgetPassword: PropTypes.func,
  userInitForgetPassword: PropTypes.func,
}

const mapStatetoProps = state => {
  const { forgetError, forgetSuccessMsg } = state.ForgetPassword
  return { forgetError, forgetSuccessMsg }
}

export default withRouter(
  connect(mapStatetoProps, { userForgetPassword, userInitForgetPassword })(
    ForgetPasswordPage
  )
)

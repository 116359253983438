import React from "react"
import { AdminList } from "."

const Admin = () => {
  return (
    <>
      <AdminList />
    </>
  )
}

export default Admin

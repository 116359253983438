import React, { useEffect, useState } from "react"
import { useDispatch } from "react-redux"

import { UPDATE_PROFILE } from "store/auth/profile/actionTypes"

import BaseLayout from "components/BaseLayout"
import { ApiForm } from "components/Common/Form"
import CustomButton from "components/Common/CustomButton"
import Modal from "components/Common/Modal"

import { toast } from "helpers/cms_helper"
import { getMe, editAdmin, updateMePassword } from "helpers/backend_helper"
import { getUser, setUser } from "helpers/localstorage/auth"

const Profile = () => {
  const [me, setMe] = useState({})
  const [resetPasswordModalOpen, setResetPasswordModalOpen] = useState(false)
  const dispatch = useDispatch()

  useEffect(() => {
    getMe().then((res) => {
      setMe({
        ...res,
      })
    })
  }, [])

  const fields = [
    {
      label: "name",
      type: "text",
      name: "displayName",
      required: true,
      style: { maxWidth: "540px" },
    },
    {
      label: "Email Address",
      name: "email",
      type: "email",
      required: true,
    },
  ]

  const resetPasswordFields = [
    {
      label: "New Password",
      name: "newPassword",
      type: "password",
      required: true,
    },
    {
      label: "Re-enter New Password",
      name: "reenterNewPassword",
      type: "password",
      required: true,
    },
  ]

  const extraSubmitButtons = (
    <CustomButton
      className="btn-outline-dark"
      onClick={() => toggleResetPasswordModal(true)}
    >
      Reset Password
    </CustomButton>
  )

  const toggleResetPasswordModal = (open = true) => {
    setResetPasswordModalOpen(open)
  }

  async function handleEditSubmit(values) {
    // console.log(values)

    const res = await editAdmin(me?.id, values)
    toast("Updated")
    const existingUser = getUser()
    setUser({ ...existingUser, ...res.updated, displayName: res.displayName })
    dispatch({
      type: UPDATE_PROFILE,
      payload: {
        ...existingUser,
        ...res.updated,
        displayName: res.displayName,
      },
    })
  }

  async function handleResetPassword(values) {
    if (values.newPassword !== values.reenterNewPassword)
      throw "New passwords do not match"

    await updateMePassword({
      password: values.newPassword,
    })
    toast("Updated")
    toggleResetPasswordModal(false)
  }

  return (
    <BaseLayout title="Profile">
      <h5 className="sub-title">My Information</h5>
      <ApiForm
        name="profile-edit"
        mode="edit"
        fields={fields}
        data={me}
        onSubmit={handleEditSubmit}
        extraSubmitButtons={extraSubmitButtons}
      />

      <Modal
        title="Reset Password"
        isOpen={resetPasswordModalOpen}
        onToggle={toggleResetPasswordModal}
      >
        <ApiForm
          name="reset-Password"
          fields={resetPasswordFields}
          onSubmit={handleResetPassword}
        />
      </Modal>
    </BaseLayout>
  )
}

export default Profile

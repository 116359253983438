import StaticPages from "./StaticPages"
import StaticPagesEdit from "./StaticPagesEdit"

export const ABOUT_MY_TREE = "About_MyTree"
export const TREE_SURVEY_PROTOCOL = "Tree_Survey_Protocol"
export const FAQ = "FAQ"
export const TERMS_AND_POLICY = "Terms&Conditions"
export const PRIVACY_POLICY = "Privacy_Policy"

export const STATIC_PAGES_DISPLAY = {
  [ABOUT_MY_TREE]: "About MyTree",
  [TREE_SURVEY_PROTOCOL]: "Tree Survey Protocol",
  [FAQ]: "FAQ",
  [TERMS_AND_POLICY]: "Terms & Conditions",
  [PRIVACY_POLICY]: "Privacy Policy",
}

export const STATIC_PAGES_URL_TO_CONSTANT = {
  "about-mytree": ABOUT_MY_TREE,
  "tree-survey-protocol": TREE_SURVEY_PROTOCOL,
  faq: FAQ,
  "terms-and-conditions": TERMS_AND_POLICY,
  "privacy-policy": PRIVACY_POLICY,
}

export { StaticPages, StaticPagesEdit }

import React, { useRef, useState } from "react"

import BaseLayout from "components/BaseLayout"
import CustomButton from "components/Common/CustomButton"
import Modal from "components/Common/Modal"
import { ApiTable } from "components/Common/Table"
import { ApiForm } from "components/Common/Form"

import { editTreeSpecies, getTreeSpecies } from "helpers/backend_helper"
import { toast } from "helpers/cms_helper"

import { getUser } from "helpers/localstorage/auth"
import { ADMIN_ROLE_SUPER } from "pages/AdminManagement"

import _ from "lodash"

const TreeSpeciesList = () => {
  const [editModalOpen, setEditModalOpen] = useState(false)
  const [editingRow, setEditingRow] = useState(null)
  const [avgWoodDensity, setAvgWoodDensity] = useState(0)
  const tableRef = useRef(null)
  const me = getUser()

  // const editing = !!editingRow

  const columns = [
    { title: "Species / 種", value: "scientificName" },
    {
      title: "Common Name / 英文名",
      value: (row) => row?.commonNames?.join(", "),
    },
    { title: "Synonym / 異名", value: (row) => row?.synonyms?.join(", ") },
    { title: "中文名", value: (row) => row?.chineseNames?.join(", ") },
    { title: "Wood Density", value: "woodDensity" },
    { title: "Vulnerable List", value: (row) => (row?.vulnerable ? "Y" : "N") },
  ]

  const filters = [
    {
      rowId: 0,
      rowName: "Filter",
      fields: [
        {
          placeholder: "Name",
          name: "keyword",
          type: "text",
          icon: "search",
        },
      ],
    },
  ]

  const editFields = [
    {
      label: "Scientific Name",
      name: "scientificName",
    },
    {
      label: "Synonyms",
      name: "synonyms",
    },
    {
      label: "Common Names",
      name: "commonNames",
    },
    {
      label: "Chinese Names",
      name: "chineseNames",
    },
    {
      label: "Vulnerability",
      name: "vulnerable",
      type: "select",
      options: [
        { label: "Y", value: true },
        { label: "N", value: false },
      ],
      required: true,
    },
    {
      label: "Wood density",
      name: "woodDensity",
      type: "number",
      required: true,
    },
  ]

  const toggleEditModal = (open = true) => {
    if (open === false) setEditingRow(null)
    setEditModalOpen(open)
  }

  async function fetch(params) {
    const res = await getTreeSpecies(params)
    if (res?.avgWoodDensity) setAvgWoodDensity(() => res?.avgWoodDensity)
    return res
  }

  async function handleEditSubmit(values) {
    const params = _.pick(values, [
      "scientificName",
      "vulnerable",
      "woodDensity",
    ])
    params.commonNames = values?.commonNames?.split(",")?.filter((str) => str)
    params.synonyms = values?.synonyms?.ssplit(",")?.filter((str) => str)
    params.chineseNames = values?.chineseNames?.split(",")?.filter((str) => str)

    await editTreeSpecies(editingRow.id, params)
    toast("Updated")
    toggleEditModal(false)
    tableRef.current.refetch()
  }

  return (
    <BaseLayout title="Tree species listings">
      <p
        style={{ "font-weight": "bold" }}
      >{`Wood density for Species not provided: ${avgWoodDensity.toFixed(
        4
      )}`}</p>
      <ApiTable
        ref={tableRef}
        name="tree-species"
        fetch={fetch}
        columns={columns}
        filters={filters}
        rowButtons={(row) =>
          me?.accountType === ADMIN_ROLE_SUPER && (
            <>
              <CustomButton
                className="btn-outline-dark"
                onClick={() => {
                  setEditingRow({
                    ...row,
                    commonNames: row?.commonNames?.join(",") || "",
                    synonyms: row?.synonyms?.join(",") || "",
                    chineseNames: row?.chineseNames?.join(",") || "",
                  })
                  toggleEditModal(true)
                }}
              >
                Edit
              </CustomButton>
            </>
          )
        }
      />

      <Modal
        title="Edit Tree Species"
        isOpen={editModalOpen}
        onToggle={toggleEditModal}
      >
        <ApiForm
          name="tree-species-edit"
          mode="edit"
          fields={editFields}
          data={editingRow}
          onSubmit={handleEditSubmit}
        />
      </Modal>
    </BaseLayout>
  )
}

export default TreeSpeciesList

// import User from "./User"
import TreeSubmissionList from "./TreeSubmissionList"
import TreeApprovalList from "./TreeApprovalList"
import TreeRegistryList from "./TreeRegistryList"
import TreeSubmissionDetail from "./TreeSubmissionDetail"
import TreeSubmissionImagesField from "./TreeSubmissionImagesField"
import TreeSubmissionStemNoField from "./TreeSubmissionStemNoField"
import TreeSubmissionStemsField from "./TreeSubmissionStemsField"
import TreeSpeciesField from "./TreeSpeciesField"
import TreeSubmissionLocationField from "./TreeSubmissionLocationField"

export const LISTING_STATUS_IN_REVIEW = "IN_REVIEW"
export const LISTING_STATUS_VERIFIED = "VERIFIED"
export const LISTING_STATUS_HIDDEN = "HIDDEN"
export const LISTING_STATUS_REJECTED = "REJECTED"

export const RESOURCE_TYPES = [
  "WHOLE_TREE",
  "TREE_TRUNK",
  "TREE_LEAF",
  "OTHER1",
  "OTHER2",
  "OTHER3",
  "OTHER4",
  "OTHER5",
  "OTHER6",
  "OTHER7",
  "OTHER8",
  "OTHER9",
  "OTHER10",
]

export const TYPE_ORDER = {
  WHOLE_TREE: 1,
  TREE_TRUNK: 2,
  TREE_LEAF: 3,
  OTHER1: 4,
  OTHER2: 5,
  OTHER3: 6,
  OTHER4: 7,
  OTHER5: 8,
  OTHER6: 9,
  OTHER7: 10,
  OTHER8: 11,
  OTHER9: 12,
  OTHER10: 13,
}

export const DISTRICT_OPTIONS = [
  {
    label: "Hong Kong Island",
    value: "Hong Kong Island",
  },
  {
    label: "Kowloon",
    value: "Kowloon",
  },
  {
    label: "New Territories",
    value: "New Territories",
  },
  {
    label: "Outlying Islands",
    value: "Outlying Islands",
  },
  {
    label: "Outside Hong Kong",
    value: "Outside Hong Kong",
  },
]

export const RESOURCE_TYPES_DISPLAY = {
  WHOLE_TREE: "Whole Tree",
  TREE_TRUNK: "Tree Trunk",
  TREE_LEAF: "Tree Leaf",
  OTHER1: "Others",
  OTHER2: "Others",
  OTHER3: "Others",
  OTHER4: "Others",
  OTHER5: "Others",
  OTHER6: "Others",
  OTHER7: "Others",
  OTHER8: "Others",
  OTHER9: "Others",
  OTHER10: "Others",
}

export const TREE_SUBMISSION_STATUS_DISPLAY = {
  [LISTING_STATUS_IN_REVIEW]: "In Review",
  [LISTING_STATUS_VERIFIED]: "Verified",
  [LISTING_STATUS_HIDDEN]: "Hidden",
  [LISTING_STATUS_REJECTED]: "Rejected",
}

export const TREE_SUBMISSION_STATUS_OPTIONS = [
  {
    label: "In Review",
    value: LISTING_STATUS_IN_REVIEW,
  },
  {
    label: "Verified",
    value: LISTING_STATUS_VERIFIED,
  },
  {
    label: "Hidden",
    value: LISTING_STATUS_HIDDEN,
  },
  {
    label: "Rejected",
    value: LISTING_STATUS_REJECTED,
  },
]

export const TREE_SUBMISSION_POM_OPTIONS = [
  {
    label: "1.3",
    value: 1.3,
  },
  {
    label: "1.2",
    value: 1.2,
  },
  {
    label: "1.1",
    value: 1.1,
  },
  {
    label: "1.0",
    value: 1,
  },
]

export const DEFAULT_STEM_OBJECT = {
  pointOfMeasurement: 0,
  circumferenceAtBreastHeight: null,
  diameterAtBreastHeight: null,
  treeHeight: null,
}

export {
  TreeSubmissionList,
  TreeApprovalList,
  TreeRegistryList,
  TreeSubmissionDetail,
  TreeSubmissionImagesField,
  TreeSubmissionStemNoField,
  TreeSubmissionStemsField,
  TreeSpeciesField,
  TreeSubmissionLocationField,
}

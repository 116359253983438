import React from "react"
import ReactDOM from "react-dom"
import { toast as toastifyToast } from "react-toastify"
import moment from "moment"
import countries from "./countries.json"
import _ from "lodash"
import ConfirmModal from "components/Common/ConfirmModal"

import { getScrollOffset, setBadge } from "./localstorage/sidebar"

export const isLocalhost = Boolean(
  window.location.hostname === "localhost" ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === "[::1]" ||
    // 127.0.0.0/8 are considered localhost for IPv4.
    window.location.hostname.match(
      /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    )
)

export const MULTILANG_TABS = [
  { label: "English", value: "EN" },
  { label: "繁體中文", value: "TC" },
  // { label: "简体中文", value: "SC" },
]

export const COUNTRIES = countries.map((country) => {
  return {
    label: country.nameEN,
    value: country.value,
  }
})

export const COUNTRY_OPTIONS = COUNTRIES.filter(
  (countries) => countries.value !== "Hong Kong"
) // Ship from supports non hk countries

export const AREA_CODES = Object.keys(
  _.groupBy(
    countries.map((country) => `+${country["Tel country code"]}`).sort(),
    (val) => val
  )
)

export const CURRENCIES = ["HKD", "USD"]

const WINDOW_WIDTH = $(document).width()
export const isMobile = WINDOW_WIDTH <= 600

export function toast(message, error = false) {
  toastifyToast(message, {
    position: toastifyToast.POSITION.BOTTOM_RIGHT,
    className: error ? "toast-error" : "toast-normal",
  })
}

export function confirm(message, onConfirm, confirmButtonClassName = null) {
  ReactDOM.render(
    <ConfirmModal
      message={message}
      onConfirm={onConfirm}
      confirmButtonClassName={confirmButtonClassName}
    />,
    document.getElementById("page-content-append")
  )
}

export function setDocumentTitle(title) {
  document.title = `${title} | MyTree Admin Portal`
}

export function setSidebarBadge(badgeName, value, storage = true) {
  if (storage) setBadge(badgeName, value)
  const badgeElement = document.getElementById(`sidebar-badge-${badgeName}`)
  if (badgeElement) {
    badgeElement.innerHTML = value
    badgeElement.style.visibility = value ? "visible" : "hidden"
  }
}

export function scrollSidebar() {
  const scrollMenu = (document.getElementsByClassName(
    "simplebar-content-wrapper"
  ) || [null])[0]
  const scrollOffset = getScrollOffset()
  if (scrollMenu && scrollOffset) scrollMenu.scrollTop = scrollOffset
}

export function isEmpty(val) {
  return [null, undefined, ""].indexOf(val) !== -1
}

export function dateToISO(date, time = true) {
  if (!date) return ""
  try {
    // for date-only value, set time to 12:00 to keep the same date no matter UTC / HKT
    return moment(time ? date : date.setHours(12)).toISOString()
  } catch {
    console.error("invalid date")
    return ""
  }
}

export function dateToStr(date, format = "MMM D, YYYY HH:mm:ss") {
  // date: Date() object | ISO formatted string
  if (!date) return ""
  try {
    return moment(date).format(format)
  } catch {
    console.error("invalid date")
    return ""
  }
}

export function strToDate(s, format = "YYYY-MM-DD") {
  if (s) {
    try {
      return moment(s, format).toDate()
    } catch {
      console.error("invalid date")
    }
  }
  return s
}

export function toTitleCase(s) {
  // https://stackoverflow.com/a/60610887
  return s.toLowerCase().replace(/(^\w|\s\w)/g, (m) => m.toUpperCase())
}

export function roundNumber(num, decimals = 2) {
  if (isEmpty(num)) return num
  return Math.round(num * Math.pow(10, decimals)) / Math.pow(10, decimals)
}

// https://stackoverflow.com/a/2901298
export function numberWithCommas(n) {
  try {
    const nStr = n.toString()
    if (nStr.indexOf(".") !== -1)
      return nStr.replace(/\B(?=(?=\d*\.)(\d{3})+(?!\d))/g, ",")
    else return nStr.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
  } catch {
    return "NaN"
  }
}

export function getRangeDisplay(currency, lowerBound, upperBound) {
  return `${
    lowerBound && !upperBound ? ">= " : !lowerBound && upperBound ? "<= " : ""
  }
${lowerBound ? `${currency}${numberWithCommas(lowerBound)} ` : ""}
${lowerBound && upperBound ? "- " : ""}
${upperBound ? `${currency}${numberWithCommas(upperBound)}` : ""}`
}

export const AUTHENTICATION_OPTIONS = {
  BASIC: "Basic Service",
  ADVANCED: "Advanced Service",
}

export const ACTIVE_STATUS = {
  ACTIVE: "ACTIVE",
  INACTIVE: "INACTIVE",
}

export const getLogo = (user) => {
  return user?.shippingAgent?.logoUri
}

export function isExceedTwoWeeks(selectedDate) {
  if (!selectedDate) {
    return false
  }
  const twoWeeksPrior = moment().subtract(14, "days").startOf("day")
  const targetDate = moment(selectedDate).startOf("day")
  const exceedsTwoWeeks =
    twoWeeksPrior.isAfter(targetDate) || twoWeeksPrior.isSame(targetDate)

  return exceedsTwoWeeks
}

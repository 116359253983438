import React, { useCallback, useEffect, useState } from "react"
import PropTypes from "prop-types"
import { Field } from "redux-form"

import { ImagePreview, ResourceUploader } from "./ResourceComponents"

import { isEmpty } from "helpers/cms_helper"

import _ from "lodash"

const ImageInput = (props) => {
  const [image, setImage] = useState(null)
  const {
    value,
    onChange,
    required,
    disabled,
    onError,
    className,
    size,
    includeFilename,
    isPrivate,
    userId,
    accept = "*",
  } = props

  useEffect(() => {
    if (!isEmpty(onError)) validate(value)
    setImage({
      url: value,
    })
  }, [value])

  const validate = (val) => {
    if (disabled) return onError(null)

    if (required && isEmpty(val)) return onError("Required")
    return onError(null)
  }

  const handleUpload = (response) => {
    if (response?.signedUrl && response?.imageDataUrl) {
      if (includeFilename) onChange(response)
      else onChange(response.url)
    }
  }

  const handleClear = () => {
    onChange(null)
  }

  return (
    <div>
      {image && image.url ? (
        <ImagePreview
          image={image}
          disabled={disabled}
          onClear={handleClear}
          size={size}
        />
      ) : (
        <ResourceUploader
          type="image"
          disabled={disabled}
          onUpload={handleUpload}
          className={className ? className : ""}
          size={size}
          isPrivate={isPrivate}
          userId={userId}
          accept={accept}
        />
      )}
    </div>
  )
}

const ImageField = (props) => {
  // for type: image (single image upload)
  const { fieldType, value, onChange, onError, hasError } = props

  const fieldProps = _.pick(props, [
    "fieldType",
    "name",
    "className",
    "style",
    "required",
    "disabled",
    "size",
    "includeFilename",
    "isPrivate",
    "userId",
    "accept",
  ])
  fieldProps.className =
    (fieldProps.className || "") + (hasError ? " border border-danger" : "")

  const renderImageInput = useCallback(
    (field) => (
      <ImageInput
        {...fieldProps}
        value={field.input.value}
        onChange={field.input.onChange}
        onError={onError}
      />
    ),
    [props.disabled, props.userId] // add dynamic props here
  )

  if (fieldType === "form")
    return (
      <div
        className={"image-field field-wrapper" + (hasError ? " has-error" : "")}
      >
        <Field component={renderImageInput} {...fieldProps} />
      </div>
    )
  else if (fieldType === "filter")
    return <Field component={renderImageInput} {...fieldProps} />
  else
    return (
      <ImageInput
        {...fieldProps}
        value={value}
        onChange={onChange}
        onError={onError}
      />
    )
}

ImageInput.propTypes = {
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.string,
  onError: PropTypes.func,
  size: PropTypes.string,
  includeFilename: PropTypes.bool,
  isPrivate: PropTypes.bool,
  userId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  accept: PropTypes.string,
}

ImageField.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.object,
  fieldType: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.string,
  onError: PropTypes.func,
  size: PropTypes.string,
  includeFilename: PropTypes.bool,
  isPrivate: PropTypes.bool,
  userId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  hasError: PropTypes.bool,
}

ImageField.defaultProps = {
  fieldType: "form",
  required: false,
  disabled: false,
  includeFilename: false,
  isPrivate: false,
  hasError: false,
}

export default ImageField

import React, { useRef, useState } from "react"

import BaseLayout from "components/BaseLayout"
import CustomButton from "components/Common/CustomButton"
import Modal from "components/Common/Modal"
import { ApiTable } from "components/Common/Table"
import { ApiForm } from "components/Common/Form"

import { ADMIN_ROLE_SUPER, ADMIN_ROLES } from ".."
import { getAdmins, createAdmin, editAdmin } from "helpers/backend_helper"
import { isEmpty, toast } from "helpers/cms_helper"

import _ from "lodash"

const roles = {
  SUPER: "Admin",
  EXPERT: "Expert",
}

const AdminList = () => {
  const [createModalOpen, setCreateModalOpen] = useState(false)
  const [editModalOpen, setEditModalOpen] = useState(false)
  const [editingRow, setEditingRow] = useState(null)
  const tableRef = useRef(null)

  // const editing = !!editingRow

  const columns = [
    { title: "Admin Email", value: "email" },
    { title: "Admin Name", value: "displayName" },
    { title: "Admin Type", value: (row) => roles[row?.accountType] },
    { title: "Creation Date", value: "createdAt", format: "date" },
    { title: "Admin Status", value: "status" },
    {
      title: "Block",
      value: (row) => row.status === "INACTIVE",
      format: "toggle",
      onToggle: (row, val) => handleToggleActive(row, val),
    },
  ]

  const filters = [
    {
      rowId: 0,
      rowName: "Filter",
      fields: [
        {
          placeholder: "Email or Admin Name",
          name: "keyword",
          type: "text",
          icon: "search",
        },
      ],
    },
    {
      rowId: 1,
      rowName: " ",
      fields: [
        {
          placeholder: "Admin Type",
          name: "accountType",
          type: "select",
          options: ADMIN_ROLES.map((role) => ({
            label:
              role === ADMIN_ROLE_SUPER
                ? "Admin"
                : _.capitalize(role.toLowerCase()),
            value: role,
          })),
          multiple: true,
        },
        {
          placeholder: "Admin Status",
          name: "status",
          type: "select",
          options: [
            {
              label: "Active",
              value: "ACTIVE",
            },
            {
              label: "Inactive",
              value: "INACTIVE",
            },
          ],
          multiple: true,
        },
      ],
    },
  ]

  const createFields = [
    {
      label: "Admin Email",
      name: "email",
      type: "email",
      required: true,
    },
    {
      label: "Admin Name (First Name & Last Name)",
      name: "displayName",
      type: "text",
      required: true,
    },
    {
      label: "Admin Type",
      name: "accountType",
      type: "select",
      options: ADMIN_ROLES.map((role) => ({
        label:
          role === ADMIN_ROLE_SUPER
            ? "Admin"
            : _.capitalize(role.toLowerCase()),
        value: role,
      })),
      required: true,
    },
    {
      label: "Password",
      name: "password",
      type: "password",
      required: true,
    },
  ]

  const editFields = [
    {
      label: "Admin Email",
      name: "email",
      type: "email",
      required: true,
    },
    {
      label: "Admin Name",
      name: "displayName",
      type: "text",
      required: true,
    },
    {
      label: "Admin Type",
      name: "accountType",
      type: "select",
      options: ADMIN_ROLES.map((role) => ({
        label:
          role === ADMIN_ROLE_SUPER
            ? "Admin"
            : _.capitalize(role.toLowerCase()),
        value: role,
      })),
      required: true,
    },
    {
      label: "Account Status",
      name: "status",
      type: "select",
      options: [
        {
          label: "Active",
          value: "ACTIVE",
        },
        {
          label: "Inactive",
          value: "INACTIVE",
        },
      ],
      required: true,
    },
    {
      label: "Password",
      name: "passwordNew",
      type: "password",
    },
  ]

  const headerButtons = (
    <CustomButton
      className="btn-dark"
      mobileContent="+ Add"
      onClick={() => toggleCreateModal(true)}
    >
      + Admin
    </CustomButton>
  )

  const toggleCreateModal = (open = true) => {
    setCreateModalOpen(open)
  }

  const toggleEditModal = (open = true) => {
    if (open === false) setEditingRow(null)
    setEditModalOpen(open)
  }

  async function fetch(params) {
    return getAdmins(params)
  }

  async function handleCreateSubmit(values) {
    const params = _.pick(values, [
      "name",
      "email",
      "password",
      "displayName",
      "accountType",
    ])
    await createAdmin(params)
    toggleCreateModal(false)
    toast("Created")
    tableRef.current.refetch()
  }

  async function handleEditSubmit(values) {
    const params = _.pick(values, [])

    if (!isEmpty(values.passwordNew)) params.password = values.passwordNew

    await editAdmin(editingRow.id, params)
    toast("Updated")
    toggleEditModal(false)
    tableRef.current.refetch()
  }

  async function handleToggleActive(row, val) {
    await editAdmin(row.id, { status: val === true ? "INACTIVE" : "ACTIVE" })
    toast(val === true ? "DeactivatedActivated" : "Activated")
    tableRef.current.refetch()
  }

  return (
    <BaseLayout title="Admin List" headerButtons={headerButtons}>
      <ApiTable
        ref={tableRef}
        name="admins"
        fetch={fetch}
        columns={columns}
        filters={filters}
        rowButtons={(row) => (
          <>
            <CustomButton
              className="btn-outline-dark"
              onClick={() => {
                setEditingRow({
                  ...row,
                })
                toggleEditModal(true)
              }}
            >
              Edit
            </CustomButton>
          </>
        )}
      />

      <Modal
        title="Create Admin"
        isOpen={createModalOpen}
        onToggle={toggleCreateModal}
      >
        <ApiForm
          name="admin-create"
          fields={createFields}
          onSubmit={handleCreateSubmit}
        />
      </Modal>

      <Modal
        title="Edit Admin"
        isOpen={editModalOpen}
        onToggle={toggleEditModal}
      >
        <ApiForm
          name="admin-edit"
          mode="edit"
          fields={editFields}
          data={editingRow}
          onSubmit={handleEditSubmit}
        />
      </Modal>
    </BaseLayout>
  )
}

export default AdminList

import React from "react"
import { TreeIssueList } from "."

const TreeIssue = () => {
  return (
    <>
      <TreeIssueList />
    </>
  )
}

export default TreeIssue

import { combineReducers } from "redux"
import { reducer as formReducer } from "redux-form"

// Front
import Layout from "./layout/reducer"

// Authentication
import Login from "./auth/login/reducer"
import Account from "./auth/register/reducer"
import ForgetPassword from "./auth/forgetpwd/reducer"
import Profile from "./auth/profile/reducer"
import Api from "./api/reducer"
import ResetPassword from "./auth/resetpwd/reducer"
import tree from "./tree/reducer"

const rootReducer = combineReducers({
  // public
  Layout,
  Login,
  Account,
  ForgetPassword,
  Profile,
  Api,
  ResetPassword,
  tree,
  // https://redux-form.com/
  form: formReducer,
})

export default rootReducer

import PropTypes from "prop-types"
import React from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"

// //Import Scrollbar
import SimpleBar from "simplebar-react"

import SidebarContent from "./SidebarContent"

const Sidebar = props => {
  const style = { maxHeight: "100%" }
  return (
    <React.Fragment>
      <div className="vertical-menu">
        <div data-simplebar className="h-100">
          <SimpleBar style={style}>
            <SidebarContent />
          </SimpleBar>
        </div>
      </div>
    </React.Fragment>
  )
}

Sidebar.propTypes = {
}

export default withRouter(Sidebar)

import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"

import { FieldLabel, FormField } from "components/Common/Form"

import { AREA_CODES, isEmpty } from "helpers/cms_helper"

const PhoneNumberField = props => {
  const { value, onChange, required, disabled, onError, submitted } = props
  const [val, setVal] = useState({ areaCode: "", phoneNumber: "" })
  const [error, setError] = useState({}) // object of {fieldName: "errorMsg"}

  useEffect(() => {
    if (isEmpty(value)) {
      // reset
      setVal({ areaCode: "", phoneNumber: "" })
    } else {
      let toSet = _.cloneDeep(value)
      for (const key in toSet) {
        if (isEmpty(toSet[key])) toSet[key] = ""
      }
      setVal(toSet)
    }
  }, [value])

  useEffect(() => {
    for (const key in error) {
      if (!isEmpty(error[key])) return onError(true)
    }
    return onError(false)
  }, [error])

  const handleChange = val => {
    onChange(val)
  }

  const handleError = (fieldName, errorMsg) => {
    setError(prevState => ({ ...prevState, [fieldName]: errorMsg }))
  }

  return (
    <div className="row">
      <div className="col-4">
        <FieldLabel
          name="areaCode"
          label="Area Code"
          required={required}
          hasError={submitted && !!error.areaCode}
        />
        <FormField
          fieldType="custom"
          name="areaCode"
          type="select"
          options={AREA_CODES}
          disabled={disabled}
          required={required}
          value={val.areaCode}
          onChange={v => handleChange({ ...val, areaCode: v })}
          onError={errMsg => handleError("areaCode", errMsg)}
          hasError={submitted && !!error.areaCode}
        />
        {submitted && error.areaCode && (
          <span className="text-danger">{error.areaCode}</span>
        )}
      </div>

      <div className="col-8">
        <FieldLabel
          name="phoneNumber"
          label="Phone Number"
          required={required}
          hasError={submitted && !!error.phoneNumber}
        />
        <FormField
          fieldType="custom"
          name="phoneNumber"
          type="tel"
          disabled={disabled}
          required={required}
          value={val.phoneNumber}
          onChange={v => handleChange({ ...val, phoneNumber: v })}
          onError={errMsg => handleError("phoneNumber", errMsg)}
          hasError={submitted && !!error.phoneNumber}
        />
        {submitted && error.phoneNumber && (
          <span className="text-danger">{error.phoneNumber}</span>
        )}
      </div>
    </div>
  )
}

PhoneNumberField.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onChange: PropTypes.func,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  onError: PropTypes.func,
  submitted: PropTypes.bool,
}

PhoneNumberField.defaultProps = {
  required: false,
  disabled: false,
  submitted: false,
}

export default PhoneNumberField

import React, { useEffect, useMemo, useRef, useState } from "react"
import { useParams, useHistory } from "react-router-dom"

import BaseLayout from "components/BaseLayout"
import CustomButton from "components/Common/CustomButton"
// import FlagButton from "components/Common/FlagButton"
import { ApiForm } from "components/Common/Form"
import Modal from "components/Common/Modal"

import {
  TreeSubmissionImagesField,
  TreeSubmissionStemNoField,
  TreeSubmissionStemsField,
  TreeSubmissionLocationField,
  TreeSpeciesField,
  LISTING_STATUS_IN_REVIEW,
  LISTING_STATUS_HIDDEN,
  LISTING_STATUS_REJECTED,
  LISTING_STATUS_VERIFIED,
  TREE_SUBMISSION_STATUS_OPTIONS,
  DEFAULT_STEM_OBJECT,
  TYPE_ORDER,
} from "."

import {
  getTree,
  editTree,
  verifiedTree,
  hideTree,
  rejectTree,
  reviewTree,
  deleteImages,
  keepHiddenTree,
} from "helpers/backend_helper"
import { toast, confirm } from "helpers/cms_helper"
import { getUser } from "helpers/localstorage/auth"
import { ADMIN_ROLE_SUPER } from "pages/AdminManagement"
import { useDispatch } from "react-redux"
import { reset } from "redux-form"

import _ from "lodash"

const TreeSubmissionDetail = () => {
  const [detail, setDetail] = useState({})
  const [cloneData, setCloneData] = useState({})
  const [action, setAction] = useState(null)
  const [statusUpdateModalOpen, setStatusUpdateModalOpen] = useState(false)
  const { id } = useParams()
  const formRef = useRef(null)
  const dispatch = useDispatch()
  const me = getUser()
  const statusUpdateModalContent = useMemo(() => {
    let title = "Reject Listing"
    let className = "btn-danger"

    if (action === "hide") {
      title = "Hide Listing"
      className = "btn-warning"
    }

    if (action === "hideToRejected") {
      title = "Confirm Reject Listing"
    }

    return {
      title,
      className,
    }
  }, [action])
  const history = useHistory()
  const isSuperAdmin = me?.accountType === ADMIN_ROLE_SUPER
  const disabledEdit =
    [LISTING_STATUS_REJECTED, LISTING_STATUS_VERIFIED].indexOf(
      detail?.status
    ) !== -1 && !isSuperAdmin

  const fields = [
    // tree
    {
      label: "Tree ID#",
      name: "displayId",
      disabled: true,
    },
    {
      label: "Tree Listing Submission Date",
      name: "createdAt",
      type: "datetime",
      disabled: true,
    },
    {
      label: "Status",
      name: "status",
      type: "select",
      options: TREE_SUBMISSION_STATUS_OPTIONS,
      disabled: true,
    },
    {
      label: "Last Update Date",
      name: "updatedAt",
      type: "datetime",
      disabled: true,
    },
    {
      label: "Tree photos",
      name: "resources",
      type: "component",
      component: TreeSubmissionImagesField,
      required: true,
      style: { maxWidth: "540px" },
      disabled: disabledEdit,
    },
    {
      label: "",
      name: "treeLocation",
      type: "component",
      component: TreeSubmissionLocationField,
      disabled: disabledEdit,
    },
    {
      label: "Coordinate (Latitude)",
      name: "lat",
      disabled: disabledEdit,
    },
    {
      label: "Coordinate (Longitude)",
      name: "lng",
      disabled: disabledEdit,
    },
    {
      label: "",
      name: "treeSpeciesId",
      type: "component",
      component: TreeSpeciesField,
      disabled: disabledEdit,
    },
    // stems
    {
      label: "",
      name: "stemNo",
      type: "component",
      component: TreeSubmissionStemNoField,
      customButtonEvent: () => {
        setCloneData((preV) => {
          const newArr = [...preV?.stems]
          const stemNo = Number(preV?.stemNo) + 1
          newArr.push({ ...DEFAULT_STEM_OBJECT, stemId: id })
          return {
            ...preV,
            stems: newArr,
            stemNo,
          }
        })
      },
      disabled: disabledEdit,
    },
    {
      label: "",
      name: "stems",
      type: "component",
      component: TreeSubmissionStemsField,
      customButtonEvent: () => {
        confirm(
          `Confirm delete last stem?`,
          async () => {
            setCloneData((preV) => {
              const stemNo = Number(preV?.stemNo) - 1
              let newArr = [...preV?.stems]

              newArr = newArr.slice(0, -1)

              return {
                ...preV,
                stems: newArr,
                stemNo,
              }
            })
          },
          "btn-success"
        )
      },
      disabled: disabledEdit,
    },
    {
      label: "Crown Base Height (m)",
      name: "crownBaseHeight",
      type: "number",
      min: 0.1,
      maxDecimalPlaces: 1,
      disabled: disabledEdit,
    },
    {
      label: "Crown Width (m)",
      name: "crownWidth",
      type: "number",
      min: 0.1,
      maxDecimalPlaces: 1,
      disabled: disabledEdit,
    },
    {
      label: "Other Observations / Note",
      name: "remarks",
      disabled: disabledEdit,
    },
    {
      label: "Carbon Storage (kg to date)",
      name: "carbonStorage",
      type: "number",
      disabled: true,
    },
    {
      label: "CO2 Removal (kg of CO2 per year)",
      name: "CO2Removal",
      type: "number",
      disabled: true,
    },
    {
      label: "Air Pollution Removal (kg per year)",
      name: "airPollutionRemoval",
      type: "number",
      disabled: true,
    },
    {
      label: "O3 (kg) ",
      name: "O3",
      type: "number",
      disabled: true,
    },
    {
      label: "NO2 (kg)",
      name: "NO2",
      type: "number",
      disabled: true,
    },
    {
      label: "PM10 (kg)",
      name: "PM10",
      type: "number",
      disabled: true,
    },
    {
      label: "Hide Reason",
      name: "lastReason.lastHideReason.reason",
      type: "textarea",
      hidden: !detail?.lastReason?.lastHideReason,
      disabled: true,
    },
    {
      label: "Reject Reason",
      name: "lastReason.lastRejectReason.reason",
      type: "textarea",
      hide: !detail?.lastReason?.lastRejectReason,
      disabled: true,
    },
  ]

  const statusUpdateFields = [
    { label: "Reason", name: "reason", type: "textarea" },
  ]

  async function fetchTree(id) {
    const res = await getTree(id)
    setDetail(() => {
      const lastReason = res?.reasons?.reduce(
        (acc, obj) => {
          if (obj?.type === "REJECT") {
            acc.lastRejectReason = obj
          } else if (obj.type === "HIDE") {
            acc.lastHideReason = obj
          }
          return acc
        },
        { lastRejectReason: null, lastHideReason: null }
      )

      return {
        ...res,
        lastReason,
      }
    })
  }

  function parseValuesToApi(values) {
    const params = _.pick(values, [
      "lat",
      "lng",
      "treeSpeciesId",
      "stemNo",
      "stems",
      "crownBaseHeight",
      "crownWidth",
      "carbonStorage",
      "CO2Removal",
      "airPollutionRemoval",
      "O3",
      "NO2",
      "PM10",
      "remark",
    ])
    const resources = values?.resources || {}
    const images = []
    const ids = []

    params.district = values?.treeLocation?.district
    params.location = values?.treeLocation?.location

    for (const key in resources) {
      const image = resources[key]
      const dataObj = {
        type: "OTHER".includes(key) ? "OTHERS" : key,
        filename: image?.filename,
        url: image?.signedUrl || image?.url,
        ordering: TYPE_ORDER[key] || Number.MAX_SAFE_INTEGER,
      }
      if (image?.id) dataObj.id = image?.id
      else {
        const duplicateImage = detail?.images.find((item) => item?.type === key)
        ids.push(duplicateImage?.id)
      }

      if (dataObj?.url) images.push(dataObj)
    }

    if (ids.length > 0) deleteImages(id, { ids })

    return {
      ...params,
      images: _.sortBy(
        images,
        (image) => image.ordering || Number.MAX_SAFE_INTEGER
      ),
    }
  }

  async function handleSubmit(values) {
    const payload = parseValuesToApi(values)

    await editTree(id, payload)
    await fetchTree(id)

    toast("Updated")
  }

  async function handleStatusUpdate(type, values, statusFormData) {
    // console.log(type, values)
    let toastMsg = ""

    if (!values?.treeSpeciesId && type === "verify") {
      toastMsg = "Species name must be provided when the tree is being verified"
      toast(toastMsg, true)
      return
    }

    switch (type) {
      case "verify":
        await verifiedTree(id, values)
        toastMsg = "Verified"
        break
      case "hide":
        await hideTree(id, values)
        toastMsg = "Hidden"
        break
      case "reject":
        await rejectTree(id, values)
        toastMsg = "Rejected"
        break
      case "hiddenToVerify":
        await reviewTree(id, values)
        await verifiedTree(id, values)
        toastMsg = "Verified"
        break
      case "hideToRejected":
        await reviewTree(id, values)
        await rejectTree(id, statusFormData)
        toastMsg = "Rejected"
        break
      default:
        break
    }

    toast(toastMsg)
    toggleStatusUpdateModal(false)
    fetchTree(id)
  }

  const toggleStatusUpdateModal = (open = true) => {
    if (open === false) {
      dispatch(reset("listing-status-update"))
      setAction(null)
    }
    setStatusUpdateModalOpen(open)
  }

  const handleToggleStatusUpdateModal = (type) => {
    setAction(type)
    toggleStatusUpdateModal(true)
  }

  useEffect(() => {
    fetchTree(id)
  }, [id])

  useEffect(() => {
    const resources = {}
    const treeLocation = {
      district: detail?.district,
      location: detail?.location,
    }
    const lastReason = detail?.reasons?.[detail?.reasons?.length - 1]
    const reason = lastReason?.reason
    let othersNo = 1

    detail?.images?.map((image) => {
      if (image?.type === "OTHERS") {
        resources["OTHER" + othersNo] = {
          url: image?.url,
          filename: image?.filename,
          id: image?.id,
        }
        othersNo++
      } else {
        resources[image?.type] = {
          url: image?.url,
          filename: image?.filename,
          id: image?.id,
        }
      }
    })

    setCloneData({ ...detail, resources, treeLocation, reason })
  }, [detail])

  return (
    <BaseLayout title="Tree Submission">
      <div className="buttons-row mb-3">
        {detail?.status === LISTING_STATUS_VERIFIED && isSuperAdmin && (
          <>
            <CustomButton
              className="btn-warning"
              onClick={() => handleToggleStatusUpdateModal("hide")}
            >
              Verified to Hidden
            </CustomButton>
            <CustomButton
              className="btn-danger"
              onClick={() => handleToggleStatusUpdateModal("reject")}
            >
              Verified to Rejected
            </CustomButton>
          </>
        )}
        {detail?.status === LISTING_STATUS_IN_REVIEW && (
          <>
            {isSuperAdmin ? (
              <>
                <CustomButton
                  className="btn-success"
                  onClick={() => {
                    confirm(
                      `Confirm Verified listing ?`,
                      async () => {
                        const payload = parseValuesToApi(
                          formRef.current.props.formValues
                        )
                        await editTree(id, payload)
                        await handleStatusUpdate("verify", payload)
                      },
                      "btn-success"
                    )
                  }}
                >
                  Verify
                </CustomButton>
                <CustomButton
                  className="btn-warning"
                  onClick={() => handleToggleStatusUpdateModal("hide")}
                >
                  Hide
                </CustomButton>
                <CustomButton
                  className="btn-danger"
                  onClick={() => handleToggleStatusUpdateModal("reject")}
                >
                  Reject
                </CustomButton>
              </>
            ) : (
              <>
                <CustomButton
                  className="btn-success"
                  onClick={() => {
                    confirm(
                      `Confirm Verified listing ?`,
                      async () => {
                        const payload = parseValuesToApi(
                          formRef.current.props.formValues
                        )
                        await editTree(id, payload)
                        await handleStatusUpdate("verify", payload)
                      },
                      "btn-success"
                    )
                  }}
                >
                  Verify
                </CustomButton>
                <CustomButton
                  className="btn-warning"
                  onClick={() => handleToggleStatusUpdateModal("hide")}
                >
                  Hide
                </CustomButton>
                <CustomButton
                  className="btn-danger"
                  onClick={() => handleToggleStatusUpdateModal("reject")}
                >
                  Reject
                </CustomButton>
              </>
            )}
          </>
        )}
        {detail?.status === LISTING_STATUS_HIDDEN && (
          <>
            <CustomButton
              disabled={detail?.keepHiddenDate}
              className="btn-warning"
              onClick={async () => {
                const today = new Date().toJSON().slice(0, 10)
                await keepHiddenTree(id, {
                  keepHiddenDate: today,
                })
                await fetchTree(id)
                toast("Keep hidden!")
              }}
            >
              Keep it as hidden
            </CustomButton>
            <CustomButton
              className="btn-success"
              onClick={() => {
                confirm(
                  `Confirm Verified listing ?`,
                  async () => {
                    const payload = parseValuesToApi(
                      formRef.current.props.formValues
                    )
                    await editTree(id, payload)
                    await handleStatusUpdate("hiddenToVerify", payload)
                  },
                  "btn-success"
                )
              }}
            >
              Hidden to Verified
            </CustomButton>
            <CustomButton
              className="btn-danger"
              onClick={() => {
                handleToggleStatusUpdateModal("hideToRejected")
              }}
            >
              Hidden to Rejected
            </CustomButton>
          </>
        )}
      </div>
      <ApiForm
        ref={formRef}
        name="tree-submission"
        fields={fields}
        data={cloneData}
        onSubmit={handleSubmit}
        style={{ maxWidth: !disabledEdit ? "585px" : "540px" }}
        titlesStyle={{ textAlign: "center" }}
        isDisabled={
          [LISTING_STATUS_REJECTED, LISTING_STATUS_VERIFIED].indexOf(
            detail?.status
          ) !== -1 && !isSuperAdmin
        }
        extraSubmitButtonsLeft={
          <>
            <CustomButton
              className="btn-outline-dark"
              onClick={() => {
                localStorage.setItem(
                  "locationFrom",
                  history?.location?.state?.from
                )
                history.goBack()
              }}
            >
              Back
            </CustomButton>
          </>
        }
      />
      <Modal
        title={statusUpdateModalContent.title}
        isOpen={statusUpdateModalOpen}
        onToggle={toggleStatusUpdateModal}
      >
        <ApiForm
          name="listing-status-update"
          mode="create"
          fields={statusUpdateFields}
          onSubmit={async (values) => {
            const payload = parseValuesToApi(formRef.current.props.formValues)
            await editTree(id, payload)

            if (action === "hideToRejected")
              await handleStatusUpdate("hideToRejected", payload, values)
            else await handleStatusUpdate(action, values)
          }}
          submitButtonText="Confirm"
          submitButtonClassName={statusUpdateModalContent.className}
        />
      </Modal>
    </BaseLayout>
  )
}

export default TreeSubmissionDetail

import React from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import { reset } from "redux-form"
import { Row, Col } from "reactstrap"

import DataTable from "./DataTable"
import TableFilter from "./TableFilter"
import TablePagination from "./TablePagination"

import { isEmpty } from "helpers/cms_helper"
import {
  getApiTableSettings,
  setApiTableSettings,
} from "helpers/localstorage/api_table"

import _ from "lodash"

const defaultHits = 50

class ApiTable extends React.Component {
  constructor(props) {
    super(props)
    const { name, filters, fetchOnMount } = props
    const defaultFilterParams = {}
    const storedHits = getApiTableSettings(name).hits

    if (filters && filters.length > 0) {
      const filterFields = []
      filters.map((row) => filterFields.push(...row.fields))
      filterFields.map((field) => {
        if (field.defaultValue)
          if (field.multiple)
            defaultFilterParams[field.name] = field.defaultValue.split(",")
          else defaultFilterParams[field.name] = field.defaultValue
      })
    }

    this.state = {
      datas: [],
      loading: fetchOnMount ? true : false,
      total: 0,
      offset: 0,
      hits: props.hits || (storedHits ? storedHits : defaultHits),
      currentPage: 1,
      filterFormName: name + "-filter",
      filterParams: defaultFilterParams,
      defaultFilterParams: defaultFilterParams,
      selectedAll: false,
    }
  }

  async componentDidMount() {
    const { fetchOnMount } = this.props
    if (fetchOnMount) this.query()
  }

  componentDidUpdate(prevProps, prevState) {
    const { selectable, selected } = this.props
    const { datas } = this.state

    if (selectable) {
      if (selected !== prevProps.selected || datas !== prevState.datas) {
        let selectedAllTmp = datas && datas.length > 0 ? true : false
        for (const data of datas) {
          if (_.findIndex(selected, { id: data.id }) === -1) {
            selectedAllTmp = false
            break
          }
        }
        this.setState({ selectedAll: selectedAllTmp })
      }
    }
  }

  query = () => {
    const { fetch, resultsField, fetchCallback, setCustomFetchOnMount } =
      this.props
    const { offset, hits, filterParams } = this.state

    const parsedFilterParams = _.cloneDeep(filterParams)
    for (const key in parsedFilterParams) {
      if (_.isArray(parsedFilterParams[key]))
        parsedFilterParams[key] = parsedFilterParams[key].join()
    }

    this.setState({
      loading: true,
    })

    setCustomFetchOnMount(true)

    const params = {
      offset: offset,
      hits: hits,
      ...parsedFilterParams,
    }
    fetch(params).then((res) => {
      this.setState({
        datas: resultsField ? res[resultsField] : res.results,
        loading: false,
      })

      setCustomFetchOnMount(false)

      if (!isEmpty(res.numResults))
        this.setState({
          total: res.numResults,
        })
      if (!isEmpty(fetchCallback)) fetchCallback(res, params)
    })
  }

  refetch = () => {
    this.query()
  }

  setFilterParams = (params) => {
    this.setState((prevState) => ({
      filterParams: { ...prevState.filterParams, ...params },
    }))
  }

  handleFilterSearch = (values = {}) => {
    const parsedValues = _.cloneDeep(values)

    for (const key in parsedValues) {
      if (isEmpty(parsedValues[key])) delete parsedValues[key]
    }

    this.setState(
      () => ({
        offset: 0,
        currentPage: 1,
        filterParams: { ...parsedValues },
      }),
      this.query
    )
  }

  handlePageChange = (pageNum) => {
    this.setState(
      (prevState) => ({
        offset: (pageNum - 1) * prevState.hits,
        currentPage: pageNum,
      }),
      this.query
    )
  }

  handleHitsChange = (val) => {
    const { name } = this.props
    setApiTableSettings(name, { hits: val })
    this.setState(
      () => ({
        hits: val,
        offset: 0,
        currentPage: 1,
      }),
      this.query
    )
  }

  handleResetFilters = () => {
    const { dispatch } = this.props
    const { filterFormName } = this.state

    dispatch(reset(filterFormName))
    this.setState((prevState) => ({
      filterParams: prevState.defaultFilterParams,
    }))
    this.handleFilterSearch(this.state.defaultFilterParams)
  }

  handleSelect = (state, value) => {
    // value can be a row or "all"
    const { onSelect, initSelected } = this.props
    const { datas } = this.state

    if (value === "all")
      onSelect(
        state,
        _.filter(datas, (data) => {
          if (_.findIndex(initSelected, { id: data.id }) === -1) return data
        })
      )
    else onSelect(state, value)
  }

  render() {
    const {
      filters,
      columns,
      headerButtons,
      rowButtons,
      pagination,
      selectable,
      selected,
      initSelected,
    } = this.props
    const {
      datas,
      loading,
      total,
      offset,
      hits,
      currentPage,
      filterFormName,
      filterParams,
      selectedAll,
    } = this.state

    let tableHeader = null
    const hasFilters = filters && filters.length > 0
    const hasHeaderButtons = !!headerButtons
    if (hasFilters || hasHeaderButtons) {
      tableHeader = (
        <Row className="mb-3">
          {hasFilters && (
            <Col className="table-filter-col">
              <TableFilter
                name={filterFormName}
                filters={filters || []}
                onSubmit={this.handleFilterSearch}
                filterParams={filterParams}
                reset={this.handleResetFilters}
                loading={loading}
              />
            </Col>
          )}
          {hasHeaderButtons && (
            <Col style={{ maxWidth: hasFilters ? "fit-content" : "100%" }}>
              <div className="float-right">{headerButtons}</div>
            </Col>
          )}
        </Row>
      )
    }

    return (
      <div>
        {tableHeader}
        <DataTable
          rows={datas}
          columns={columns || []}
          loading={loading}
          rowButtons={rowButtons}
          selectable={selectable}
          onSelect={this.handleSelect}
          selected={selected}
          selectedAll={selectedAll}
          initSelected={initSelected}
        />
        {pagination && (
          <TablePagination
            total={total}
            offset={offset}
            hits={hits}
            currentPage={currentPage}
            onPageChange={this.handlePageChange}
            onHitsChange={this.handleHitsChange}
          />
        )}
      </div>
    )
  }
}

ApiTable.propTypes = {
  name: PropTypes.string.isRequired,
  columns: PropTypes.array.isRequired,
  fetch: PropTypes.func.isRequired,
  filters: PropTypes.array,
  headerButtons: PropTypes.oneOfType([PropTypes.object, PropTypes.elementType]),
  rowButtons: PropTypes.oneOfType([PropTypes.object, PropTypes.elementType]),
  resultsField: PropTypes.string,
  pagination: PropTypes.bool,
  fetchOnMount: PropTypes.bool,
  selectable: PropTypes.bool,
  onSelect: PropTypes.func,
  initSelected: PropTypes.array,
  fetchCallback: PropTypes.func,
  hits: PropTypes.number,
  selected: PropTypes.bool,
  dispatch: PropTypes.func,
  setCustomFetchOnMount: PropTypes.func,
}

ApiTable.defaultProps = {
  pagination: true,
  fetchOnMount: true,
  selectable: false,
  setCustomFetchOnMount: () => null,
}

export default connect(null, null, null, { forwardRef: true })(ApiTable)

import React, { forwardRef, useEffect, useMemo, useState } from "react"
import PropTypes from "prop-types"

import _ from "lodash"

import Tabs from "components/Common/Tabs"
import { ApiForm } from "."

import { MULTILANG_TABS } from "helpers/cms_helper"

const MultiLangForm = forwardRef((props, ref) => {
  const { name, mode, fields, data, onSubmit, extraSubmitButtons, style } =
    props
  const [currentTab, setCurrentTab] = useState("EN")
  const [enFields, setEnFields] = useState([])
  const [tcFields, setTcFields] = useState([])
  const [scFields, setScFields] = useState([])
  const [errors, setErrors] = useState({}) // {fieldName1: "errorMsg1", ...}

  useEffect(() => {
    if (!fields) return
    MULTILANG_TABS.map((tab) => {
      const tabFields = _.map(_.cloneDeep(fields), function (field) {
        field.name = `${field?.name}.${tab.value}`
        return field
      })
      if (tab.value === "EN") setEnFields(tabFields)
      else if (tab.value === "TC") setTcFields(tabFields)
      else if (tab.value === "SC") setScFields(tabFields)
    })
  }, [fields])

  const tabErrors = useMemo(() => {
    // e.g. {EN: true, TC: false, SC: true}
    const errorsClone = _.cloneDeep(errors)
    for (const key in errorsClone) {
      if (!errorsClone[key.slice(-2)])
        errorsClone[key.slice(-2)] = !!errorsClone[key]
      delete errorsClone[key]
    }
    return errorsClone
  }, [errors])

  const multiLangFields = useMemo(() => {
    const enFieldsTmp = [...enFields].map((field) => {
      const f = { ...field }
      if (currentTab !== "EN") f.multilangHidden = true
      return f
    })
    const tcFieldsTmp = [...tcFields].map((field) => {
      const f = { ...field }
      if (currentTab !== "TC") f.multilangHidden = true
      return f
    })
    const scFieldsTmp = [...scFields].map((field) => {
      const f = { ...field }
      if (currentTab !== "SC") f.multilangHidden = true
      return f
    })
    return [...enFieldsTmp, ...tcFieldsTmp, ...scFieldsTmp]
  }, [currentTab, enFields, tcFields, scFields])

  const handleChangeTab = (tab) => {
    setCurrentTab(tab)
  }

  return (
    <div style={style}>
      <Tabs
        type="form"
        tabs={MULTILANG_TABS}
        currentTab={currentTab}
        onChangeTab={handleChangeTab}
        errors={tabErrors}
      />
      <ApiForm
        ref={ref}
        name={name}
        mode={mode}
        fields={multiLangFields}
        data={data}
        onSubmit={onSubmit}
        onError={(errors, submitted) => setErrors(submitted ? errors : {})}
        extraSubmitButtons={extraSubmitButtons}
      />
    </div>
  )
})

MultiLangForm.propTypes = {
  name: PropTypes.string.isRequired,
  mode: PropTypes.string,
  fields: PropTypes.array.isRequired,
  onSubmit: PropTypes.func.isRequired,
  extraSubmitButtons: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.elementType,
  ]),
  data: PropTypes.object,
  style: PropTypes.object,
}

MultiLangForm.defaultProps = {
  mode: "create",
}

MultiLangForm.displayName = "MultiLangForm"

export default MultiLangForm

import React, { useCallback, useEffect, useState } from "react"
import PropTypes from "prop-types"

import { isEmpty } from "helpers/cms_helper"
import { ImagePreview, ResourceUploader } from "./ResourceComponents"
import { Field } from "redux-form"

const ImagesInput = props => {
  const [images, setImages] = useState([])
  const {
    value,
    onChange,
    required,
    disabled,
    min,
    max,
    onError,
    canFlag,
    onFlag,
    flagged, // array of indexes
    mode,
    isPrivate,
    userId,
  } = props

  useEffect(() => {
    if (!isEmpty(onError)) validate(value)
    setImages(
      (value || []).map(v => {
        return { url: v }
      })
    )
  }, [value])

  const validate = val => {
    if (disabled) return onError(null)

    if (required && (isEmpty(val) || val.length === 0))
      return onError("Required")

    if (min && (val || []).length < min)
      return onError(`At least ${min} images are required`)
    return onError(null)
  }

  const handleUpload = response => {
    if (response?.url) {
      const imagesClone = _.cloneDeep(images)
      const newImages = [...imagesClone.map(i => i.url), response.url]
      onChange(newImages)
    }
  }

  const handleClear = url => {
    const imagesClone = _.cloneDeep(images)
    const newImages = imagesClone.filter(i => i.url !== url).map(i => i.url)
    onChange(newImages)
  }

  return (
    <div>
      <div className="row">
        {(images || []).map((image, idx) => (
          <div
            key={idx}
            className="col mr-3 mb-3"
            style={{ maxWidth: "170px" }}
          >
            <ImagePreview
              image={image}
              disabled={disabled || mode === "view"}
              onClear={handleClear}
              size="sm"
              canFlag={canFlag}
              onFlag={state => onFlag(state, idx)}
              flagged={(flagged || []).indexOf(idx) !== -1}
            />
          </div>
        ))}
      </div>
      {(isEmpty(max) || (images || []).length < max) && mode !== "view" && (
        <ResourceUploader
          type="image"
          disabled={disabled}
          onUpload={handleUpload}
          isPrivate={isPrivate}
          userId={userId}
        />
      )}
    </div>
  )
}

const ImagesField = props => {
  // for type: images (multi image upload)
  // min: min number of images ; max: max number of images
  const { fieldType, value, onChange, onError, hasError } = props

  const fieldProps = _.pick(props, [
    "fieldType",
    "name",
    "className",
    "style",
    "required",
    "disabled",
    "min",
    "max",
    "canFlag",
    "onFlag",
    "flagged",
    "mode",
    "isPrivate",
    "userId",
  ])
  fieldProps.className =
    (fieldProps.className || "") + (hasError ? " border border-danger" : "")

  const renderImagesInput = useCallback(
    field => (
      <ImagesInput
        {...fieldProps}
        value={field.input.value}
        onChange={field.input.onChange}
        onError={onError}
      />
    ),
    [props.disabled, props.userId] // add dynamic props here
  )

  if (fieldType === "form")
    return (
      <div
        className={"image-field field-wrapper" + (hasError ? " has-error" : "")}
      >
        <Field component={renderImagesInput} {...fieldProps} />
      </div>
    )
  else if (fieldType === "filter")
    return <Field component={renderImagesInput} {...fieldProps} />
  else
    return (
      <ImagesInput
        {...fieldProps}
        value={value}
        onChange={onChange}
        onError={onError}
      />
    )
}

ImagesField.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.object,
  fieldType: PropTypes.string,
  mode: PropTypes.string,
  min: PropTypes.number,
  max: PropTypes.number,
  onChange: PropTypes.func,
  value: PropTypes.array,
  onError: PropTypes.func,
  canFlag: PropTypes.bool,
  onFlag: PropTypes.func,
  flagged: PropTypes.array,
  isPrivate: PropTypes.bool,
  userId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  hasError: PropTypes.bool,
}

ImagesField.defaultProps = {
  fieldType: "form",
  required: false,
  disabled: false,
  canFlag: false,
  flagged: [],
  isPrivate: false,
  hasError: false,
}

export default ImagesField

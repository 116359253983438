import React from "react"

import BaseLayout from "components/BaseLayout"

const Page403 = props => {
  return (
    <BaseLayout title="Forbidden">
      <h5>You don't have permission to view this page.</h5>
    </BaseLayout>
  )
}

export default Page403

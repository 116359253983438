import React, { useRef, useState } from "react"

import BaseLayout from "components/BaseLayout"
import CustomButton from "components/Common/CustomButton"
import Modal from "components/Common/Modal"
import { ApiTable } from "components/Common/Table"
import { ApiForm } from "components/Common/Form"

import { getUsers, createUser, editUser } from "helpers/backend_helper"
import { toast } from "helpers/cms_helper"

import _ from "lodash"

const UserList = () => {
  const [createModalOpen, setCreateModalOpen] = useState(false)
  const [editModalOpen, setEditModalOpen] = useState(false)
  const [editingRow, setEditingRow] = useState(null)
  const tableRef = useRef(null)

  // const editing = !!editingRow
  const lang = {
    EN: "English",
    TC: "繁體中文",
  }
  const columns = [
    { title: "User ID", value: "id" },
    { title: "User Name", value: "username" },
    { title: "Email", value: "email" },
    {
      title: "Species Identification Accuracy Rate",
      value: (row) => `${row?.accuracy}%`,
    },
    {
      title: "Preferred Language",
      value: (row) => lang[row?.preferredLang] || "N/A",
    },
    { title: "Register Date", value: "createdAt", format: "date" },
    { title: "User Account Status", value: "status" },
  ]

  const filters = [
    {
      rowId: 0,
      rowName: "Filter",
      fields: [
        {
          placeholder: "Email or username",
          name: "keyword",
          type: "text",
          icon: "search",
        },
        {
          placeholder: "User Status",
          name: "status",
          type: "select",
          options: [
            {
              label: "Active",
              value: "ACTIVE",
            },
            {
              label: "Inactive",
              value: "INACTIVE",
            },
          ],
          multiple: true,
        },
      ],
    },
    {
      rowId: 1,
      rowName: " ",
      fields: [
        {
          name: "registerDateFrom",
          type: "datetime",
          placeholder: "Register Date From",
        },
        {
          name: "registerDateTo",
          type: "datetime",
          placeholder: "Register Date To",
        },
      ],
    },
  ]

  const createFields = [
    {
      label: "User Name",
      name: "username",
      type: "text",
      required: true,
    },
    {
      label: "Email",
      name: "email",
      type: "email",
      required: true,
    },
    {
      label: "Preferred Language",
      name: "preferredLang",
      type: "select",
      options: [
        {
          label: "English",
          value: "EN",
        },
        {
          label: "繁體中文",
          value: "TC",
        },
      ],
    },
    {
      label: "User Account Status",
      name: "status",
      type: "select",
      options: [
        {
          label: "Active",
          value: "ACTIVE",
        },
        {
          label: "Inactive",
          value: "INACTIVE",
        },
      ],
    },
  ]

  const editFields = [
    {
      label: "User Name",
      name: "username",
      type: "text",
      required: true,
      disabled: editingRow?.deleted,
    },
    {
      label: "Email",
      name: "email",
      type: "email",
      required: true,
    },
    {
      label: "Preferred Language",
      name: "preferredLang",
      type: "select",
      options: [
        {
          label: "English",
          value: "EN",
        },
        {
          label: "繁體中文",
          value: "TC",
        },
      ],
      disabled: editingRow?.deleted,
    },
    {
      label: "User Account Status",
      name: "status",
      type: "select",
      options: [
        {
          label: "Active",
          value: "ACTIVE",
        },
        {
          label: "Inactive",
          value: "INACTIVE",
        },
      ],
      disabled: editingRow?.deleted,
    },
  ]

  const headerButtons = (
    <CustomButton
      className="btn-dark"
      mobileContent="+ Add"
      onClick={() => toggleCreateModal(true)}
    >
      + User
    </CustomButton>
  )

  const toggleCreateModal = (open = true) => {
    setCreateModalOpen(open)
  }

  const toggleEditModal = (open = true) => {
    if (open === false) setEditingRow(null)
    setEditModalOpen(open)
  }

  async function fetch(params) {
    return getUsers(params)
  }

  async function handleCreateSubmit(values) {
    const params = _.pick(values, [
      "username",
      "email",
      "status",
      "preferredLang",
    ])
    await createUser(params)
    toggleCreateModal(false)
    toast("Created")
    tableRef.current.refetch()
  }

  async function handleEditSubmit(values) {
    const params = _.pick(values, [
      "username",
      "status",
      "preferredLang",
      "email",
    ])
    await editUser(editingRow.id, params)
    toast("Updated")
    toggleEditModal(false)
    tableRef.current.refetch()
  }

  return (
    <BaseLayout title="User List" headerButtons={headerButtons}>
      <ApiTable
        ref={tableRef}
        name="users"
        fetch={fetch}
        columns={columns}
        filters={filters}
        rowButtons={(row) => (
          <>
            <CustomButton
              className="btn-outline-dark"
              onClick={() => {
                setEditingRow({
                  ...row,
                })
                toggleEditModal(true)
              }}
            >
              Edit
            </CustomButton>
          </>
        )}
      />

      <Modal
        title="Create User"
        isOpen={createModalOpen}
        onToggle={toggleCreateModal}
      >
        <ApiForm
          name="user-create"
          fields={createFields}
          onSubmit={handleCreateSubmit}
        />
      </Modal>

      <Modal
        title="Edit User"
        isOpen={editModalOpen}
        onToggle={toggleEditModal}
      >
        <ApiForm
          name="User-edit"
          mode="edit"
          fields={editFields}
          data={editingRow}
          onSubmit={handleEditSubmit}
        />
      </Modal>
    </BaseLayout>
  )
}

export default UserList

import React from "react"
import PropTypes from "prop-types"
import { Field } from "redux-form"
import Switch from "react-switch"

const ToggleInput = props => {
  const { fieldType, input, disabled } = props

  return (
    <div>
      <Switch
        onChange={checked => input.onChange(checked)}
        checked={!!input.value}
        onColor="#343a40"
        offColor="#adb5bd"
        onHandleColor="#ffffff"
        uncheckedIcon={false}
        checkedIcon={false}
        handleDiameter={fieldType === "form" ? 23 : 12}
        height={fieldType === "form" ? 28 : 16}
        width={fieldType === "form" ? 49 : 28}
        disabled={disabled}
      />
    </div>
  )
}

const ToggleField = props => {
  // for type: toggle
  const { fieldType, value, onChange } = props

  const fieldProps = _.pick(props, [
    "fieldType",
    "name",
    "className",
    "style",
    "disabled",
  ])

  if (fieldType === "form" || fieldType === "filter")
    return <Field component={ToggleInput} {...fieldProps} />
  else
    return (
      <ToggleInput
        {...fieldProps}
        input={{ value: value, onChange: onChange }}
      />
    )
}

ToggleField.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  style: PropTypes.object,
  fieldType: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
}

ToggleField.defaultProps = {
  fieldType: "form",
  required: false,
  disabled: false,
}

export default ToggleField

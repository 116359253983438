import React, { useMemo } from "react"
import PropTypes from "prop-types"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faImage, faFile } from "@fortawesome/free-solid-svg-icons"

import CustomButton from "components/Common/CustomButton"

import { uploadImage, uploadFile, uploadPrivate } from "helpers/resource_helper"
import { toast } from "helpers/cms_helper"

const ResourceUploader = (props) => {
  const {
    name,
    type,
    disabled,
    onUpload,
    className,
    size,
    isPrivate,
    userId,
    accept = "*",
    fileName = "",
  } = props
  const hiddenFileInput = React.useRef(null)
  const customAccept = useMemo(() => {
    if (type === "image" && accept !== "*") return accept
    if (type === "image") return "image/*"
    return accept
  }, [accept, type])

  const upload = (file) => {
    if (isPrivate) {
      uploadPrivate(file, userId).then((response) => {
        onUpload(response)
      })
    } else {
      if (type === "image")
        uploadImage(file).then((response) => {
          onUpload(response)
        })
      else
        uploadFile(file).then((response) => {
          onUpload(response)
        })
    }
  }

  const generateFileName = (name, fileName) => {
    if (!name || !fileName) return ""

    let lang = "EN"
    let customFileName = ""

    switch (name) {
      case "contentUriTC":
        lang = "TC"
        break
      case "contentUriSC":
        lang = "SC"
        break
      default:
        lang = "EN"
    }

    switch (fileName) {
      case "Privacy Policy":
        customFileName = "Travous_PrivacyPolicy"
        break
      case "Terms & Conditions":
        customFileName = "Travous_Terms&Conditions"
        break
      default:
        customFileName = ""
    }
    return customFileName ? `${lang}/${customFileName}` : ""
  }

  const handleClick = () => {
    hiddenFileInput.current.click()
  }

  const handleChange = (e) => {
    upload(e.target.files[0], generateFileName(name, fileName))
  }

  const handleDragEnter = (e) => {
    e.preventDefault()
  }

  const handleDragOver = (e) => {
    e.preventDefault()
  }

  const handleDragLeave = (e) => {
    e.preventDefault()
  }

  const handleDrop = (e) => {
    e.preventDefault()
    if (!disabled && e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      const file = e.dataTransfer.files[0]
      if (type === "image" && file.type.indexOf("image") === -1)
        return toast("Invalid image file", true)
      upload(file, generateFileName(name, fileName))
      e.dataTransfer.clearData()
    }
  }

  return (
    <div
      className={
        "resource-field-uploader no-select " + (className ? className : "")
      }
      style={{ width: "fit-content" }}
    >
      <div
        className={
          "text-center text-uppercase " +
          (size === "sm"
            ? "resource-field-drag-area-sm"
            : "resource-field-drag-area")
        }
        onDragEnter={handleDragEnter}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
        style={{ backgroundColor: disabled ? "#f7f7f7" : null }}
      >
        <p>
          <FontAwesomeIcon icon={type === "image" ? faImage : faFile} />
          <br />
          Drop {type === "image" ? "Photo" : "File"} Here
        </p>
      </div>
      <div>
        <CustomButton
          className={
            "btn-outline-secondary " +
            (size === "sm"
              ? "resource-field-upload-button-sm"
              : "resource-field-upload-button")
          }
          onClick={handleClick}
          disabled={disabled}
        >
          Select {type === "image" ? "Photo" : "File"}
        </CustomButton>
        <input
          ref={hiddenFileInput}
          type="file"
          accept={customAccept}
          onChange={handleChange}
          style={{ display: "none" }}
        />
      </div>
    </div>
  )
}

ResourceUploader.propTypes = {
  name: PropTypes.string,
  type: PropTypes.string.isRequired, // image | file
  disabled: PropTypes.bool,
  onUpload: PropTypes.func,
  size: PropTypes.string,
  isPrivate: PropTypes.bool, // isPrivate & userId must be provided together
  userId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  className: PropTypes.string,
  accept: PropTypes.string,
  fileName: PropTypes.string,
}

ResourceUploader.defaultProps = {
  disabled: false,
  isPrivate: false,
}

export default ResourceUploader

import React, { useMemo } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { submit } from "redux-form"
import { Row, Col, FormGroup, Label } from "reactstrap"

import { ApiForm, FormField } from "components/Common/Form"
import CustomButton from "components/Common/CustomButton"

const selectFilterStyles = {
  backgroundColor: "#f7f7f7",
  border: "none",
  "&:hover": {
    border: "none",
  },
}

const TableFilter = props => {
  const { dispatch, name, filters, onSubmit, filterParams, reset, loading } =
    props

  if (!filters || filters.length === 0) return null

  const filtersSorted = useMemo(
    () =>
      _.sortBy(_.cloneDeep(filters), filter => {
        filter.rowId
      }),
    [filters]
  )

  const filterButtons = (
    <Col style={{ minWidth: "186px", maxWidth: "fit-content" }}>
      <CustomButton
        type="submit"
        className="btn-dark mr-2 mb-2"
        onClick={e => {
          e.preventDefault()
          dispatch(submit(name))
        }}
        loading={loading}
        style={{ minWidth: "84px" }}
      >
        Search
      </CustomButton>
      <CustomButton className="btn-outline-dark mb-2" onClick={reset}>
        Reset
      </CustomButton>
    </Col>
  )

  const filterRows = filtersSorted.map((filter, index) => (
    <FormGroup key={filter.rowId} row className="mb-2">
      {filter.rowName && (
        <Label
          sm={1}
          className="text-uppercase font-weight-bold"
          style={{ paddingLeft: "14px", minWidth: "80px" }}
        >
          {filter.rowName}
        </Label>
      )}
      <Col>
        <Row>
          {filter.fields.map(
            field =>
              !field.hidden && (
                <Col
                  xs={field.colSpan ? 12 : null}
                  sm={field.colSpan ? 12 : null}
                  md={field.colSpanMd || field.colSpan}
                  lg={field.colSpanMd || field.colSpan}
                  xl={field.colSpan}
                  key={field.name}
                  className="table-filter-field-col mb-2"
                >
                  <FormField
                    fieldType="filter"
                    className="table-filter-field"
                    style={selectFilterStyles}
                    {...field}
                  />
                </Col>
              )
          )}
          {filters.length === 1 && filterButtons}
        </Row>
      </Col>
    </FormGroup>
  ))

  return (
    <Row>
      <Col>
        <ApiForm
          name={name}
          type="filter"
          fields={filters}
          onSubmit={onSubmit}
          data={filterParams}
        >
          {filterRows}
        </ApiForm>
      </Col>
      {filters.length > 1 && filterButtons}
    </Row>
  )
}

TableFilter.propTypes = {
  name: PropTypes.string.isRequired,
  filters: PropTypes.array.isRequired,
  onSubmit: PropTypes.func.isRequired,
  filterParams: PropTypes.object,
  reset: PropTypes.func,
}

export default connect()(TableFilter)

import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"

import { FieldLabel, TextField, SelectField } from "components/Common/Form"

import { isEmpty } from "helpers/cms_helper"

import { DISTRICT_OPTIONS } from "."

import _ from "lodash"

const TreeSubmissionLocationField = (props) => {
  const { value, onChange, required, disabled, onError, submitted } = props

  const [val, setVal] = useState([])
  const [error, setError] = useState([])

  const handleChange = (val) => {
    onChange(val)
  }

  const handleError = (fieldName, errorMsg) => {
    setError((prevState) => ({ ...prevState, [fieldName]: errorMsg }))
  }

  useEffect(() => {
    if (isEmpty(value)) {
      // reset
      setVal({
        district: "",
        location: "",
      })
    } else {
      const toSet = _.cloneDeep(value)
      for (const key in toSet) {
        if (isEmpty(toSet[key])) toSet[key] = ""
      }
      setVal(toSet)
    }
  }, [value])

  useEffect(() => {
    for (const key in error) {
      if (!isEmpty(error[key])) return onError(true)
    }
    return onError(false)
  }, [error])

  return (
    <div className="row">
      <div className="col-5">
        <FieldLabel
          name="district"
          label="District"
          required={required}
          hasError={submitted && !!error.district}
        />
        <SelectField
          fieldType="customValue"
          name="district"
          options={DISTRICT_OPTIONS}
          required={required}
          disabled={disabled}
          value={val.district}
          clearable={false}
          onChange={(v) => handleChange({ ...val, district: v })}
          onError={(errorMsg) => handleError("district", errorMsg)}
          hasError={submitted && !!error.district}
        />
        {submitted && error.district && (
          <span className="text-danger">{error.district}</span>
        )}
      </div>

      <div className="col-7">
        <FieldLabel
          name="location"
          label="Tree Location"
          required={required}
          hasError={submitted && !!error.location}
        />
        <TextField
          fieldType="customValue"
          name="location"
          disabled={disabled}
          required={required}
          value={val.location}
          onChange={(v) => handleChange({ ...val, location: v })}
          onError={(errorMsg) => handleError("location", errorMsg)}
          hasError={submitted && !!error.location}
        />
        {submitted && error.location && (
          <span className="text-danger">{error.location}</span>
        )}
      </div>
    </div>
  )
}

export default TreeSubmissionLocationField

TreeSubmissionLocationField.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.array,
  ]),
  onChange: PropTypes.func,
  onError: PropTypes.func,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  canFlag: PropTypes.bool,
  onFlag: PropTypes.func,
  submitted: PropTypes.bool,
  customButtonEvent: PropTypes.func,
}

TreeSubmissionLocationField.defaultProps = {
  required: false,
  disabled: false,
  canFlag: false,
  submitted: false,
}

import React from "react"
import PropTypes from "prop-types"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTriangleExclamation } from "@fortawesome/free-solid-svg-icons"

const FlagButton = props => {
  const { value, onClick, disabled, style } = props

  const handleClick = () => {
    if (disabled) return
    onClick(!value)
  }

  return (
    <>
      <span className="vertical-align-image-helper"></span>
      <FontAwesomeIcon
        icon={faTriangleExclamation}
        className={"flag-button " + (value ? "text-danger" : "text-secondary")}
        onClick={handleClick}
        style={{ cursor: disabled ? "not-allowed" : "pointer", ...style }}
      />
    </>
  )
}

FlagButton.propTypes = {
  value: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  style: PropTypes.object,
}

export default FlagButton

import PropTypes from "prop-types"
import React, { useEffect } from "react"

import { Switch } from "react-router-dom"
import { connect } from "react-redux"
import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import "react-datepicker/dist/react-datepicker.css"

// Import Routes all
import { userRoutes, authRoutes } from "./routes/allRoutes"

// Import all middleware
import Authmiddleware from "./routes/middleware/Authmiddleware"

// layouts Format
import VerticalLayout from "./components/VerticalLayout/"
import NonAuthLayout from "./components/NonAuthLayout"

// get init api
import { getTreeList } from "store/actions"

// Import scss
import "./assets/scss/theme.scss"
import "./assets/scss/styles.scss"

const HttpsRedirect = () => {
  if (
    process.env.NODE_ENV !== "development" &&
    typeof window !== "undefined" &&
    window.location &&
    window.location.protocol === "http:"
  ) {
    window.location.href = window.location.href.replace(/^http(?!s)/, "https")
    return null
  }
  return <div></div>
}

const App = (props) => {
  function getLayout() {
    let layoutCls = VerticalLayout
    return layoutCls
  }

  useEffect(() => {
    props.getTreeList()
  }, [])

  const Layout = getLayout()

  return (
    <div className="font-size-14">
      <HttpsRedirect />
      <Switch>
        {authRoutes.map((route, idx) => (
          <Authmiddleware
            path={route.path}
            layout={NonAuthLayout}
            component={route.component}
            key={idx}
            isAuthProtected={false}
            title={route.title}
          />
        ))}

        {userRoutes.map((route, idx) => {
          return (
            <Authmiddleware
              path={route.path}
              layout={Layout}
              component={route.component}
              key={idx}
              isAuthProtected={true}
              title={route.title}
              permission={route.permission}
              exact
            />
          )
        })}
      </Switch>
      <ToastContainer autoClose={3000} />
    </div>
  )
}

App.propTypes = {
  layout: PropTypes.any,
  getTreeList: PropTypes.func,
}

const mapStateToProps = (state) => {
  return {
    layout: state.Layout,
  }
}

export default connect(mapStateToProps, { getTreeList })(App)

import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"

import { FieldLabel, SelectField, TextField } from "components/Common/Form"

import { isEmpty, CURRENCIES } from "helpers/cms_helper"

const PriceField = props => {
  const { value, onChange, required, disabled, onError, submitted } = props
  const [val, setVal] = useState({ currency: "", amount: "" })
  const [error, setError] = useState({})

  useEffect(() => {
    if (isEmpty(value)) {
      // reset
      setVal({ currency: "", amount: "" })
    } else {
      let toSet = _.cloneDeep(value)
      for (const key in toSet) {
        if (isEmpty(toSet[key])) toSet[key] = ""
      }
      setVal(toSet)
    }
  }, [value])

  useEffect(() => {
    for (const key in error) {
      if (!isEmpty(error[key])) return onError(true)
    }
    return onError(false)
  }, [error])

  const handleChange = val => {
    onChange(val)
  }

  const handleError = (fieldName, errorMsg) => {
    setError(prevState => ({ ...prevState, [fieldName]: errorMsg }))
  }

  return (
    <div className="row">
      <div className="col-5">
        <FieldLabel
          name="currency"
          label="Currency"
          required={required}
          hasError={submitted && !!error.currency}
        />
        <SelectField
          fieldType="custom"
          name="currency"
          options={CURRENCIES}
          required={required}
          disabled={disabled}
          value={val.currency}
          clearable={false}
          onChange={v => handleChange({ ...val, currency: v })}
          onError={errorMsg => handleError("currency", errorMsg)}
          hasError={submitted && !!error.currency}
        />
        {submitted && error.currency && (
          <span className="text-danger">{error.currency}</span>
        )}
      </div>

      <div className="col-7">
        <FieldLabel
          name="amount"
          label="Amount"
          required={required}
          hasError={submitted && !!error.amount}
        />
        <TextField
          fieldType="custom"
          name="amount"
          type="price"
          min={1}
          disabled={disabled}
          required={required}
          value={val.amount}
          onChange={v => handleChange({ ...val, amount: v })}
          onError={errorMsg => handleError("amount", errorMsg)}
          hasError={submitted && !!error.amount}
        />
        {submitted && error.amount && (
          <span className="text-danger">{error.amount}</span>
        )}
      </div>
    </div>
  )
}

PriceField.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onChange: PropTypes.func,
  onError: PropTypes.func,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  submitted: PropTypes.bool,
}

PriceField.defaultProps = {
  required: false,
  disabled: false,
  submitted: false,
}

export default PriceField

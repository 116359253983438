import React, { useState } from "react"
import PropTypes from "prop-types"
import { Row, Col } from "reactstrap"

import { SelectField } from "components/Common/Form"

const TablePagination = props => {
  const { total, offset, hits, currentPage, onPageChange, onHitsChange } = props

  function handlePageChange(pageNum) {
    if (pageNum === currentPage) return
    onPageChange(pageNum)
  }

  function handleHitsChange(val) {
    onHitsChange(parseInt(val))
  }

  const numPages = Math.ceil(total / hits)
  const pageItems = []
  let hiddenItemRenderedCount = 0
  let hiddenItemCount =
    numPages <= 8 || !(currentPage > 4 && currentPage < numPages - 3) ? 1 : 2
  for (let i = 1; i <= numPages; i++) {
    // show: first page, last page, currentPage-2, currentPage-1, currentPage, currentPage+1, currentPage+2
    const show =
      i === 1 ||
      i === numPages ||
      (i >= currentPage - 2 && i <= currentPage + 2)

    if (numPages <= 6 || show) {
      pageItems.push(
        <li
          key={i}
          className={
            "page-item" + (currentPage === i ? " table-page-item-active" : "")
          }
        >
          <a
            className="page-link table-page-link rounded-0"
            onClick={() => handlePageChange(i)}
          >
            {i}
          </a>
        </li>
      )
    } else if (
      !show &&
      (hiddenItemRenderedCount === 0 ||
        (i > numPages - 3 && hiddenItemRenderedCount < hiddenItemCount))
    ) {
      pageItems.push(
        <li
          key={`hidden${hiddenItemRenderedCount}`}
          className="page-item disabled"
        >
          <a className="page-link table-page-link rounded-0">...</a>
        </li>
      )
      hiddenItemRenderedCount++
    }
  }

  const hitOptions = [
    { label: "10", value: 10 },
    { label: "50", value: 50 },
    { label: "100", value: 100 },
    { label: "150", value: 150 },
    { label: "200", value: 200 },
    { label: "250", value: 250 },
  ]

  return (
    <Row className="align-items-center no-select">
      <Col sm={4}>
        <div className="row justify-content-start align-items-center">
          <div className="col" style={{ maxWidth: "90px" }}>
            <SelectField
              fieldType={null}
              name="hits"
              options={hitOptions}
              value={hits}
              onChange={handleHitsChange}
              style={{ width: "75px" }}
              clearable={false}
            />
          </div>
          <div className="col">
            <span>
              {total === 0 || total === 1 ? (
                <span>{total}</span>
              ) : (
                <span>
                  {offset + 1}-{Math.min(total, currentPage * hits)}
                </span>
              )}
              <span>
                &nbsp;of total {total} record{total > 1 && "s"}
              </span>
            </span>
          </div>
        </div>
      </Col>
      <Col sm={8}>
        {total > 0 && (
          <nav aria-label="Pagination" className="float-right">
            <ul className="pagination mb-0">
              <li className="page-item">
                <a
                  className="page-link table-page-link rounded-0"
                  aria-label="Previous"
                  onClick={() => handlePageChange(Math.max(1, currentPage - 1))}
                >
                  <span aria-hidden="true">&#x2039;</span>
                </a>
              </li>
              {pageItems}
              <li className="page-item">
                <a
                  className="page-link table-page-link rounded-0"
                  aria-label="Next"
                  onClick={() =>
                    handlePageChange(Math.min(numPages, currentPage + 1))
                  }
                >
                  <span aria-hidden="true">&#x203a;</span>
                </a>
              </li>
            </ul>
          </nav>
        )}
      </Col>
    </Row>
  )
}

TablePagination.propTypes = {
  total: PropTypes.number.isRequired,
  offset: PropTypes.number.isRequired,
  hits: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  onHitsChange: PropTypes.func.isRequired,
}

TablePagination.defaultProps = {
  total: 0,
  offset: 0,
  hits: 50,
  currentPage: 1,
}

export default TablePagination

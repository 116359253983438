import { useState } from "react"

function useFocus(initialState = false) {
  const [isFocused, setIsFocused] = useState(initialState)

  const onFocus = () => setIsFocused(true)
  const onBlur = () => setIsFocused(false)

  return { isFocused, onFocus, onBlur }
}

export default useFocus

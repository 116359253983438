import React, { useCallback, useEffect, useState } from "react"
import PropTypes from "prop-types"
import { Field } from "redux-form"

import { FilePreview, ResourceUploader } from "./ResourceComponents"

import { isEmpty } from "helpers/cms_helper"

const FilesInput = props => {
  const [files, setFiles] = useState([])
  const {
    value,
    onChange,
    required,
    disabled,
    min,
    max,
    onError,
    mode,
    canFlag,
    onFlag,
    flagged, // array of indexes
    isPrivate,
    userId,
  } = props

  useEffect(() => {
    if (!isEmpty(onError)) validate(value)
    setFiles((value || []).map(v => ({ url: v })))
  }, [value])

  const validate = val => {
    if (disabled) return onError(null)

    if (required && (isEmpty(val) || val.length === 0))
      return onError("Required")

    if (min && (val || []).length < min)
      return onError(`At least ${min} files are required`)

    return onError(null)
  }

  const handleUpload = response => {
    if (response?.url) {
      const filesClone = _.cloneDeep(files)
      const newFiles = [...filesClone.map(f => f.url), response.url]
      onChange(newFiles)
    }
  }

  const handleClear = url => {
    const filesClone = _.cloneDeep(files)
    const newFiles = filesClone.filter(f => f.url !== url).map(f => f.url)
    onChange(newFiles)
  }

  return (
    <div>
      {(files || []).map((file, idx) => (
        <div key={idx} className="mb-2">
          <FilePreview
            file={file}
            disabled={disabled || mode === "view"}
            onClear={handleClear}
            canFlag={canFlag}
            onFlag={state => onFlag(state, idx)}
            flagged={(flagged || []).indexOf(idx) !== -1}
          />
        </div>
      ))}
      {(isEmpty(max) || (files || []).length < max) && mode !== "view" && (
        <ResourceUploader
          type="file"
          disabled={disabled}
          onUpload={handleUpload}
          isPrivate={isPrivate}
          userId={userId}
        />
      )}
    </div>
  )
}

const FilesField = props => {
  // for type: files (multi file upload)
  const { fieldType, value, onChange, onError, hasError } = props

  const fieldProps = _.pick(props, [
    "fieldType",
    "name",
    "className",
    "style",
    "required",
    "disabled",
    "min",
    "max",
    "mode",
    "canFlag",
    "onFlag",
    "flagged",
    "isPrivate",
    "userId",
  ])
  fieldProps.className =
    (fieldProps.className || "") + (hasError ? " border border-danger" : "")

  const renderFilesInput = useCallback(
    field => (
      <FilesInput
        {...fieldProps}
        value={field.input.value}
        onChange={field.input.onChange}
        onError={onError}
      />
    ),
    [props.disabled, props.userId] // add dynamic props here
  )

  if (fieldType === "form")
    return (
      <div
        className={"file-field field-wrapper" + (hasError ? " has-error" : "")}
      >
        <Field component={renderFilesInput} {...fieldProps} />
      </div>
    )
  else if (fieldType === "filter")
    return <Field component={renderFilesInput} {...fieldProps} />
  else
    return (
      <FilesInput
        {...fieldProps}
        value={value}
        onChange={onChange}
        onError={onError}
      />
    )
}

FilesField.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  style: PropTypes.object,
  fieldType: PropTypes.string,
  min: PropTypes.number,
  max: PropTypes.number,
  onChange: PropTypes.func,
  value: PropTypes.array,
  onError: PropTypes.func,
  mode: PropTypes.string,
  canFlag: PropTypes.bool,
  onFlag: PropTypes.func,
  flagged: PropTypes.array,
  isPrivate: PropTypes.bool,
  userId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  hasError: PropTypes.bool,
}

FilesField.defaultProps = {
  fieldType: "form",
  required: false,
  disabled: false,
  canFlag: false,
  flagged: [],
  isPrivate: false,
  hasError: false,
}

export default FilesField

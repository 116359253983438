import React, { useRef, useEffect, useState } from "react"
import PropTypes from "prop-types"

import BaseLayout from "components/BaseLayout"
import CustomButton from "components/Common/CustomButton"
import { ApiTable } from "components/Common/Table"

import { getTreeList } from "helpers/backend_helper"
import { dateToStr } from "helpers/cms_helper"

import {
  DISTRICT_OPTIONS,
  TREE_SUBMISSION_STATUS_DISPLAY,
  TREE_SUBMISSION_STATUS_OPTIONS,
  LISTING_STATUS_IN_REVIEW,
} from "."

import { Link, useHistory } from "react-router-dom"
// Store
import { connect } from "react-redux"

const TreeApprovalList = ({ treeOptions = [] }) => {
  const tableRef = useRef(null)
  const [loading, setLoading] = useState(true)
  const history = useHistory()

  // const editing = !!editingRow
  const treeSpeciesOptions = [
    { label: "Species not provided", value: "species not provided" },
  ].concat(treeOptions)

  const columns = [
    { title: "Tree ID#", value: "displayId" },
    {
      title: "Tree Listing Submission Date",
      value: "createdAt",
      format: "date",
    },
    {
      title: "Submitted by",
      value: (row) => row?.submittedBy?.username || "",
    },
    {
      title: "Status",
      value: (row) => TREE_SUBMISSION_STATUS_DISPLAY[row?.status] || "",
    },
    {
      title: "Keep it as hidden",
      value: (row) =>
        row?.keepHiddenDate
          ? `Y, ${dateToStr(row?.keepHiddenDate, "MMM D, YYYY")}`
          : "",
    },
    { title: "Last Update Date", value: "updatedAt", format: "date" },
  ]

  const filters = [
    {
      rowId: 0,
      rowName: "Filter",
      fields: [
        {
          placeholder: "Search by Tree ID# ",
          name: "treeListingNo",
          type: "text",
          icon: "search",
        },
        {
          placeholder: "Search by Submitted By",
          name: "submittedBy",
          type: "text",
          icon: "search",
        },
      ],
    },
    {
      rowId: 1,
      rowName: " ",
      fields: [
        {
          placeholder: "Search by Tree Species",
          name: "treeSpecies",
          type: "select",
          options: treeSpeciesOptions,
        },
      ],
    },
    {
      rowId: 2,
      rowName: " ",
      fields: [
        {
          placeholder: "All Status",
          name: "status",
          type: "select",
          options: TREE_SUBMISSION_STATUS_OPTIONS,
          multiple: true,
          defaultValue: LISTING_STATUS_IN_REVIEW,
        },
        {
          placeholder: "Filter by district",
          name: "district",
          type: "select",
          options: DISTRICT_OPTIONS,
          multiple: true,
        },
        {
          placeholder: "Keep it as hidden",
          name: "keepHidden",
          type: "select",
          options: [{ label: "Y", value: true }],
        },
      ],
    },
    {
      rowId: 3,
      rowName: " ",
      fields: [
        {
          name: "submissionDateFrom",
          type: "datetime",
          placeholder: "Submission Date From",
        },
        {
          name: "submissionDateTo",
          type: "datetime",
          placeholder: "Submission Date To",
        },
      ],
    },
  ]

  async function fetch(params) {
    return getTreeList({ status: `${LISTING_STATUS_IN_REVIEW}`, ...params })
  }

  function setCurrentPage() {
    const currentPage = tableRef?.current?.state?.currentPage
    localStorage.setItem("tree-approval-page", currentPage)
  }

  useEffect(() => {
    if (
      !loading &&
      localStorage.getItem("tree-approval-page") &&
      localStorage.getItem("locationFrom") === history?.location?.pathname
    ) {
      const page = parseInt(localStorage.getItem("tree-approval-page"), 10)
      tableRef?.current?.handlePageChange(page)
      localStorage.removeItem("tree-approval-page")
      localStorage.removeItem("locationFrom")
    }
  }, [loading])

  return (
    <BaseLayout title="Pending Records">
      <ApiTable
        ref={tableRef}
        name="tree-approval"
        fetch={fetch}
        columns={columns}
        filters={filters}
        setCustomFetchOnMount={setLoading}
        rowButtons={(row) => (
          <Link
            to={{
              pathname: `/tree-listings/${row.id}`,
              state: { from: history?.location?.pathname },
            }}
          >
            <CustomButton onClick={setCurrentPage} className="btn-outline-dark">
              View
            </CustomButton>
          </Link>
        )}
      />
    </BaseLayout>
  )
}

const mapStatetoProps = (state) => {
  const { treeOptions } = state.tree
  return { treeOptions }
}

export default connect(mapStatetoProps, {})(TreeApprovalList)

TreeApprovalList.propTypes = {
  treeOptions: PropTypes.array,
}

TreeApprovalList.defaultProps = {
  treeOptions: [],
}

import React, { useCallback } from "react"
import PropTypes from "prop-types"
import { Field } from "redux-form"

const ComponentField = props => {
  // for type: component
  const { fieldType, component, value, onChange, onError, submitted } = props
  const CustomComponent = component

  const fieldProps = _.pick(props, [
    "name",
    "type",
    "className",
    "style",
    "required",
    "disabled",
  ])

  const renderComponent = useCallback(
    field => (
      <CustomComponent
        {...props}
        value={field.input.value}
        onChange={val => field.input.onChange(val)}
        onError={onError}
        submitted={submitted}
      />
    ),
    [props.disabled, props.submitted] // add dynamic props here
  )

  if (fieldType === "form" || fieldType === "filter")
    return <Field component={renderComponent} {...fieldProps} />
  else
    return (
      <CustomComponent
        {...props}
        input={{ value: value, onChange: onChange }}
        onError={onError}
      />
    )
}

ComponentField.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  component: PropTypes.elementType,
  className: PropTypes.string,
  style: PropTypes.object,
  fieldType: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
    PropTypes.array,
    PropTypes.object,
  ]),
  submitted: PropTypes.bool,
}

ComponentField.defaultProps = {
  fieldType: "form",
  required: false,
  disabled: false,
  submitted: false,
}

export default ComponentField

import React, { useState } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"

import Modal from "components/Common/Modal"
import CustomButton from "./CustomButton"

const ConfirmModal = props => {
  const { message, onConfirm, confirmButtonClassName } = props
  const [confirmModalOpen, setConfirmModalOpen] = useState(true)
  const [submitting, setSubmitting] = useState(false)

  const toggleConfirmModal = (open = true) => {
    setConfirmModalOpen(open)
    if (open === false)
      ReactDOM.unmountComponentAtNode(
        document.getElementById("page-content-append")
      )
  }

  return (
    <Modal
      title={message}
      isOpen={confirmModalOpen}
      onToggle={toggleConfirmModal}
      style={{ marginTop: "15vh" }}
    >
      <div className="float-right">
        <CustomButton
          className="btn-outline-secondary mr-2"
          onClick={() => toggleConfirmModal(false)}
        >
          Cancel
        </CustomButton>
        <CustomButton
          className={confirmButtonClassName || "btn-success"}
          onClick={() => {
            setSubmitting(true)
            onConfirm()
              .then(() => {
                setSubmitting(false)
                toggleConfirmModal(false)
              })
              .catch(() => setSubmitting(false))
          }}
          loading={submitting}
        >
          Confirm
        </CustomButton>
      </div>
    </Modal>
  )
}

ConfirmModal.propTypes = {
  message: PropTypes.string,
  onConfirm: PropTypes.func,
  confirmButtonClassName: PropTypes.string,
}

export default ConfirmModal

import { takeEvery, fork, put, all, call } from "redux-saga/effects"

import { GET_TREE_LIST, UPDATE_TREE_LIST } from "./actionTypes"

import { getAllTreeSpecies } from "helpers/backend_helper"

import { getJwt } from "helpers/localstorage/auth"

function* fetchTreeSpecies() {
  if (getJwt()) {
    try {
      const res = yield call(getAllTreeSpecies)

      yield put({ type: UPDATE_TREE_LIST, payload: res?.results })
    } catch (error) {
      console.log(error)
    }
  }
}
export function* watchTree() {
  yield takeEvery(GET_TREE_LIST, fetchTreeSpecies)
}

function* TreeSaga() {
  yield all([fork(watchTree)])
}

export default TreeSaga

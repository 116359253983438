import React from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import { Draggable } from "react-beautiful-dnd"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faBars, faCircleMinus } from "@fortawesome/free-solid-svg-icons"
import ReactHtmlParser from "react-html-parser"

import CustomCheckbox from "components/Common/CustomCheckbox"
import FormField from "../Form/FormField"
import { ToggleField } from "components/Common/Form"

import { isPrivate, getResourceUrl } from "helpers/resource_helper"
import { dateToStr, isEmpty } from "helpers/cms_helper"

const DataTableRows = props => {
  const {
    type,
    rows,
    columns,
    rowButtons,
    draggable,
    canEditRows,
    onDelete,
    selectable,
    onSelect,
    selected,
    initSelected,
    ignoreError = false,
    onRowUpdate,
    onModalDisplay,
  } = props

  async function handleViewResource(url) {
    const toOpen = await getResourceUrl(url)
    window.open(toOpen, "_blank")
  }

  let parsedRows = []
  if (rows)
    parsedRows = rows.map((row, rowIdx) => {
      return columns.map((col, colIdx) => {
        if (col.hidden) return

        let rowValue = ""
        let displayValue = ""

        if (_.isString(col.value)) {
          rowValue = _.get(row, col.value)
        } else if (typeof col.value === "function") {
          rowValue = col.value(row)
        }

        if (isEmpty(rowValue) && col?.defaultValue)
          return (
            <td key={rowIdx + "-" + colIdx}>
              <div>{col.defaultValue}</div>
            </td>
          )

        switch (col.format) {
          case "date":
            // default format Jan 1, 2022
            displayValue = dateToStr(
              rowValue,
              col.dateFormat ? col.dateFormat : "MMM D, YYYY"
            )
            break
          case "datetime":
            // default format Jan 1, 2022 14:09:30
            displayValue = dateToStr(
              rowValue,
              col.dateFormat ? col.dateFormat : "MMM D, YYYY HH:mm:ss"
            )
            break
          case "toggle":
            // for when PUT API is called per row upon toggle
            displayValue = (
              <ToggleField
                fieldType="table"
                name={col.value + "-" + rowIdx}
                value={rowValue}
                onChange={val => col.onToggle(row, val)}
                disabled={col.disabled}
              />
            )
            break
          case "image":
            displayValue = (
              <a target="_blank" onClick={() => handleViewResource(rowValue)}>
                {isPrivate(rowValue) ? (
                  <span className="text-primary">View Image</span>
                ) : (
                  <img src={rowValue} className="data-table-image" />
                )}
              </a>
            )
            break
          case "file":
            displayValue = (
              <a target="_blank" onClick={() => handleViewResource(rowValue)}>
                <span className="custom-link">
                  {isPrivate(rowValue) ? "View File" : rowValue}
                </span>
              </a>
            )
            break
          case "input":
            // for when PUT API is called per the whole table
            const errorMessageField = `rowErrorMessages.${col.value}`
            const errorMessage = _.get(row, errorMessageField)
            const inputProps =
              typeof col?.input === "function" ? col.input(row) : col?.input
            displayValue = (
              <FormField
                fieldType="table"
                name={col.value}
                {...inputProps}
                value={rowValue}
                row={row}
                onChange={v => onRowUpdate(row, col.value, rowIdx, v)}
                onError={errMsg =>
                  onRowUpdate(row, errorMessageField, rowIdx, errMsg)
                }
                hasError={!ignoreError && !isEmpty(errorMessage)}
              />
            )
            if (!ignoreError && !isEmpty(errorMessage))
              displayValue = (
                <>
                  {displayValue}
                  <span
                    className="text-danger"
                    style={{ whiteSpace: "normal" }}
                  >
                    {errorMessage}
                  </span>
                </>
              )
            break
          case "html":
            displayValue = ReactHtmlParser(rowValue)
            break
          case "fulltext":
            displayValue = (
              <>
                <div style={{ textOverflow: "ellipsis", overflow: "hidden" }}>
                  {rowValue}{" "}
                </div>
                <button
                  type="button"
                  onClick={() => onModalDisplay && onModalDisplay(rowValue)}
                  className="btn btn-light btn-sm rounded-0"
                >
                  Show All
                </button>
              </>
            )
            break
          default:
            displayValue = rowValue
        }

        if (col.link) {
          let link = ""
          if (_.isString(col.link)) link = col.link
          else if (typeof col.link === "function") link = col.link(row)
          if (!isEmpty(link))
            displayValue = (
              <Link to={link} target="_blank" className="custom-link">
                {displayValue}
              </Link>
            )
        }

        if (col.tooltip) {
          let tooltipContent = ""
          if (_.isString(col.tooltip)) tooltipContent = col.tooltip
          else if (typeof col.tooltip === "function")
            tooltipContent = col.tooltip(row)
          displayValue = <span title={tooltipContent}>{displayValue}</span>
        }

        let cellStyle =
          col.format === "toggle" && type === "table"
            ? { width: "65px", textAlign: "center" }
            : {}
        cellStyle = { ...cellStyle, ...col.style }

        return (
          <td key={rowIdx + "-" + colIdx}>
            <div style={cellStyle}>{displayValue}</div>
          </td>
        )
      })
    })

  return (
    <>
      {parsedRows && parsedRows.length > 0 ? (
        parsedRows.map((row, idx) => {
          const parsedCells = (
            <>
              {canEditRows && (
                <td
                  className="text-center text-danger"
                  style={{ width: "50px" }}
                >
                  <div>
                    <FontAwesomeIcon
                      icon={faCircleMinus}
                      onClick={() => onDelete(idx)}
                      style={{ cursor: "pointer" }}
                    />
                  </div>
                </td>
              )}
              {selectable && (
                <td>
                  <div>
                    <CustomCheckbox
                      value={
                        _.findIndex(selected || [], { id: rows[idx].id }) !== -1
                      }
                      onChange={state => onSelect(state, rows[idx])}
                      disabled={
                        _.findIndex(initSelected || [], {
                          id: rows[idx].id,
                        }) !== -1
                      }
                    />
                  </div>
                </td>
              )}
              {row.map(cell => cell)}
              {rowButtons && (
                <td className="data-table-row-buttons">
                  {rowButtons(rows[idx])}
                </td>
              )}
            </>
          )

          return draggable ? (
            <Draggable draggableId={`${idx}`} key={idx} index={idx}>
              {(provided, snapshot) => {
                return (
                  <tr {...provided.draggableProps} ref={provided.innerRef}>
                    <td
                      {...provided.dragHandleProps}
                      className="text-center"
                      style={{ width: "76px" }}
                    >
                      <div>
                        <FontAwesomeIcon icon={faBars} />
                      </div>
                    </td>
                    {parsedCells}
                  </tr>
                )
              }}
            </Draggable>
          ) : (
            <tr key={idx}>{parsedCells}</tr>
          )
        })
      ) : (
        <tr>
          <td
            colSpan="999"
            className="text-center pt-3"
            style={{ fontStyle: "italic" }}
          >
            No result
          </td>
        </tr>
      )}
    </>
  )
}

DataTableRows.propTypes = {
  type: PropTypes.string,
  rows: PropTypes.array,
  columns: PropTypes.array.isRequired,
  rowButtons: PropTypes.oneOfType([PropTypes.object, PropTypes.elementType]),
  draggable: PropTypes.bool,
  canEditRows: PropTypes.bool,
  onDelete: PropTypes.func,
  selectable: PropTypes.bool,
  onSelect: PropTypes.func,
  selected: PropTypes.array,
  initSelected: PropTypes.array,
  onRowUpdate: PropTypes.func,
}

DataTableRows.defaultProps = {
  type: "table",
  draggable: false,
  canEditRows: false,
  selectable: false,
}

export default DataTableRows

import React from "react"
import PropTypes from "prop-types"
import { Button } from "reactstrap"

import FlagButton from "components/Common/FlagButton"

import { getResourceUrl, isPrivate } from "helpers/resource_helper"
import { isEmpty } from "helpers/cms_helper"

const ImagePreview = props => {
  const {
    disabled,
    image,
    onClear,
    size,
    className,
    canFlag,
    onFlag,
    flagged,
  } = props

  if (isEmpty(image)) return null

  async function handleViewImage(url) {
    const toOpen = await getResourceUrl(url)
    window.open(toOpen, "_blank")
  }

  return (
    <div
      className={"image-field-preview " + (className ? className : "")}
      style={{
        display: "inline-block",
        backgroundColor: disabled ? "#f7f7f7" : null,
      }}
    >
      {canFlag && (
        <FlagButton
          value={flagged}
          onClick={onFlag}
          style={{ float: "left" }}
        />
      )}
      {!disabled && (
        <Button close onClick={() => onClear(image.url)} disabled={disabled} />
      )}
      <div
        className={
          "p-3 " +
          (size === "sm"
            ? "image-field-preview-img-sm"
            : "image-field-preview-img")
        }
      >
        <a target="_blank" onClick={() => handleViewImage(image.url)}>
          {isPrivate(image.url) ? (
            <p className="text-primary" style={{ margin: "45px" }}>
              View Image
            </p>
          ) : (
            <img src={image.url} />
          )}
        </a>
      </div>
    </div>
  )
}

ImagePreview.propTypes = {
  disabled: PropTypes.bool,
  image: PropTypes.object,
  onClear: PropTypes.func,
  size: PropTypes.string,
  className: PropTypes.string,
  canFlag: PropTypes.bool,
  onFlag: PropTypes.func,
  flagged: PropTypes.bool,
}

ImagePreview.defaultProps = {
  disabled: false,
  canFlag: false,
  flagged: false,
}

export default ImagePreview

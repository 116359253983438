import React, { useCallback, useEffect, useMemo } from "react"
import PropTypes from "prop-types"
import { Field } from "redux-form"
import Select, { createFilter } from "react-select"

import { isEmpty } from "helpers/cms_helper"

import _ from "lodash"

const SelectInput = (props) => {
  const {
    value,
    onChange,
    options,
    defaultValue,
    style,
    required,
    disabled,
    clearable,
    onError,
    onChangeCustom,
    onBlur, // onBlur must be used together with touched in order to make touched work
    touched,
    multiple,
    hasError,
    fieldType,
  } = props

  useEffect(() => {
    if (!isEmpty(onError)) validate(value)
    if (!isEmpty(onChangeCustom)) onChangeCustom(value, touched)
  }, [value])

  const validate = (val) => {
    if (disabled) return onError(null)

    if (required && (isEmpty(val) || val.length === 0))
      return onError("Required")
    return onError(null)
  }

  const fieldProps = _.pick(props, ["name", "options", "placeholder"])

  const customStyles = {
    control: (base, state) => ({
      ...base,
      boxShadow: "none",
      border: "1px solid " + (hasError ? "#f46a6a" : "#ced4da"),
      "&:hover": {
        border: "1px solid " + (hasError ? "#f46a6a" : "#ced4da"),
      },
      backgroundColor: state.isDisabled ? "#f7f7f7" : undefined,
      maxWidth: fieldType !== "filter" ? (multiple ? "1080px" : "540px") : null,
      ...style,
    }),
    placeholder: (styles) => ({
      ...styles,
      color: "#74788d",
    }),
    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
    singleValue: (styles) => ({
      ...styles,
      color: "#495057",
    }),
    multiValue: (styles) => ({
      ...styles,
      backgroundColor: "white",
      border: "1px solid #343a40",
    }),
    multiValueLabel: (styles) => ({
      ...styles,
      color: "#343a40",
    }),
    multiValueRemove: (styles) => ({
      ...styles,
      color: "#74788d",
      ":hover": {
        color: "#343a40",
      },
    }),
  }

  return (
    <Select
      {...fieldProps}
      isMulti={multiple}
      menuPortalTarget={document.body}
      menuPlacement="auto"
      value={
        isEmpty(value)
          ? value
          : _.isArray(value)
          ? _.filter(options || [], (o) => value.indexOf(o.value) !== -1)
          : (options || []).find((option) => option.value === value)
      }
      onChange={(val) => {
        if (_.isArray(val)) onChange(val.map((v) => v.value))
        else onChange(val === null ? val : val.value)
        if (!isEmpty(onBlur)) onBlur()
      }}
      defaultValue={(options || []).filter(
        (option) => option.value === defaultValue
      )}
      theme={(theme) => ({
        ...theme,
        borderRadius: 0,
        colors: {
          ...theme.colors,
          primary: "#343a40",
          primary75: "#74788d",
          primary50: "#ced4da",
          primary25: "#f6f6f6",
        },
      })}
      styles={customStyles}
      components={{
        IndicatorSeparator: () => null,
      }}
      filterOption={createFilter()}
      isClearable={!required && clearable}
      isDisabled={disabled}
      isOptionDisabled={(option) => option.disabled}
    />
  )
}

const SelectField = (props) => {
  // for type: select
  // options can be either an array of strings / an array of {label: "", value: ""}
  const { fieldType, options, value, onChange, onError, hasError } = props

  const fieldProps = _.pick(props, [
    "fieldType",
    "name",
    "required",
    "disabled",
    "placeholder",
    "className",
    "style",
    "clearable",
    "onChangeCustom",
    "multiple",
    "hasError",
  ])
  fieldProps.className =
    (fieldProps.className || "") +
    " form-control rounded-0" +
    (hasError ? " border-danger" : "")

  const parsedOptions = useMemo(() => {
    let results = []
    for (const option of options) {
      if (_.isString(option)) results.push({ label: option, value: option })
      else results.push(option)
    }
    return results
  }, [options])

  const renderSelectInput = useCallback(
    ({ input, meta: { touched } }) => (
      <SelectInput
        {...fieldProps}
        options={parsedOptions}
        value={input.value}
        onChange={(val) => input.onChange(val)}
        onError={onError}
        onBlur={input.onBlur}
        touched={touched}
      />
    ),
    [props.hasError, props.options, props.disabled] // add dynamic props here
  )

  if (fieldType === "form" || fieldType === "filter")
    return <Field component={renderSelectInput} {...fieldProps} />
  else
    return (
      <SelectInput
        {...fieldProps}
        options={parsedOptions}
        value={value}
        onChange={onChange}
        onError={onError}
      />
    )
}

SelectInput.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  options: PropTypes.array,
  className: PropTypes.string,
  style: PropTypes.object,
  fieldType: PropTypes.string,
  clearable: PropTypes.bool,
  onChange: PropTypes.func,
  onChangeCustom: PropTypes.func,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.array,
  ]),
  defaultValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.array,
  ]),
  onError: PropTypes.func,
  multiple: PropTypes.bool,
  hasError: PropTypes.bool,
  onBlur: PropTypes.any,
  touched: PropTypes.any,
}

SelectField.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  options: PropTypes.array,
  className: PropTypes.string,
  style: PropTypes.object,
  fieldType: PropTypes.string,
  clearable: PropTypes.bool,
  onChange: PropTypes.func,
  onChangeCustom: PropTypes.func,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.array,
  ]),
  onError: PropTypes.func,
  multiple: PropTypes.bool,
  hasError: PropTypes.bool,
}

SelectField.defaultProps = {
  fieldType: "form",
  clearable: true,
  required: false,
  disabled: false,
  multiple: false,
  hasError: false,
}

export default SelectField

import React, { useState, useEffect } from "react"
import { useParams, useHistory } from "react-router-dom"

import BaseLayout from "components/BaseLayout"
import FaqForm from "./FaqForm"

import { getFaq, editFaq, createFaq } from "helpers/backend_helper"
import { toast } from "helpers/cms_helper"

const FaqDetail = () => {
  const history = useHistory()
  const [editingRow, setEditingRow] = useState(null)
  const { id } = useParams()
  const mode = id === "create" ? "create" : "edit"

  async function fetchFaq(id) {
    const res = await getFaq(id)

    setEditingRow(res)
  }

  async function handleEditSubmit(values) {
    // console.log(values)
    await editFaq(editingRow.id, values)
    toast("Updated")
  }

  async function handleCreateSubmit(values) {
    // console.log(values)
    await createFaq(values)
    toast("Created")
    history.push({
      pathname: "/static-pages/faq",
    })
  }

  useEffect(() => {
    if (mode === "edit") fetchFaq(id)
  }, [id])

  return (
    <BaseLayout title={`${mode === "create" ? "Create FAQ" : "Edit FAQ"}`}>
      <FaqForm
        name="faq-edit"
        mode={mode}
        data={editingRow}
        onSubmit={mode === "create" ? handleCreateSubmit : handleEditSubmit}
      />
    </BaseLayout>
  )
}

export default FaqDetail

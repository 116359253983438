import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"

// import FlagButton from "components/Common/FlagButton"
import { FieldLabel, TextField } from "components/Common/Form"

import { isEmpty } from "helpers/cms_helper"

const ReportTreeData = (props) => {
  const { value, required, disabled, onError, submitted } = props

  const [val, setVal] = useState([])
  const [error, setError] = useState([])

  const handleError = (fieldName, errorMsg) => {
    setError((prevState) => ({ ...prevState, [fieldName]: errorMsg }))
  }

  useEffect(() => {
    if (isEmpty(value)) {
      // reset
      setVal({})
    } else {
      if (Array.isArray(value)) {
        // init data (array of objects)

        setVal(value)
        // onChange(valueArray)
      } else {
        // input change (nested object)
        setVal(value)
      }
    }
  }, [value])

  useEffect(() => {
    for (const key in error) {
      if (!isEmpty(error[key])) return onError(true)
    }
    return onError(false)
  }, [error])

  return (
    <div>
      <div className="row align-items-end mb-3">
        <div className="col">
          <FieldLabel
            name={`tree-species`}
            label={`Tree Species`}
            hasError={submitted && !!error[`treeSpecies`]}
          ></FieldLabel>
          <TextField
            fieldType="customValue"
            name={`tree-species`}
            value={val["treeSpecies"]}
            onChange={() => {}}
            disabled={disabled}
            onError={(errorMsg) => handleError([`treeSpecies`], errorMsg)}
          ></TextField>
          {submitted && error[`treeSpecies`] && (
            <span className="text-danger">{error[`treeSpecies`]}</span>
          )}
        </div>
      </div>
      <div className="row align-items-end mb-3">
        <div className="col">
          <FieldLabel
            name={`tree-height`}
            label={`Tree Height(m)`}
            hasError={submitted && !!error[`treeHeight`]}
          ></FieldLabel>
          <TextField
            fieldType="customValue"
            name={`tree-height`}
            value={val["treeHeight"]}
            onChange={() => {}}
            disabled={disabled}
            onError={(errorMsg) => handleError([`treeHeight`], errorMsg)}
          ></TextField>
          {submitted && error[`treeHeight`] && (
            <span className="text-danger">{error[`treeHeight`]}</span>
          )}
        </div>
      </div>
      <div className="row align-items-end mb-3">
        <div className="col">
          <FieldLabel
            name={`diameter-at-breast-height`}
            label={`Diameter At Breast Height(DBH) (cm)`}
            required={required}
            hasError={submitted && !!error[`diameterAtBreastHeight`]}
          ></FieldLabel>
          <TextField
            fieldType="customValue"
            name={`diameter-at-breast-height`}
            value={val["diameterAtBreastHeight"]}
            onChange={() => {}}
            disabled={disabled}
            onError={(errorMsg) =>
              handleError([`diameterAtBreastHeight`], errorMsg)
            }
          ></TextField>
          {submitted && error[`diameterAtBreastHeight`] && (
            <span className="text-danger">
              {error[`diameterAtBreastHeight`]}
            </span>
          )}
        </div>
      </div>
      <div className="row align-items-end mb-3">
        <div className="col">
          <FieldLabel
            name={`circumferenceh`}
            label={`Circumference (cm)`}
            required={required}
            hasError={submitted && !!error[`circumference`]}
          ></FieldLabel>
          <TextField
            fieldType="customValue"
            name={`circumferenceh`}
            value={val["circumference"]}
            onChange={() => {}}
            disabled={disabled}
            onError={(errorMsg) => handleError([`circumference`], errorMsg)}
          ></TextField>
          {submitted && error[`circumference`] && (
            <span className="text-danger">{error[`circumference`]}</span>
          )}
        </div>
      </div>
      <div className="row align-items-end mb-3">
        <div className="col">
          <FieldLabel
            name={`crown-base-height`}
            label={`Crown Base Height`}
            required={required}
            hasError={submitted && !!error[`crownBaseHeight`]}
          ></FieldLabel>
          <TextField
            fieldType="customValue"
            name={`crown-base-height`}
            value={val["crownBaseHeight"]}
            type="number"
            maxDecimalPlaces={1}
            onChange={() => {}}
            disabled={disabled}
            onError={(errorMsg) => handleError([`crownBaseHeight`], errorMsg)}
          ></TextField>
          {submitted && error[`crownBaseHeight`] && (
            <span className="text-danger">{error[`crownBaseHeight`]}</span>
          )}
        </div>
      </div>
      <div className="row align-items-end mb-3">
        <div className="col">
          <FieldLabel
            name={`crown-width`}
            label={`Crown Width`}
            required={required}
            hasError={submitted && !!error[`crownWidth`]}
          ></FieldLabel>
          <TextField
            fieldType="customValue"
            name={`crown-width`}
            value={val["crownWidth"]}
            type="number"
            maxDecimalPlaces={1}
            onChange={() => {}}
            disabled={disabled}
            onError={(errorMsg) => handleError([`crownWidth`], errorMsg)}
          ></TextField>
          {submitted && error[`crownWidth`] && (
            <span className="text-danger">{error[`crownWidth`]}</span>
          )}
        </div>
      </div>
      <div className="row align-items-end mb-3">
        <div className="col">
          <FieldLabel
            name={`ohter`}
            label={`Others`}
            required={required}
            hasError={submitted && !!error[`other`]}
          ></FieldLabel>
          <TextField
            fieldType="customValue"
            name={`other`}
            value={val["other"]}
            onChange={() => {}}
            disabled={disabled}
            onError={(errorMsg) => handleError([`other`], errorMsg)}
          ></TextField>
          {submitted && error[`other`] && (
            <span className="text-danger">{error[`other`]}</span>
          )}
        </div>
      </div>
    </div>
  )
}

export default ReportTreeData

ReportTreeData.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.array,
  ]),
  onError: PropTypes.func,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  canFlag: PropTypes.bool,
  onFlag: PropTypes.func,
  submitted: PropTypes.bool,
}

ReportTreeData.defaultProps = {
  required: false,
  disabled: false,
  canFlag: false,
  submitted: false,
}

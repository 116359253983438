import React, { useCallback, useEffect, useState } from "react"
import PropTypes from "prop-types"
import { Field } from "redux-form"
// https://ckeditor.com/docs/ckeditor5/latest/builds/guides/integration/frameworks/react.html
// set up using the CKEditor 5 online builder
import { CKEditor } from "@ckeditor/ckeditor5-react"
import Editor from "ckeditor5-custom-build/build/ckeditor"

import _ from "lodash"

import { isEmpty } from "helpers/cms_helper"
import { uploadImage } from "helpers/resource_helper"
import { getConvertUrl } from "helpers/backend_helper"

class UploadAdapter {
  constructor(loader) {
    this.loader = loader
  }

  async upload() {
    const file = await this.loader.file
    const signedUrlObj = await uploadImage(file)
    const url = await getConvertUrl(signedUrlObj?.filename)
    return {
      default: url?.url,
    }
  }

  abort() {}
}

function UploadAdapterPlugin(editor) {
  editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
    return new UploadAdapter(loader)
  }
}

// example CKEditor config:
// {
//   toolbar: ["fontSize", "|", "bold", "italic"],
//   placeholder: ...
// }
// available tools:
// heading, fontSize, fontFamily, fontColor
// bold, italic, underline, strikethrough, subscript, superscript, highlight
// alignment, indent, outdent, numberedList, bulletedList, link, blockQuote, horizontalLine
// specialCharacters, insertTable, imageUpload, mediaEmbed
// undo, redo, findAndReplace, | (separator)
const CKEditorInput = (props) => {
  const {
    name,
    value,
    onChange,
    required,
    disabled,
    placeholder,
    config,
    onError,
  } = props

  const [characterCount, setCharacterCount] = useState(0)

  useEffect(() => {
    if (!isEmpty(onError)) validate(value)
  }, [value])

  const validate = (val) => {
    if (disabled) return onError(null)

    if (required && isEmpty(val)) return onError("Required")
    return onError(null)
  }

  return (
    <div style={{ maxWidth: 1100 }}>
      <CKEditor
        name={name}
        editor={Editor}
        data={value}
        onChange={(event, editor) => {
          return onChange(editor.getData())
        }}
        disabled={disabled}
        config={{
          ...config,
          toolbar: [
            "heading",
            "|",
            "fontSize",
            "fontColor",
            "|",
            "bold",
            "italic",
            "underline",
            "strikethrough",
            "highlight",
            "|",
            "alignment",
            "|",
            "numberedList",
            "bulletedList",
            "|",
            "link",
            "blockQuote",
            "imageUpload",
            "|",
            "undo",
            "redo",
            "|",
          ],
          placeholder: placeholder,
          extraPlugins: [UploadAdapterPlugin],
          fontColor: {
            colorPicker: {
              format: "hex",
            },
          },
          image: {
            toolbar: [
              "imageStyle:inline",
              "imageStyle:breakText",
              "imageStyle:wrapText",
              "|",
              "toggleImageCaption",
              "imageTextAlternative",
              "linkImage",
            ],
          },
          link: {
            addTargetToExternalLinks: true,
          },
          wordCount: {
            onUpdate: (stats) => {
              setCharacterCount(stats?.characters)
            },
          },
        }}
      />
      <div
        style={{
          textAlign: "right",
          padding: "5px",
          background: "#ccc",
          color: characterCount > 65535 ? "red" : null,
        }}
      >
        {`Charactes: ${characterCount} / 65535`}
      </div>
    </div>
  )
}

const CKEditorField = (props) => {
  // for type: ckeditor
  const { fieldType, name, value, onChange, onError, hasError } = props

  const fieldProps = _.pick(props, [
    "fieldType",
    "name",
    "placeholder",
    "config",
    "className",
    "required",
    "disabled",
  ])
  fieldProps.className =
    (fieldProps.className || "") +
    " form-control rounded-0" +
    (hasError ? " border-danger" : "")

  const renderCkEditor = useCallback(
    (field) => {
      return (
        <CKEditorInput
          {...fieldProps}
          value={field.input.value}
          onChange={field.input.onChange}
          onError={onError}
        />
      )
    },
    [props.disabled] // add dynamic props here
  )

  if (fieldType === "form")
    return (
      <div
        className={
          "ck-editor-field field-wrapper" + (hasError ? " has-error" : "")
        }
      >
        <Field component={renderCkEditor} name={name} />
      </div>
    )
  else if (fieldType === "filter")
    return <Field component={renderCkEditor} name={name} />
  else
    return (
      <CKEditorInput
        {...fieldProps}
        value={value}
        onChange={onChange}
        onError={onError}
      />
    )
}

CKEditorInput.propTypes = {
  name: PropTypes.string.isRequired,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  config: PropTypes.object,
  onChange: PropTypes.func,
  value: PropTypes.string,
  onError: PropTypes.func,
}

CKEditorField.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.object,
  config: PropTypes.object,
  fieldType: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.string,
  onError: PropTypes.func,
  hasError: PropTypes.bool,
}

CKEditorField.defaultProps = {
  fieldType: "form",
  required: false,
  disabled: false,
  hasError: false,
}

export default CKEditorField

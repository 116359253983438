import { GET_TREE_LIST, UPDATE_TREE_LIST } from "./actionTypes"

export const getTreeList = () => {
  return {
    type: GET_TREE_LIST,
  }
}

export const updateTreeList = (list) => {
  return {
    type: UPDATE_TREE_LIST,
    payload: { list },
  }
}

import React from "react"
import { TreeSpeciesList } from "."

const TreeSpecies = () => {
  return (
    <>
      <TreeSpeciesList />
    </>
  )
}

export default TreeSpecies

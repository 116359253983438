import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"

import { ImageField } from "components/Common/Form"

import { isEmpty } from "helpers/cms_helper"

const TreeIssueMediaField = (props) => {
  const { value, disabled } = props
  const [val, setVal] = useState([])

  useEffect(() => {
    if (isEmpty(value)) {
      // reset
      setVal([])
    } else {
      setVal(value)
    }
  }, [value])

  return (
    <div className="row">
      {val?.map((media) => {
        const key = `tree-media-${media?.id}}`

        return (
          <div
            key={key}
            className="col col-xl-4 col-lg-4 col-md-4 col-sm-6 col-6 mb-3"
            style={{ minWidth: "100px" }}
          >
            <label className="mb-0"></label>
            <ImageField
              fieldType="custom"
              name={key}
              size="sm"
              includeFilename={true}
              disabled={disabled}
              value={media?.url}
              onChange={() => {}}
            />
          </div>
        )
      })}
    </div>
  )
}

TreeIssueMediaField.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.array,
  ]),
  disabled: PropTypes.bool,
}

TreeIssueMediaField.defaultProps = {
  disabled: false,
}

export default TreeIssueMediaField

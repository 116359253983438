import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"

// import FlagButton from "components/Common/FlagButton"
import { FieldLabel, TextField, SelectField } from "components/Common/Form"
import CustomButton from "components/Common/CustomButton"

import { isEmpty } from "helpers/cms_helper"

import { TREE_SUBMISSION_POM_OPTIONS, DEFAULT_STEM_OBJECT } from "."

const TreeSubmissionStemsField = (props) => {
  const {
    value,
    onChange,
    customButtonEvent,
    required,
    disabled,
    onError,
    // canFlag,
    // onFlag,
    submitted,
  } = props

  const [val, setVal] = useState([])
  const [error, setError] = useState([])

  const handleChange = (val) => {
    onChange(val)
  }

  const handleError = (fieldName, errorMsg) => {
    setError((prevState) => ({ ...prevState, [fieldName]: errorMsg }))
  }

  useEffect(() => {
    if (isEmpty(value)) {
      // reset
      setVal([DEFAULT_STEM_OBJECT])
    } else {
      if (Array.isArray(value)) {
        // init data (array of objects)

        setVal(value)
        // onChange(valueArray)
      } else {
        // input change (nested object)
        setVal(value)
      }
    }
  }, [value])

  useEffect(() => {
    for (const key in error) {
      if (!isEmpty(error[key])) return onError(true)
    }
    return onError(false)
  }, [error])

  return (
    <>
      {val.map((stem, index) => {
        const key = `tree-stem-${index}`
        const i = index + 1

        return (
          <div key={key}>
            <div className="row align-items-end mb-3">
              <div className="col">
                <FieldLabel
                  name={`stem-point-of-measurement-${index}`}
                  label={`Stem ${i}_Point of Measurement (POM) (m)`}
                  hasError={submitted && !!error[`stem-${i}-pom`]}
                ></FieldLabel>
                <SelectField
                  fieldType="customValue"
                  name={`stem-point-of-measurement-${index}`}
                  value={stem?.pointOfMeasurement}
                  options={TREE_SUBMISSION_POM_OPTIONS}
                  onChange={(v) => {
                    setVal((preV) => {
                      const newArr = [...preV]
                      newArr[index].pointOfMeasurement = v
                      return newArr
                    })

                    handleChange(val)
                  }}
                  disabled={disabled}
                  onError={(errorMsg) =>
                    handleError([`stem-${i}-pom`], errorMsg)
                  }
                ></SelectField>
              </div>
              {val?.length === i && val?.length > 1 && (
                <div className="col pt-2" style={{ maxWidth: "fit-content" }}>
                  <CustomButton
                    onClick={customButtonEvent}
                    className="btn-outline-dark mr-3"
                    disabled={disabled}
                  >
                    Delete last set of stem data
                  </CustomButton>
                </div>
              )}
            </div>
            <div className="row align-items-end mb-3">
              <div className="col">
                <FieldLabel
                  name={`stem-circumference-at-breast-height-${index}`}
                  label={`Stem ${i}_Circumference at Breast Height (cm)`}
                  hasError={
                    submitted &&
                    !!error[`stem-${i}-circumferenceAtBreastHeight`]
                  }
                ></FieldLabel>
                <TextField
                  fieldType="customValue"
                  name={`stem-circumference-at-breast-height-${index}`}
                  value={stem.circumferenceAtBreastHeight}
                  type="number"
                  min={
                    stem.circumferenceAtBreastHeight === null ? undefined : 29.8
                  }
                  maxDecimalPlaces={1}
                  onChange={(v) => {
                    setVal((preV) => {
                      const newArr = [...preV]
                      const PI = 3.14
                      // DBH = circumference / π
                      const diameterAtBreastHeight = v / PI

                      newArr[index].circumferenceAtBreastHeight = v

                      if (v !== null) {
                        newArr[index].diameterAtBreastHeight =
                          diameterAtBreastHeight?.toFixed(1)
                      } else {
                        newArr[index].diameterAtBreastHeight = null
                      }
                      return newArr
                    })
                    handleChange(val)
                  }}
                  disabled={disabled}
                  onError={(errorMsg) =>
                    handleError(
                      [`stem-${i}-circumferenceAtBreastHeight`],
                      errorMsg
                    )
                  }
                ></TextField>
                {submitted &&
                  error[`stem-${i}-circumferenceAtBreastHeight`] && (
                    <span className="text-danger">
                      {error[`stem-${i}-circumferenceAtBreastHeight`]}
                    </span>
                  )}
              </div>
            </div>
            <div className="row align-items-end mb-3">
              <div className="col">
                <FieldLabel
                  name={`stem-diameter-at-breast-height-${index}`}
                  label={`Stem ${i}_Diameter at Breast Height (DBH) (cm)`}
                  hasError={
                    submitted && !!error[`stem-${i}-diameterAtBreastHeight`]
                  }
                ></FieldLabel>
                <TextField
                  fieldType="customValue"
                  name={`stem-diameter-at-breast-height-${index}`}
                  value={stem?.diameterAtBreastHeight}
                  type="number"
                  min={stem?.diameterAtBreastHeight === null ? undefined : 9.5}
                  maxDecimalPlaces={1}
                  onChange={(v) => {
                    setVal((preV) => {
                      const newArr = [...preV]
                      const PI = 3.14
                      // DBH = circumference / π
                      const circumferenceAtBreastHeight = v * PI

                      newArr[index].diameterAtBreastHeight = v

                      if (v !== null) {
                        newArr[index].circumferenceAtBreastHeight =
                          circumferenceAtBreastHeight?.toFixed(1)
                      } else {
                        newArr[index].circumferenceAtBreastHeight = null
                      }

                      return newArr
                    })
                    handleChange(val)
                  }}
                  disabled={disabled}
                  onError={(errorMsg) =>
                    handleError([`stem-${i}-diameterAtBreastHeight`], errorMsg)
                  }
                ></TextField>
                {submitted && error[`stem-${i}-diameterAtBreastHeight`] && (
                  <span className="text-danger">
                    {error[`stem-${i}-diameterAtBreastHeight`]}
                  </span>
                )}
              </div>
            </div>
            <div className="row align-items-end mb-3">
              <div className="col">
                <FieldLabel
                  name={`stem-tree-height-${index}`}
                  label={`Stem ${i}_Tree Height (m)`}
                  required={required}
                  hasError={submitted && !!error[`stem-${i}-treeHeight`]}
                ></FieldLabel>
                <TextField
                  fieldType="customValue"
                  name={`stem-tree-height-${index}`}
                  value={stem?.treeHeight}
                  type="number"
                  min={1.3}
                  maxDecimalPlaces={1}
                  onChange={(v) => {
                    setVal((preV) => {
                      const newArr = [...preV]
                      newArr[index].treeHeight = v
                      return newArr
                    })
                    handleChange(val)
                  }}
                  placeholder="Tree height not provided"
                  disabled={disabled}
                  onError={(errorMsg) =>
                    handleError([`stem-${i}-treeHeight`], errorMsg)
                  }
                ></TextField>
                {submitted && error[`stem-${i}-treeHeight`] && (
                  <span className="text-danger">
                    {error[`stem-${i}-treeHeight`]}
                  </span>
                )}
              </div>
            </div>
          </div>
        )
      })}
    </>
  )
}

export default TreeSubmissionStemsField

TreeSubmissionStemsField.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.array,
  ]),
  onChange: PropTypes.func,
  onError: PropTypes.func,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  canFlag: PropTypes.bool,
  onFlag: PropTypes.func,
  submitted: PropTypes.bool,
  customButtonEvent: PropTypes.func,
}

TreeSubmissionStemsField.defaultProps = {
  required: false,
  disabled: false,
  canFlag: false,
  submitted: false,
}

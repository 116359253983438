import React, { useEffect, useRef, useState, useMemo } from "react"
import { useParams } from "react-router-dom"

import BaseLayout from "components/BaseLayout"
import { MultiLangForm } from "components/Common/Form"
import { STATIC_PAGES_DISPLAY, STATIC_PAGES_URL_TO_CONSTANT } from "."
import { toast } from "helpers/cms_helper"
import { getStaticPage, editStaticPages } from "helpers/backend_helper"

const StaticPagesEdit = () => {
  const { page } = useParams()
  const [content, setContent] = useState({})
  const formRef = useRef(null)

  const pageName = useMemo(() => {
    return STATIC_PAGES_URL_TO_CONSTANT[page]
  }, [page])

  const fields = [
    {
      label: "Content",
      name: "content",
      type: "ckeditor",
    },
  ]

  async function handleEditSubmit(values) {
    // console.log(values)

    await editStaticPages(pageName, {
      content: values.content,
    })
    toast("Updated")
  }

  useEffect(() => {
    getStaticPage(pageName).then((res) => {
      setContent(res)
    })
  }, [pageName])

  return (
    <BaseLayout title={`${STATIC_PAGES_DISPLAY[pageName]}`}>
      <MultiLangForm
        ref={formRef}
        name="dynamic-content-edit"
        mode="edit"
        fields={fields}
        data={content}
        onSubmit={handleEditSubmit}
      />
    </BaseLayout>
  )
}

export default StaticPagesEdit

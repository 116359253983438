import React, { useEffect, useMemo, useRef, useState } from "react"
import { useSelector } from "react-redux"
import PropTypes from "prop-types"
import { withRouter, Link } from "react-router-dom"
import {
  Row,
  Col,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEllipsisVertical } from "@fortawesome/free-solid-svg-icons"
import _ from "lodash"

// MetisMenu
import MetisMenu from "metismenujs"

import { ADMIN_PERMISSIONS, ADMIN_ROLE_SUPER } from "pages/AdminManagement"

import {
  getBadge,
  getSidebarStatus,
  setScrollOffset,
  setSidebarStatus,
} from "helpers/localstorage/sidebar"
import {
  isEmpty,
  setSidebarBadge,
  scrollSidebar,
  isLocalhost,
} from "helpers/cms_helper"

// the library dont have method to show all, and it cannot show all menu in parallel (blocked by isTransitioning)
MetisMenu.prototype.initShow = function () {
  const ul = this.element
  const subMenus = ul.querySelectorAll(this.config.subMenu)
  for (const subMenu of subMenus) {
    const li = subMenu.closest(this.config.parentTrigger)
    const menuIsOpen = li.getAttribute("data-menu-open") === "true"

    const a = li?.querySelector(this.config.triggerElement)
    a?.setAttribute("aria-expanded", menuIsOpen)

    if (menuIsOpen) {
      li?.classList.remove("mm-collapsed")
      li?.classList.add("mm-active")
      subMenu.classList.remove("mm-collapse")
      subMenu.classList.add("mm-show")
    } else {
      li?.classList.add("mm-collapsed")
    }
  }
}

const SidebarContent = (props) => {
  const [profileDropdownOpen, setProfileDropdownOpen] = useState(false)
  const [badges, setBadges] = useState({})
  const [me, setMe] = useState({})
  const [scroll, setScroll] = useState(0)
  const scrollRef = useRef(null)
  const { location } = props
  const paths = location.pathname.split("/")
  const currentPaths = [`/${paths[1]}`]
  if (paths.length > 2) currentPaths.push(location.pathname)
  const [sidebarStatus] = useState(getSidebarStatus())
  const profile = useSelector((state) => state?.Profile?.me)

  const links = [
    // Admin Management
    {
      group: "Admin Management",
      display: "Admin",
      link: "/admins",
      permission: ADMIN_PERMISSIONS.adminManagement,
    },
    // User
    {
      group: "Users",
      display: "Users",
      link: "/users",
      permission: ADMIN_PERMISSIONS.user,
    },
    {
      group: "Tree List",
      display: "Tree Species",
      link: "/tree-species-listings",
      permission: ADMIN_PERMISSIONS.treeSpecies,
    },
    // Tree Listings
    {
      group: "Tree Submission",
      display: "All Records",
      link: "/tree-listings/all-records",
      permission: ADMIN_PERMISSIONS.tree,
    },
    {
      group: "Tree Submission",
      display: "Pending Records",
      link: "/tree-listings/pending-records",
      permission: ADMIN_PERMISSIONS.tree,
    },
    {
      group: "Tree Submission",
      display: "Verified",
      link: "/tree-listings/verified",
      permission: ADMIN_PERMISSIONS.tree,
    },
    // Static Page
    // {
    //   group: "Static Pages",
    //   display: "Static Pages",
    //   link: "/static-pages",
    //   permission: ADMIN_PERMISSIONS.staticPage,
    // },
    {
      group: "Static Pages",
      display: "About MyTree",
      link: "/static-pages/about-mytree",
      permission: ADMIN_PERMISSIONS.staticPage,
    },
    {
      group: "Static Pages",
      display: "Tree Survey Protocol",
      link: "/static-pages/tree-survey-protocol",
      permission: ADMIN_PERMISSIONS.staticPage,
    },
    {
      group: "Static Pages",
      display: "FAQ",
      link: "/static-pages/faq",
      permission: ADMIN_PERMISSIONS.staticPage,
    },
    {
      group: "Static Pages",
      display: "Terms & Conditions",
      link: "/static-pages/terms-and-conditions",
      permission: ADMIN_PERMISSIONS.staticPage,
    },
    {
      group: "Static Pages",
      display: "Privacy Policy",
      link: "/static-pages/privacy-policy",
      permission: ADMIN_PERMISSIONS.staticPage,
    },
    {
      group: "Report Issue",
      display: "Report Issue listing",
      link: "/tree-issue",
      permission: ADMIN_PERMISSIONS.treeIssue,
    },
    {
      group: "System management",
      display: "App version management",
      link: "/app-setting",
      permission: ADMIN_PERMISSIONS.appSetting,
    },
  ]

  // define badge name for links with hasBadge = true
  const badgeNames = {}

  const groupedLinks = _.groupBy(links, (link) => link.group)
  const menus = useMemo(() => {
    const results = []

    for (const key in groupedLinks) {
      const menuIsActive =
        _.findIndex(
          groupedLinks[key],
          (v) => currentPaths.indexOf(v.link) !== -1
        ) !== -1
      if (menuIsActive) setSidebarStatus(key, true)
      const menuIsOpen = isEmpty(sidebarStatus[key])
        ? true // default open
        : menuIsActive || sidebarStatus[key]
      let menuIsOpenTmp = menuIsOpen

      const visibleLinks = groupedLinks[key].filter(
        (link) =>
          !link.permission || link.permission.indexOf(me?.accountType) !== -1
      )

      if (visibleLinks && visibleLinks.length > 0)
        results.push(
          <li key={key} className="mb-3" data-menu-open={menuIsOpen}>
            <a
              className="sidebar-group-title has-arrow pb-2"
              onClick={() => {
                menuIsOpenTmp = !menuIsOpenTmp
                setSidebarStatus(key, menuIsOpenTmp)
              }}
            >
              <span className="">{key}</span>
            </a>
            <ul className="sub-menu" aria-expanded="false">
              {visibleLinks.map((v) => (
                <li
                  key={v.link}
                  className={
                    currentPaths.indexOf(v.link) !== -1
                      ? "active-sidebar-link"
                      : ""
                  }
                >
                  <Link to={v.link} className="py-2">
                    <span
                      id={`sidebar-badge-${badgeNames[v.link]}`}
                      className="badge badge-pill badge-warning float-right"
                      style={{
                        visibility: v.hasBadge ? "hidden" : "visible",
                      }}
                    >
                      {_.get(badges, `${badgeNames[v.link]}.value`)}
                    </span>
                    <span>{v.display}</span>
                  </Link>
                </li>
              ))}
            </ul>
          </li>
        )
    }
    return results
  }, [sidebarStatus, me, location.pathname])

  function openProfileDropdown() {
    setProfileDropdownOpen(!profileDropdownOpen)
  }

  // Use ComponentDidMount and ComponentDidUpdate method symultaniously
  const initMenu = async () => {
    const metisMenu = new MetisMenu("#side-menu", {
      toggle: false,
    })
    metisMenu.initShow()
  }

  useEffect(() => {
    if (profile) setMe(profile)

    const scrollMenu = (document.getElementsByClassName(
      "simplebar-content-wrapper"
    ) || [null])[0]
    if (scrollMenu)
      scrollMenu.addEventListener("scroll", handleScroll, { passive: true })

    return () => {
      if (scrollMenu) scrollMenu.removeEventListener("scroll", handleScroll)
      setScrollOffset(scrollRef.current)
    }
  }, [])

  useEffect(() => {
    // setup a Ref for scroll to make it accessible during component unmount
    scrollRef.current = scroll
  }, [scroll])

  useEffect(() => {
    if (me?.accountType) {
      scrollSidebar()

      initMenu().then(() => {
        initBadges()
      })
    }
  }, [me])

  useEffect(() => {
    scrollSidebar()
  }, [menus])

  useEffect(() => {
    if (profile) setMe(profile)
  }, [profile])

  const handleScroll = (e) => {
    setScroll(e.srcElement.scrollTop)
  }

  const initBadges = () => {
    for (const key in badgeNames) {
      const link = _.find(links, { link: key })
      if (
        !link.permission ||
        link.permission?.indexOf(me?.accountType !== -1)
      ) {
        const badgeName = badgeNames[key]
        const badge = getBadge(badgeName)
        if (
          isEmpty(badge) ||
          _.isEmpty(badge) ||
          new Date().getTime() - badge.timestamp > 60 * 60 * 1000 // expire after 1 hour
        ) {
          fetchBadge(badgeName)
            .then((res) => {
              initBadge(badgeName, res.numResults)
            })
            .catch(() => {
              // local env default 0 when api error to avoid keep fetching
              if (isLocalhost) initBadge(badgeName, 0)
            })
        } else {
          initBadge(badgeName, badge.value, false)
        }
      }
    }
  }

  async function fetchBadge(badgeName) {
    switch (badgeName) {
      default:
        return
    }
  }

  const initBadge = (badgeName, value, storage = true) => {
    setBadges((prevState) => ({
      ...prevState,
      [badgeName]: value,
    }))
    setSidebarBadge(badgeName, value, storage)
  }

  return (
    <React.Fragment>
      <div id="sidebar-menu" className="no-select">
        <ul className="metismenu list-unstyled mt-3" id="side-menu">
          <li
            className={
              "mb-3" +
              (currentPaths.indexOf("/profile") !== -1
                ? " active-sidebar-link"
                : "")
            }
          >
            <Row className="w-100">
              <Col>
                <Link to="/profile" className="py-2">
                  <p className="font-weight-bold mb-1">
                    {me?.displayName ? me.displayName : <span>&nbsp;</span>}
                  </p>
                  <p id="sidebar-profile-role" className="m-0">
                    {me?.accountType && me?.accountType === ADMIN_ROLE_SUPER
                      ? "Admin"
                      : _.capitalize(me?.accountType?.toLowerCase())}
                  </p>
                </Link>
              </Col>
              <Col style={{ maxWidth: "fit-content", paddingRight: "0.5rem" }}>
                <Dropdown
                  direction="left"
                  isOpen={profileDropdownOpen}
                  toggle={openProfileDropdown}
                  style={{ marginLeft: "-5px" }}
                >
                  <DropdownToggle className="text-button">
                    <FontAwesomeIcon icon={faEllipsisVertical} />
                  </DropdownToggle>
                  <DropdownMenu
                    className="profile-dropdown-menu rounded-0"
                    style={{ minWidth: 0 }}
                  >
                    <DropdownItem className="profile-dropdown-item">
                      <Link to="/logout" className="py-3 px-4">
                        Logout
                      </Link>
                    </DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              </Col>
            </Row>
          </li>

          {menus.map((menu) => menu)}
        </ul>
      </div>
    </React.Fragment>
  )
}

SidebarContent.propTypes = {
  location: PropTypes.object,
}

export default withRouter(SidebarContent)

import React from "react"
import PropTypes from "prop-types"

const CustomCheckbox = props => {
  const { label, value, onChange, disabled, checkboxName } = props

  return (
    <label
      className="custom-checkbox-container no-select"
      style={{ width: "fit-content" }}
    >
      <span>{label}</span>
      <input
        type="checkbox"
        checked={value || false}
        onChange={e => {
          if (!disabled) onChange(e.target.checked)
        }}
        disabled={disabled}
      />
      <span
        className={
          "custom-checkbox-checkmark " + (checkboxName ? checkboxName : "")
        }
      ></span>
    </label>
  )
}

CustomCheckbox.propTypes = {
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  checkboxName: PropTypes.string,
}

CustomCheckbox.defaultProps = {
  disabled: false,
}

export default CustomCheckbox

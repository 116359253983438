import React, { useRef } from "react"
import { Link } from "react-router-dom"

import BaseLayout from "components/BaseLayout"
import { ApiTable } from "components/Common/Table"
import CustomButton from "components/Common/CustomButton"

import { getFaqList, editFaq, deleteFaq } from "helpers/backend_helper"
import { toast, confirm } from "helpers/cms_helper"

const Faq = () => {
  const tableRef = useRef(null)

  const columns = [
    {
      title: "FAQ No.",
      value: "ordering",
    },
    {
      title: "Question",
      value: (row) => row?.question?.EN,
    },
    {
      title: "Last Updated By",
      value: (row) => row?.updatedBy?.displayName,
    },
    { title: "Last Updated on", value: "updatedAt", format: "date" },
    {
      title: "Published",
      value: "published",
      format: "toggle",
      onToggle: handleTogglePublished,
    },
  ]

  async function fetch(params) {
    return getFaqList({ ...params })
  }

  async function handleTogglePublished(row, val) {
    await editFaq(row.id, { published: val })
    toast(val ? "Published" : "Unpublished")
    tableRef.current.refetch()
  }

  async function handleDelete(id) {
    await deleteFaq(id)
    toast("Deleted")
    tableRef.current.refetch()
  }

  return (
    <BaseLayout title="Faq Listing">
      <ApiTable
        name="faq"
        fetch={fetch}
        columns={columns}
        ref={tableRef}
        headerButtons={
          <Link to="/static-pages/faq/create">
            <CustomButton className="btn-dark">+ Question</CustomButton>
          </Link>
        }
        rowButtons={(row) => (
          <>
            <Link to={`/static-pages/faq/${row.id}`}>
              <CustomButton className="btn-outline-dark">Edit</CustomButton>
            </Link>
            <CustomButton
              className="btn-outline-danger"
              onClick={() => {
                confirm(
                  `Confirm delete "${row?.question?.EN || row?.ordering}"?`,
                  () => handleDelete(row.id),
                  "btn-danger"
                )
              }}
            >
              Delete
            </CustomButton>
          </>
        )}
      />
    </BaseLayout>
  )
}

export default Faq

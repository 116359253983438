// @flow
import {
  SET_MEMBERSHIP_PLANS,
  } from "./actionTypes"
  
  const INIT_STATE = {
    membershipPlans: [],
  }
  
  const Api = (state = INIT_STATE, action) => {
    switch (action.type) {
      case SET_MEMBERSHIP_PLANS:
        return {
          ...state,
          membershipPlans: action.payload,
        }
  
      default:
        return state
    }
  }
  
  export default Api
  
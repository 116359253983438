import { UPDATE_TREE_LIST } from "./actionTypes"

function createTreeIdRef(list = []) {
  const obj = {}

  list?.map((tree) => {
    if (tree?.id) {
      obj[tree.id] = tree
    }
  })
  return obj
}

function createTreeOptions(list = []) {
  const options = []

  list.map((tree) => {
    if (tree?.scientificName)
      options.push({
        label: tree?.scientificName,
        value: tree?.scientificName,
      })

    if (tree?.commonNames?.length > 0) {
      tree?.commonNames?.map((name) => {
        options.push({
          label: name,
          value: name,
        })
      })
    }

    if (tree?.scientificNames?.length > 0) {
      tree?.scientificNames?.map((name) => {
        options.push({
          label: name,
          value: name,
        })
      })
    }

    if (tree?.chineseNames?.length > 0) {
      tree?.chineseNames?.map((name) => {
        options.push({
          label: name,
          value: name,
        })
      })
    }
  })

  options?.sort((a, b) => {
    const nameA = a?.label?.toUpperCase()
    const nameB = b?.label?.toUpperCase()

    if (nameA < nameB) {
      return -1
    }
    if (nameA > nameB) {
      return 1
    }
    return 0
  })

  return options
}

const initialState = {
  list: [],
  treeOptions: [],
  treeIdRef: {},
}

const tree = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_TREE_LIST:
      state = {
        list: action?.payload,
        treeOptions: createTreeOptions(action?.payload),
        treeIdRef: createTreeIdRef(action?.payload),
      }
      break
  }
  return state
}

export default tree

import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"

// import FlagButton from "components/Common/FlagButton"
import { FieldLabel, TextField } from "components/Common/Form"
import CustomButton from "components/Common/CustomButton"

import { isEmpty } from "helpers/cms_helper"

const TreeSubmissionStemNoField = (props) => {
  const {
    value,
    onChange,
    customButtonEvent,
    required,
    onError,
    // canFlag,
    // onFlag,
    submitted,
    disabled,
  } = props

  const [val, setVal] = useState(0)
  const [error, setError] = useState({})

  const handleChange = (val) => {
    onChange(val)
  }

  const handleError = (entity, errorMsg) => {
    setError((prevState) => ({ ...prevState, [entity]: errorMsg }))
  }

  useEffect(() => {
    setVal(value)
  }, [value])

  useEffect(() => {
    for (const key in error) {
      if (!isEmpty(error[key])) return onError(true)
    }
    return onError(false)
  }, [error])

  return (
    <div key={`tree-submission-stem-no`} className="mb-3">
      <div className="row align-items-end">
        <div className="col">
          <div>
            <FieldLabel
              required={required}
              name="stemNo"
              label="No. of stems"
              hasError={submitted && !!error["stemNo"]}
            />
            <TextField
              name="stemNo"
              fieldType="customValue"
              type="number"
              disabled={true}
              value={val}
              onChange={(v) => {
                setVal(v)
                handleChange(v)
              }}
              onError={(errorMsg) => handleError("stemNo", errorMsg)}
            ></TextField>
          </div>
        </div>
        <div className="col pt-2" style={{ maxWidth: "fit-content" }}>
          <CustomButton
            onClick={customButtonEvent}
            className="btn-outline-dark mr-3"
            disabled={disabled}
          >
            Insert more stems
          </CustomButton>
        </div>
      </div>
    </div>
  )
}

export default TreeSubmissionStemNoField

TreeSubmissionStemNoField.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  onError: PropTypes.func,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  canFlag: PropTypes.bool,
  onFlag: PropTypes.func,
  submitted: PropTypes.bool,
  customButtonEvent: PropTypes.func,
}

TreeSubmissionStemNoField.defaultProps = {
  required: false,
  disabled: false,
  canFlag: false,
  submitted: false,
}

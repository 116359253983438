import React from "react"
import PropTypes from "prop-types"
import { Route, Redirect } from "react-router-dom"
import { getUser } from "helpers/localstorage/auth"
import _ from "lodash"

// console.log(getUser())

const Authmiddleware = ({
  component: Component,
  layout: Layout,
  isAuthProtected,
  title,
  permission,
  ...rest
}) => {
  const me = getUser()

  return (
    <Route
      {...rest}
      render={(props) => {
        let pageTitle = "MyTree Admin Portal"
        if (_.isString(title)) pageTitle = title
        else if (typeof title === "function")
          pageTitle = title(props.match.params)

        if (isAuthProtected && !me) {
          return (
            <Redirect
              to={{ pathname: "/login", state: { from: props.location } }}
            />
          )
        }

        if (permission && permission.indexOf(me?.accountType) === -1)
          return <Redirect to="/403" />
        else
          return (
            <Layout title={pageTitle}>
              <Component {...props} />
            </Layout>
          )
      }}
    />
  )
}

Authmiddleware.propTypes = {
  isAuthProtected: PropTypes.bool,
  component: PropTypes.any,
  location: PropTypes.object,
  layout: PropTypes.any,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  permission: PropTypes.arrayOf(PropTypes.string),
  match: PropTypes.any,
}

export default Authmiddleware

import React, { useCallback, useEffect } from "react"
import PropTypes from "prop-types"
import { Field } from "redux-form"

import CustomCheckbox from "../CustomCheckbox"

import { isEmpty } from "helpers/cms_helper"

const CheckboxInput = props => {
  const { name, options, value, onChange, required, disabled, onError } = props

  useEffect(() => {
    if (!isEmpty(onError)) validate(value)
  }, [value])

  const validate = val => {
    if (disabled) return onError(null)

    if (required && (isEmpty(val) || val.length === 0))
      return onError("Required")

    return onError(null)
  }

  const handleInput = (option, state) => {
    let checked = _.cloneDeep(value || [])
    if (state === true && checked.indexOf(option) === -1) checked.push(option)
    else if (state === false && checked.indexOf(option) !== -1)
      checked = _.without(checked, option)
    onChange(checked)
  }

  return (
    <div className="row">
      {options.map(option => (
        <div
          key={`${option}-checkbox`}
          className="col"
          style={{ maxWidth: "fit-content" }}
        >
          <CustomCheckbox
            label={option}
            value={value.indexOf(option) !== -1}
            onChange={state => {
              handleInput(option, state)
            }}
            disabled={disabled}
            checkboxName={`checkbox-${name}-${option}`}
          />
        </div>
      ))}
    </div>
  )
}

const CheckboxField = props => {
  // for type: checkbox
  const { fieldType, name, value, onChange, onError, hasError } = props

  const fieldProps = _.pick(props, [
    "fieldType",
    "name",
    "options",
    "className",
    "style",
    "required",
    "disabled",
  ])
  fieldProps.className =
    (fieldProps.className || "") +
    " form-control rounded-0" +
    (hasError ? " border-danger" : "")

  const checkboxes = document.querySelectorAll(
    `*[class*=\"checkbox-${name}-\"]`
  )
  for (const checkbox of checkboxes) {
    if (hasError) checkbox.style.border = "1.5px solid #f46a6a"
    else checkbox.style.border = "1.5px solid #343a40"
  }

  const renderCheckboxInput = useCallback(
    field => (
      <CheckboxInput
        {...fieldProps}
        value={field.input.value}
        onChange={field.input.onChange}
        onError={onError}
      />
    ),
    [props.disabled] // add dynamic props here
  )

  if (fieldType === "form" || fieldType === "filter")
    return <Field component={renderCheckboxInput} {...fieldProps} />
  else
    return (
      <CheckboxInput
        {...fieldProps}
        value={value}
        onChange={onChange}
        onError={onError}
      />
    )
}

CheckboxField.propTypes = {
  fieldType: PropTypes.string,
  type: PropTypes.string,
  name: PropTypes.string.isRequired,
  options: PropTypes.array,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  style: PropTypes.object,
  value: PropTypes.array,
  onChange: PropTypes.func,
  onError: PropTypes.func,
  hasError: PropTypes.bool,
}

CheckboxField.defaultProps = {
  fieldType: "form",
  required: false,
  disabled: false,
  hasError: false,
}

export default CheckboxField

import React, { useCallback, useEffect, useState } from "react"
import PropTypes from "prop-types"
import { Field } from "redux-form"
import { Button, Input } from "reactstrap"

import { isEmpty } from "helpers/cms_helper"

const TagsInput = props => {
  const { mode, value, onChange, required, disabled, style, onError } = props
  const [tags, setTags] = useState([])
  const [tagInput, setTagInput] = useState("")

  useEffect(() => {
    if (!isEmpty(onError)) validate(value)
    if (value) setTags(value)
    else setTags([])
  }, [value])

  const validate = val => {
    if (disabled) return onError(null)

    if (required && (isEmpty(val) || val.length === 0))
      return onError("Required")
    return onError(null)
  }

  const handleInput = val => {
    setTags(val)
    onChange(val)
  }

  return (
    <div>
      {tags && tags.length > 0 ? (
        <div>
          {tags.map((tag, idx) => (
            <div key={`${tag}-${idx}`} className="tag mr-2 mb-2">
              {tag}
              {!disabled && mode !== "view" && (
                <>
                  <span>&nbsp;</span>
                  <Button
                    close
                    onClick={() => handleInput(_.without(tags, tag))}
                    disabled={disabled || mode === "view"}
                  ></Button>
                </>
              )}
            </div>
          ))}
        </div>
      ) : mode === "view" ? (
        "N/A"
      ) : null}
      {mode !== "view" && (
        <Input
          className="tags-input form-control rounded-0"
          style={style}
          placeholder="Press Tab to add"
          value={tagInput}
          disabled={disabled}
          onChange={e => setTagInput(e.target.value)}
          onKeyDown={e => {
            if (e.key === "Tab") {
              e.preventDefault()
              const tagsTmp = _.cloneDeep(tags)
              tagsTmp.push(tagInput.trim())
              handleInput(tagsTmp)
              setTagInput("")
            }
          }}
        />
      )}
    </div>
  )
}

const TagsField = props => {
  // for type: tags
  const { fieldType, value, onChange, onError, hasError } = props

  const fieldProps = _.pick(props, [
    "fieldType",
    "mode",
    "name",
    "className",
    "style",
    "required",
    "disabled",
    "placeholder",
  ])
  fieldProps.className =
    (fieldProps.className || "") +
    " form-control rounded-0" +
    (hasError ? " border-danger" : "")
  fieldProps.style = {
    maxWidth: fieldType !== "filter" ? "540px" : null,
    ...fieldProps.style,
  }

  const renderTagsInput = useCallback(
    field => (
      <TagsInput
        {...fieldProps}
        value={field.input.value}
        onChange={field.input.onChange}
        onError={onError}
      />
    ),
    [props.disabled] // add dynamic props here
  )

  if (fieldType === "form")
    return (
      <div className={"field-wrapper" + (hasError ? " has-error" : "")}>
        <Field component={renderTagsInput} {...fieldProps} />
      </div>
    )
  else if (fieldType === "filter")
    return <Field component={renderTagsInput} {...fieldProps} />
  else
    return (
      <TagsInput
        {...fieldProps}
        value={value}
        onChange={onChange}
        onError={onError}
      />
    )
}

TagsField.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.object,
  fieldType: PropTypes.string,
  mode: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.array,
  onError: PropTypes.func,
  hasError: PropTypes.bool,
}

TagsField.defaultProps = {
  fieldType: "form",
  required: false,
  disabled: false,
  hasError: false,
}

export default TagsField

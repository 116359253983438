import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { Link } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faBars } from "@fortawesome/free-solid-svg-icons"

// Redux Store
import {
  showRightSidebarAction,
  toggleLeftmenu,
  changeSidebarType,
} from "../../store/actions"
import { isEmpty, scrollSidebar } from "helpers/cms_helper"

const Header = (props) => {
  // const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)
  const logo = ""

  const handleToggleMenu = () => {
    const menu = (document.getElementsByClassName("vertical-menu") || [null])[0]
    if (menu) {
      const menuOpened =
        !isEmpty(menu.style.display) && menu.style.display !== "none"
      if (menuOpened) menu.style.display = "none"
      else menu.style.display = "block"
    }
    scrollSidebar()
  }

  return (
    <React.Fragment>
      <header id="page-topbar">
        <div className="navbar-header">
          <div className="d-flex">
            <div className="navbar-brand-box no-select">
              <div className="logo logo-dark">
                <span
                  className="logo-sm"
                  onClick={handleToggleMenu}
                  style={{ cursor: "pointer" }}
                >
                  <FontAwesomeIcon
                    icon={faBars}
                    style={{ color: "black", fontSize: "1.25rem" }}
                  />
                </span>
                <Link to="/">
                  {logo ? (
                    <img src={logo} alt="logo" style={{ height: "40px" }} />
                  ) : null}
                </Link>
              </div>

              <div className="logo logo-light no-select">
                <span
                  className="logo-sm"
                  onClick={handleToggleMenu}
                  style={{ cursor: "pointer" }}
                >
                  <FontAwesomeIcon
                    icon={faBars}
                    style={{ color: "white", fontSize: "1.25rem" }}
                  />
                </span>
                <Link to="/">
                  {logo ? (
                    <img src={logo} alt="logo" style={{ height: "40px" }} />
                  ) : null}
                </Link>
              </div>
            </div>

            <div className="d-lg-block my-auto">
              <h3 className="header-title mb-0 text-uppercase font-weight-bold">
                {props.title}
              </h3>
            </div>
          </div>
          {props.children}
        </div>
      </header>
    </React.Fragment>
  )
}

Header.propTypes = {
  changeSidebarType: PropTypes.func,
  leftMenu: PropTypes.any,
  leftSideBarType: PropTypes.any,
  showRightSidebar: PropTypes.any,
  showRightSidebarAction: PropTypes.func,
  title: PropTypes.string,
  toggleLeftmenu: PropTypes.func,
  children: PropTypes.node,
}

const mapStatetoProps = (state) => {
  const { layoutType, showRightSidebar, leftMenu, leftSideBarType } =
    state.Layout
  return { layoutType, showRightSidebar, leftMenu, leftSideBarType }
}

export default connect(mapStatetoProps, {
  showRightSidebarAction,
  toggleLeftmenu,
  changeSidebarType,
})(Header)

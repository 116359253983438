import React from "react"
import PropTypes from "prop-types"
import { Button } from "reactstrap"

import FlagButton from "components/Common/FlagButton"

import { getResourceUrl, isPrivate } from "helpers/resource_helper"
import { isEmpty } from "helpers/cms_helper"

const FilePreview = props => {
  const { file, disabled, onClear, canFlag, onFlag, flagged } = props

  if (isEmpty(file)) return null

  const getShortenedUrl = url => {
    if (isEmpty(url)) return ""
    if (url.length <= 40) return url
    return (
      url.substring(0, url.indexOf(".oss") !== -1 ? url.indexOf(".oss") : 30) +
      "..." +
      url.substr(url.length - 10)
    )
  }

  async function handleViewFile(url) {
    const toOpen = await getResourceUrl(url)
    window.open(toOpen, "_blank")
  }

  return (
    <>
      <div className="tag mr-2">
        {canFlag && (
          <FlagButton
            value={flagged}
            onClick={onFlag}
            style={{ paddingRight: "7px" }}
          />
        )}

        <a target="_blank" onClick={() => handleViewFile(file.url)}>
          <span className="text-primary">
            {isPrivate(file.url) ? "View File" : getShortenedUrl(file.url)}
          </span>
        </a>

        {!disabled && (
          <>
            <span>&nbsp;</span>
            <Button
              close
              onClick={() => onClear(file.url)}
              disabled={disabled}
            />
          </>
        )}
      </div>
    </>
  )
}

FilePreview.propTypes = {
  disabled: PropTypes.bool,
  file: PropTypes.object,
  onClear: PropTypes.func,
  className: PropTypes.string,
  canFlag: PropTypes.bool,
  onFlag: PropTypes.func,
  flagged: PropTypes.bool,
}

FilePreview.defaultProps = {
  disabled: false,
  canFlag: false,
  flagged: false,
}

export default FilePreview

import { Admin, AdminList } from "./Admin"

export const ADMIN_ROLE_EXPERT = "EXPERT"
export const ADMIN_ROLE_SUPER = "SUPER"
export const ADMIN_ROLES = [ADMIN_ROLE_SUPER, ADMIN_ROLE_EXPERT]

export const ADMIN_PERMISSIONS = {
  dashboard: [ADMIN_ROLE_SUPER, ADMIN_ROLE_EXPERT],
  adminManagement: [ADMIN_ROLE_SUPER],
  user: [ADMIN_ROLE_SUPER],
  tree: [ADMIN_ROLE_SUPER, ADMIN_ROLE_EXPERT],
  treeSpecies: [ADMIN_ROLE_SUPER, ADMIN_ROLE_EXPERT],
  staticPage: [ADMIN_ROLE_SUPER],
  treeIssue: [ADMIN_ROLE_SUPER],
  appSetting: [ADMIN_ROLE_SUPER],
}

export { Admin, AdminList }

const STORAGE_KEY = 'authUser'

function getUser() {
    return JSON.parse(localStorage.getItem(STORAGE_KEY))   
}

function getJwt() {
    const obj = getUser()
  
    if (obj && obj.token) {
        return `Bearer ${obj.token}`
    } else {
        return null
    }
}

function setUser(user) {
    localStorage.setItem(STORAGE_KEY,  JSON.stringify(user))
}

function unsetUser() {
    localStorage.removeItem(STORAGE_KEY)
}



export {
    getUser, getJwt, setUser, unsetUser
}
import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"

import FlagButton from "components/Common/FlagButton"
import { FieldLabel, ImageField } from "components/Common/Form"

import { RESOURCE_TYPES, RESOURCE_TYPES_DISPLAY } from "."
import { isEmpty } from "helpers/cms_helper"

const TreeSubmissionImagesField = (props) => {
  const {
    value,
    onChange,
    required,
    disabled,
    onError,
    canFlag,
    onFlag,
    submitted,
  } = props
  const [val, setVal] = useState({})
  const [error, setError] = useState({})

  const requiredTypes = ["WHOLE_TREE", "TREE_TRUNK", "TREE_LEAF"]

  const handleChange = (val) => {
    onChange(val)
  }

  const handleError = (fieldName, errorMsg) => {
    setError((prevState) => ({ ...prevState, [fieldName]: errorMsg }))
  }

  const handleFlag = (type, state) => {
    onFlag(state, `resources.${type}`)
    setVal((prevState) => ({
      ...prevState,
      [type]: { ...prevState[type], flagged: state },
    }))
  }

  useEffect(() => {
    if (isEmpty(value)) {
      // reset
      setVal({})
    } else {
      if (Array.isArray(value)) {
        // init data (array of objects)
        const valueObj = {}
        RESOURCE_TYPES.map((type, idx) => {
          valueObj[type] = value.length > idx ? value[idx] : null
        })
        setVal(valueObj)
        onChange(valueObj)
      } else {
        // input change (nested object)
        setVal(value)
      }
    }
  }, [value])

  useEffect(() => {
    for (const key in error) {
      if (!isEmpty(error[key])) return onError(true)
    }
    return onError(false)
  }, [error])

  return (
    <div className="row">
      {RESOURCE_TYPES.map((type) => {
        const key = `tree-image-${type}`

        return (
          <div
            key={key}
            className="col col-xl-4 col-lg-4 col-md-4 col-sm-6 col-6 mb-3"
            style={{ minWidth: "100px" }}
          >
            <label className="mb-0">
              <FieldLabel
                name={key}
                label={RESOURCE_TYPES_DISPLAY[type]}
                required={required && requiredTypes.indexOf(type) !== -1}
                hasError={submitted && !!error[type]}
              />
              &nbsp;
              {canFlag && (
                <FlagButton
                  value={val[type]?.flagged}
                  onClick={(state) => handleFlag(type, state)}
                />
              )}
            </label>
            <ImageField
              fieldType="custom"
              name={key}
              size="sm"
              includeFilename={true}
              required={required && requiredTypes.indexOf(type) !== -1}
              disabled={disabled}
              value={val[type]?.url}
              onChange={(v) => {
                handleChange({
                  ...val,
                  [type]: {
                    flagged: val[type]?.flagged ? val[type].flagged : false,
                    url: v?.imageDataUrl || null,
                    fileType: "IMAGE",
                    filename: v?.filename,
                    signedUrl: v?.signedUrl,
                  },
                })
              }}
              onError={(errorMsg) => handleError(type, errorMsg)}
              hasError={submitted && !!error[type]}
            />
            {submitted && error[type] && (
              <span className="text-danger">{error[type]}</span>
            )}
          </div>
        )
      })}
    </div>
  )
}

TreeSubmissionImagesField.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.array,
  ]),
  onChange: PropTypes.func,
  onError: PropTypes.func,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  canFlag: PropTypes.bool,
  onFlag: PropTypes.func,
  submitted: PropTypes.bool,
}

TreeSubmissionImagesField.defaultProps = {
  required: false,
  disabled: false,
  canFlag: false,
  submitted: false,
}

export default TreeSubmissionImagesField

import {
  PROFILE_ERROR,
  PROFILE_SUCCESS,
  EDIT_PROFILE,
  UPDATE_PROFILE,
} from "./actionTypes"

export const editProfile = (user) => {
  return {
    type: EDIT_PROFILE,
    payload: { user },
  }
}

export const profileSuccess = (msg) => {
  return {
    type: PROFILE_SUCCESS,
    payload: msg,
  }
}

export const profileError = (error) => {
  return {
    type: PROFILE_ERROR,
    payload: error,
  }
}

export const updateProfile = (profile) => {
  return {
    type: UPDATE_PROFILE,
    payload: profile,
  }
}

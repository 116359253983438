import React, { useEffect, useRef, useState } from "react"
import { useParams, useHistory } from "react-router-dom"

import BaseLayout from "components/BaseLayout"
import CustomButton from "components/Common/CustomButton"

import { ApiForm } from "components/Common/Form"
import {
  TreeIssueMediaField,
  ReportTreeData,
  ISSUE_STATUSES,
  ISSUE_CATEGORY_OPTIONS,
  TREE_CONDITION_DISPLAY,
} from "."

import { getTreeIssue, editTreeIssue } from "helpers/backend_helper"
import { toast, dateToStr } from "helpers/cms_helper"
import { getUser } from "helpers/localstorage/auth"
import { ADMIN_ROLE_SUPER } from "pages/AdminManagement"

import _ from "lodash"

const TreeIssueDetail = () => {
  const [detail, setDetail] = useState({})
  const [cloneData, setCloneData] = useState({})
  const [category, setCategory] = useState("")
  const { id } = useParams()
  const formRef = useRef(null)
  const me = getUser()
  const history = useHistory()
  const isSuperAdmin = me?.accountType === ADMIN_ROLE_SUPER

  const fields = [
    {
      label: "Issue ID",
      name: "displayId",
      disabled: true,
      required: true,
    },
    {
      label: "Username",
      name: "user.username",
      disabled: true,
      required: true,
    },
    {
      label: "Issue Report Date",
      name: "createdAt",
      type: "datetime",
      disabled: true,
      required: true,
    },
    {
      label: "Status",
      name: "status",
      type: "select",
      options: ISSUE_STATUSES,
      required: true,
    },
    {
      label: "Related tree listing",
      name: "treeId",
      disabled: true,
    },
    {
      label: "Issue Category",
      name: "category",
      type: "select",
      options: ISSUE_CATEGORY_OPTIONS,
      onChangeCustom: (value) => {
        setCategory(value)
      },
      disabled: !isSuperAdmin,
      required: true,
    },
    {
      label: "Issue reported",
      name: "issue.other",
      disabled: true,
    },
    {
      name: "issue",
      type: "component",
      component: ReportTreeData,
      disabled: true,
      hidden: category !== "TREE_DATA",
    },
    {
      label: "Tree Condition",
      name: "treeCondition",
      type: "checkbox",
      options: [
        "Cut Down",
        "Pests And Diseases",
        "Typhoon",
        "Hill Fire",
        "Other",
      ],
      disabled: true,
      hidden: category !== "TREE_CONDITION",
    },
    {
      label: "Photo/Video",
      name: "media",
      type: "component",
      component: TreeIssueMediaField,
      disabled: true,
    },
    {
      label: "Remarks",
      name: "remark",
      type: "textarea",
    },
    {
      label: "Remarks History",
      name: "remarksHistory",
      type: "textarea",
      disabled: true,
    },
  ]

  async function fetchTree(id) {
    const res = await getTreeIssue(id)
    setDetail(res)
  }

  async function handleSubmit(values) {
    const params = _.pick(values, ["status", "category", "remark"])

    await editTreeIssue(id, {
      ...params,
    })
    await fetchTree(id)

    toast("Updated")
  }

  useEffect(() => {
    fetchTree(id)
  }, [id])

  useEffect(() => {
    const treeCondition = []
    const remarksHistory = (detail?.remarks || [])
      .map(
        (r) =>
          `${r?.remark} [${r.admin?.displayName || ""} ${
            r.admin?.displayNamee || ""
          }${r?.createdAt ? " at " + dateToStr(r.createdAt) : ""}]`
      )
      .join("\n")

    for (const key in detail?.issue || {}) {
      if (detail?.issue[key] && TREE_CONDITION_DISPLAY[key]) {
        treeCondition.push(TREE_CONDITION_DISPLAY[key])
      }
    }

    setCloneData({
      ...detail,
      treeCondition,
      remarksHistory,
      treeId: detail?.treeListing?.displayId,
    })
  }, [detail])

  return (
    <BaseLayout title={`Report Issue #${id}`}>
      <ApiForm
        ref={formRef}
        name="tree-report-issue"
        fields={fields}
        data={cloneData}
        style={{ maxWidth: "585px" }}
        onSubmit={handleSubmit}
        titlesStyle={{ textAlign: "center" }}
        extraSubmitButtonsLeft={
          <>
            <CustomButton
              className="btn-outline-dark"
              onClick={() => {
                localStorage.setItem(
                  "locationFrom",
                  history?.location?.state?.from
                )
                history.goBack()
              }}
            >
              Back
            </CustomButton>
          </>
        }
      />
    </BaseLayout>
  )
}

export default TreeIssueDetail

import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"

// import FlagButton from "components/Common/FlagButton"
import { FieldLabel, TextField, SelectField } from "components/Common/Form"

import { isEmpty } from "helpers/cms_helper"

// Store
import { connect } from "react-redux"

const TreeSpeciesField = (props) => {
  const {
    value,
    onChange,
    required,
    disabled,
    onError,
    // canFlag,
    // onFlag,
    submitted,
    treeList,
    treeIdRef,
  } = props

  const [val, setVal] = useState([])
  const [error, setError] = useState([])
  const [treeSpeciesOptions, setTreeSpeciesOptions] = useState([])

  const handleChange = (val) => {
    onChange(val)
  }

  const handleError = (fieldName, errorMsg) => {
    setError((prevState) => ({ ...prevState, [fieldName]: errorMsg }))
  }

  useEffect(() => {
    for (const key in error) {
      if (!isEmpty(error[key])) return onError(true)
    }
    return onError(false)
  }, [error])

  useEffect(() => {
    setVal(value)
  }, [value])

  useEffect(() => {
    const options = []

    treeList.map((tree) => {
      if (tree?.scientificName)
        options.push({
          label: tree?.scientificName,
          value: tree?.id,
        })

      if (tree?.commonNames?.length > 0)
        options.push({
          label: tree?.commonNames?.join(", "),
          value: tree?.id,
        })

      if (tree?.synonyms?.length > 0)
        options.push({
          label: tree?.synonyms?.join(", "),
          value: tree?.id,
        })

      if (tree?.chineseNames?.length > 0)
        options.push({
          label: tree?.chineseNames?.join(", "),
          value: tree?.id,
        })
    })

    options?.sort((a, b) => {
      const nameA = a?.label?.toUpperCase()
      const nameB = b?.label?.toUpperCase()

      if (nameA < nameB) {
        return -1
      }
      if (nameA > nameB) {
        return 1
      }
      return 0
    })

    setTreeSpeciesOptions(options)
  }, [treeList])

  return (
    <>
      <div className="row mb-3">
        <div className="col">
          <FieldLabel
            name="treeSpeciesId"
            label="Tree Species"
            hasError={submitted && !!error[`treeSpeciesId`]}
            required={required}
          ></FieldLabel>
          <SelectField
            fieldType="customValue"
            name="treeSpeciesId"
            value={val}
            options={treeSpeciesOptions}
            onChange={(v) => {
              handleChange(v)
            }}
            disabled={disabled}
            placeholder="Species not provided"
            onError={(errorMsg) => handleError([`treeSpeciesId`], errorMsg)}
          ></SelectField>
        </div>
      </div>
      {val && (
        <>
          <div className="row mb-3">
            <div className="col">
              <FieldLabel
                name="scientificName"
                label="Tree Species (Scientific Name)"
              ></FieldLabel>
              <TextField
                name="scientificName"
                fieldType="customValue"
                value={treeIdRef[val]?.scientificName}
                disabled
              ></TextField>
            </div>
          </div>
          <div className="row mb-3">
            <div className="col">
              <FieldLabel
                name="commonName"
                label="Tree Species (Common Name)"
              ></FieldLabel>
              <TextField
                name="commonName"
                fieldType="customValue"
                value={treeIdRef[val]?.commonNames?.join(", ")}
                disabled
              ></TextField>
            </div>
          </div>
          <div className="row mb-3">
            <div className="col">
              <FieldLabel
                name="synonymName"
                label="Tree Species (Synonym)"
              ></FieldLabel>
              <TextField
                name="synonymName"
                fieldType="customValue"
                value={treeIdRef[val]?.synonyms?.join(", ")}
                disabled
              ></TextField>
            </div>
          </div>
          <div className="row mb-3">
            <div className="col">
              <FieldLabel
                name="chineseName"
                label="Tree Species (中文名)"
              ></FieldLabel>
              <TextField
                name="chineseName"
                fieldType="customValue"
                value={treeIdRef[val]?.chineseNames?.join(", ")}
                disabled
              ></TextField>
            </div>
          </div>
        </>
      )}
    </>
  )
}

const mapStatetoProps = (state) => {
  const { list, treeIdRef } = state.tree
  return { treeList: list, treeIdRef }
}

export default connect(mapStatetoProps, {})(TreeSpeciesField)

TreeSpeciesField.propTypes = {
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onChange: PropTypes.func,
  onError: PropTypes.func,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  canFlag: PropTypes.bool,
  onFlag: PropTypes.func,
  submitted: PropTypes.bool,
  treeList: PropTypes.array,
  treeIdRef: PropTypes.object,
}

TreeSpeciesField.defaultProps = {
  required: false,
  disabled: false,
  canFlag: false,
  submitted: false,
}

import _ from "lodash"
const STORAGE_KEY = "sidebar"

export function getSidebarStatus() {
  return JSON.parse(localStorage.getItem(STORAGE_KEY)) || {}
}

export function setSidebarStatus(group, state) {
  localStorage.setItem(
    STORAGE_KEY,
    JSON.stringify({ ...getSidebarStatus(), [group]: state })
  )
}

export function getBadge(name) {
  const values = JSON.parse(localStorage.getItem(STORAGE_KEY)) || {}
  return _.get(values, `badges.${name}`, {})
}

export function setBadge(name, value) {
  const values = getSidebarStatus()
  _.set(values, `badges.${name}`, {
    value: value,
    timestamp: new Date().getTime(),
  })
  localStorage.setItem(STORAGE_KEY, JSON.stringify(values))
}

export function getScrollOffset() {
  const values = JSON.parse(localStorage.getItem(STORAGE_KEY)) || {}
  return values?.scrollOffset || 0
}

export function setScrollOffset(offset) {
  const values = getSidebarStatus()
  _.set(values, "scrollOffset", offset)
  localStorage.setItem(STORAGE_KEY, JSON.stringify(values))
}

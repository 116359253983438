import axios from "axios"
import { post, del, get, put } from "./api_helper"
import { getUser } from "./localstorage/auth"
import * as url from "./url_helper"

function readAsArrayBufferAsync(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()

    reader.onload = function (event) {
      resolve(event.target.result)
    }

    reader.onerror = function (event) {
      reject(event.target.error)
    }

    reader.readAsArrayBuffer(file)
  })
}

// Gets the logged in user data from local session
const getLoggedInUser = () => {
  const user = getUser()
  if (user) return JSON.parse(user)
  return null
}

//is user is logged in
const isUserAuthenticated = () => {
  return getLoggedInUser() !== null
}

// Edit profile
const postJwtProfile = (data) => post(url.PUT_PROFILE, data)

// Register Method
const postJwtRegister = (url, data) => {
  return axios
    .post(url, data)
    .then((response) => {
      if (response.status >= 200 || response.status <= 299) return response.data
      throw response.data
    })
    .catch((err) => {
      let message
      if (err.response && err.response.status) {
        switch (err.response.status) {
          case 404:
            message = "Sorry! the page you are looking for could not be found"
            break
          case 500:
            message =
              "Sorry! something went wrong, please contact our support team"
            break
          case 401:
            message = "Invalid credentials"
            break
          default:
            message = err[1]
            break
        }
      }
      throw message
    })
}

// Login Method
const postLogin = (data) => post(url.POST_LOGIN, data)
const postJwtForgetPwd = (data) => post(url.POST_RESET_PASSWORD_EMAIL, data)
export const updateAdminPwd = (token, data) =>
  post(url.POST_RESET_PASSWORD, data, {
    headers: { Authorization: `Bearer ${token}` },
  })
export const updateMePassword = (data) => put(url.PUT_UPDATE_PASSWORD, data)
// Resource
export const getUploadUrl = (params) => {
  return post(url.GET_UPLOAD_URL, params)
}

export const getStorageUrl = async (storageUrl, file) => {
  const fileBuffer = await readAsArrayBufferAsync(file)

  return put(storageUrl, fileBuffer, {
    headers: { "Content-Type": "application/octet-stream" },
  })
}

export const getUserUploadUrl = (args) =>
  // upload to private bucket depending on user region
  // params: userId
  get(url.GET_USER_UPLOAD_URL, { params: args })
export const getSignedUrl = (fileUrl) =>
  post(url.GET_SIGNED_URL, [{ uri: fileUrl }])
export const getConvertUrl = (filename) =>
  post(url.GET_CONVERT_URL, { filename })

// Profile
export const getMe = (config) => get(url.GET_ME, config)
export const editMe = (payload) => put(url.PUT_ME, payload)
export const editMePassword = (payload) => put(url.PUT_ME_PASSWORD, payload)

// Admins
export const getAdmins = (args) => get(url.GET_ADMINS, { params: args })
export const createAdmin = (payload) => post(url.POST_ADMINS, payload)
export const editAdmin = (id, payload) => put(`${url.PUT_ADMIN}/${id}`, payload)
export const deleteAdmin = (id) => del(`${url.DELETE_ADMIN}/${id}`)

// Users
export const getUsers = (args) => get(url.GET_USERS, { params: args })
export const createUser = (payload) => post(url.POST_USERS, payload)
export const editUser = (id, payload) => put(`${url.PUT_USER}/${id}`, payload)
export const deleteUser = (id) => del(`${url.DELETE_USER}/${id}`)

// Tree
export const getTreeList = (args) => get(url.GET_TREE, { params: args })
export const getTree = (id, args) =>
  get(`${url.GET_TREE}/${id}`, { params: args })
export const editTree = (id, payload) => put(`${url.PUT_TREE}/${id}`, payload)
export const hideTree = (id, payload) =>
  put(`${url.PUT_TREE}/${id}/hide`, payload)
export const rejectTree = (id, payload) =>
  put(`${url.PUT_TREE}/${id}/reject`, payload)
export const verifiedTree = (id, payload) =>
  put(`${url.PUT_TREE}/${id}/verify`, payload)
export const reviewTree = (id, payload) =>
  put(`${url.PUT_TREE}/${id}/review`, payload)
export const deleteImages = (id, payload) =>
  put(`${url.PUT_TREE}/${id}/images`, payload)
export const keepHiddenTree = (id, payload) =>
  put(`${url.PUT_TREE}/${id}/keep-hidden`, payload)

// Tree species
export const getAllTreeSpecies = (args) =>
  get(url.GET_TREE_SPECIES, { params: { hits: 999, ...args } })
export const getTreeSpecies = (args) =>
  get(url.GET_TREE_SPECIES, { params: args })
export const editTreeSpecies = (id, payload) =>
  put(`${url.PUT_TREE_SPECIES}/${id}`, payload)

// Static Page
export const getStaticPages = (args) =>
  get(url.GET_STATIC_PAGES, { params: args })
export const getStaticPage = (page) => get(`${url.GET_STATIC_PAGES}/${page}`)
export const editStaticPages = (page, payload) =>
  put(`${url.PUT_STATIC_PAGES}/${page}`, payload)

// FAQ
export const getFaqList = (args) => get(url.GET_FAQ, { params: args })
export const createFaq = (payload) => post(url.POST_FAQ, payload)
export const getFaq = (id, args) =>
  get(`${url.PUT_FAQ}/${id}`, { params: args })
export const editFaq = (id, payload) => put(`${url.PUT_FAQ}/${id}`, payload)
export const deleteFaq = (id) => del(`${url.DELETE_FAQ}/${id}`)

// Tree Issue
export const getTreeIssues = (args) =>
  get(url.GET_TREE_ISSUES, { params: args })
export const getTreeIssue = (id, args) =>
  get(`${url.GET_TREE_ISSUES}/${id}`, { params: args })
export const editTreeIssue = (id, payload) =>
  put(`${url.PUT_TREE_ISSUES}/${id}`, payload)

// app setting
export const getAppSetting = () => get(url.GET_APP_SETTING)
export const editAppSetting = (payload) => put(url.PUT_APP_SETTING, payload)

export {
  getLoggedInUser,
  isUserAuthenticated,
  postJwtRegister,
  postLogin,
  postJwtForgetPwd,
  postJwtProfile,
}

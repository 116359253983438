import React, { useRef, useState, useEffect } from "react"

import BaseLayout from "components/BaseLayout"
import CustomButton from "components/Common/CustomButton"
import { ApiTable } from "components/Common/Table"

import { getTreeIssues } from "helpers/backend_helper"

import {
  TREE_ISSUE_STATUS_DISPLAY,
  ISSUE_CATEGORY_OPTIONS,
  ISSUE_STATUSES,
} from "."

import { Link, useHistory } from "react-router-dom"

const TreeIssueList = () => {
  const tableRef = useRef(null)
  const history = useHistory()
  const [loading, setLoading] = useState(true)

  const columns = [
    { title: "Issue ID", value: "displayId" },
    { title: "Username", value: (row) => row?.user?.username || "" },
    { title: "Issue Report Date", format: "date", value: "createdAt" },
    {
      title: "Issue Status",
      value: (row) => TREE_ISSUE_STATUS_DISPLAY[row?.status],
    },
  ]

  const filters = [
    {
      rowId: 0,
      rowName: "Filter",
      fields: [
        {
          placeholder: "Issue ID (R + digit)",
          name: "displayId",
          type: "text",
          icon: "search",
        },
        {
          placeholder: "Username",
          name: "username",
          type: "text",
          icon: "search",
        },
      ],
    },
    {
      rowId: 1,
      rowName: " ",
      fields: [
        {
          name: "reportDateStart",
          type: "datetime",
          showTime: false,
          returnFormat: "YYYY-MM-DD",
          placeholder: "Report Date Start",
        },
        {
          name: "end",
          type: "datetime",
          showTime: false,
          returnFormat: "YYYY-MM-DD",
          placeholder: "Report Date End",
        },
      ],
    },
    {
      rowId: 2,
      rowName: " ",
      fields: [
        {
          placeholder: "Issue Category",
          name: "category",
          type: "select",
          options: ISSUE_CATEGORY_OPTIONS,
          multiple: true,
          colSpan: 5,
        },
        {
          placeholder: "Issue Status ",
          name: "status",
          type: "select",
          options: ISSUE_STATUSES,
          multiple: true,
        },
      ],
    },
  ]

  async function fetch(params) {
    return getTreeIssues(params)
  }

  // set current page
  function setCurrentPage() {
    const currentPage = tableRef?.current?.state?.currentPage
    localStorage.setItem("tree-issue-page", currentPage)
  }

  useEffect(() => {
    if (
      !loading &&
      localStorage.getItem("tree-issue-page") &&
      localStorage.getItem("locationFrom") === history?.location?.pathname
    ) {
      const page = parseInt(localStorage.getItem("tree-issue-page"), 10)
      tableRef?.current?.handlePageChange(page)
      localStorage.removeItem("tree-issue-page")
      localStorage.removeItem("locationFrom")
    }
  }, [loading])

  return (
    <BaseLayout title="Report Issue Listings">
      <ApiTable
        ref={tableRef}
        name="tree-issue"
        fetch={fetch}
        columns={columns}
        filters={filters}
        setCustomFetchOnMount={setLoading}
        rowButtons={(row) => (
          <Link
            to={{
              pathname: `/tree-issue/${row.id}`,
              state: { from: history.location.pathname },
            }}
          >
            <CustomButton onClick={setCurrentPage} className="btn-outline-dark">
              View
            </CustomButton>
          </Link>
        )}
      />
    </BaseLayout>
  )
}

export default TreeIssueList

import React from "react"
import PropTypes from "prop-types"
import { useHistory, useLocation } from "react-router-dom"
import qs from "query-string"
import { Nav, NavItem, NavLink } from "reactstrap"

const Tabs = props => {
  const { type, tabs, currentTab, onChangeTab, className, errors } = props
  const history = useHistory()
  const location = useLocation()

  const handleChangeTab = tab => {
    onChangeTab(tab)
    if (type === "page") {
      const queryParams = qs.parse(location.search)
      history.push({
        search: qs.stringify({ ...queryParams, page: tab }),
      })
    }
  }

  return (
    <Nav
      tabs
      className={className}
      style={{
        marginBottom: type === "field" ? 0 : type === "form" ? "1rem" : "2rem",
      }}
    >
      {tabs.map(tab => (
        <NavItem key={tab.value} onClick={() => handleChangeTab(tab.value)}>
          <NavLink
            active={tab.value === currentTab}
            className={errors && errors[tab.value] ? "has-error" : ""}
          >
            {tab.label}
          </NavLink>
        </NavItem>
      ))}
    </Nav>
  )
}

Tabs.propTypes = {
  type: PropTypes.string,
  tabs: PropTypes.array.isRequired,
  currentTab: PropTypes.string,
  onChangeTab: PropTypes.func,
  className: PropTypes.string,
  errors: PropTypes.object,
}

Tabs.defaultProps = {
  type: "page",
}

export default Tabs

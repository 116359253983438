import {
  RESET_PASSWORD,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_ERROR,
} from "./actionTypes"

const initialState = {
  resetSuccessMsg: null,
  resetError: null,
}

const resetPassword = (state = initialState, action) => {
  switch (action.type) {
    case RESET_PASSWORD:
      return {
        ...state,
        ...initialState,
      }
    case RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        resetSuccessMsg: action.payload,
      }
    case RESET_PASSWORD_ERROR:
      return {
        ...state,
        resetError: action.payload,
      }
    default:
      return state
  }
}

export default resetPassword

import TreeIssue from "./TreeIssue"
import TreeIssueList from "./TreeIssueList"
import TreeIssueDetail from "./TreeIssueDetail"
import TreeIssueMediaField from "./TreeSubmissionImagesField"
import ReportTreeData from "./ReportTreeData"

export const NEW = "NEW"
export const PROCESSING = "PROCESSING"
export const RESOLVED = "RESOLVED"

export const TREE_ISSUE_STATUS_DISPLAY = {
  [NEW]: "New",
  [PROCESSING]: "Processing",
  [RESOLVED]: "Resolved",
}

export const ISSUE_CATEGORY_OPTIONS = [
  {
    label: "Update Tree Data",
    value: "TREE_DATA",
  },
  {
    label: "Update Tree Condition",
    value: "TREE_CONDITION",
  },
  {
    label: "Inaccurate GPS Location of the Tree",
    value: "GPS_LOCATION",
  },
  {
    label: "Report Protected Tree",
    value: "PROTECTED_TREE",
  },
  {
    label: "Technical Support",
    value: "TECHNICAL_SUPPORT",
  },
  {
    label: "Other",
    value: "OTHER",
  },
]

export const ISSUE_STATUSES = [
  {
    label: TREE_ISSUE_STATUS_DISPLAY[NEW],
    value: NEW,
  },
  {
    label: TREE_ISSUE_STATUS_DISPLAY[PROCESSING],
    value: PROCESSING,
  },
  {
    label: TREE_ISSUE_STATUS_DISPLAY[RESOLVED],
    value: RESOLVED,
  },
]

export const TREE_CONDITION_DISPLAY = {
  cutDown: "Cut Down",
  pestsAndDiseases: "Pests And Diseases",
  typhoon: "Typhoon",
  hillFire: "Hill Fire",
  other: "Other",
}

export {
  TreeIssue,
  TreeIssueList,
  TreeIssueDetail,
  TreeIssueMediaField,
  ReportTreeData,
}

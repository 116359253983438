import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"

import { ApiTable } from "."
import CustomButton from "../CustomButton"

const SearchAndSelectTable = props => {
  const { name, columns, fetch, filters, onAdd, initSelected } = props
  const [selected, setSelected] = useState([])

  useEffect(() => {
    setSelected(initSelected)
  }, [initSelected])

  const handleSelect = (state, value) => {
    // value can be a single row or an array of rows
    setSelected(prevState => {
      let results = _.cloneDeep(prevState)
      if (_.isArray(value)) {
        for (const row of value) {
          results = handleSelectRow(results, state, row)
        }
      } else {
        results = handleSelectRow(results, state, value)
      }
      return results
    })
  }

  const handleSelectRow = (results, state, row) => {
    const idx = _.findIndex(results, { id: row.id })
    if (state === true) {
      if (idx === -1) results = [...results, row]
    } else {
      if (idx !== -1) results.splice(idx, 1)
    }
    return results
  }

  return (
    <>
      <ApiTable
        name={name}
        columns={columns}
        filters={filters}
        fetch={params => fetch({ ...params, hits: 9999 })}
        fetchOnMount={false}
        selectable={true}
        pagination={false}
        headerButtons={
          <CustomButton
            type="api"
            className="btn-success"
            onClick={() => onAdd(selected)}
            disabled={selected.length - initSelected.length === 0}
          >
            Add selected ({selected.length - initSelected.length})
          </CustomButton>
        }
        onSelect={handleSelect}
        selected={selected}
        initSelected={initSelected}
      />
    </>
  )
}

SearchAndSelectTable.propTypes = {
  name: PropTypes.string.isRequired,
  columns: PropTypes.array.isRequired,
  fetch: PropTypes.func.isRequired,
  filters: PropTypes.array,
  onAdd: PropTypes.func,
  initSelected: PropTypes.array,
}

SearchAndSelectTable.defaultProps = {
  initSelected: [],
}

export default SearchAndSelectTable

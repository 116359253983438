import React from "react"
import PropTypes from "prop-types"

import { ApiForm } from "components/Common/Form"

const FaqForm = (props) => {
  const { name, mode, data, onSubmit } = props

  const fields = [
    {
      label:
        "Ordering (It refers to sequence of the FAQ listings, please input different number for every FAQ.)",
      name: "ordering",
      type: "number",
      min: 0,
      required: true,
    },
    {
      label: "Question",
      name: "question",
      type: "textarea",
      multilang: true,
      required: true,
    },
    {
      label: "Answer",
      name: "answer",
      type: "ckeditor",
      multilang: true,
      required: true,
    },
  ]

  return (
    <ApiForm
      mode={mode}
      name={name}
      fields={fields}
      data={data}
      onSubmit={onSubmit}
      style={{ width: "100%" }}
    />
  )
}

FaqForm.propTypes = {
  mode: PropTypes.string,
  name: PropTypes.string.isRequired,
  categories: PropTypes.array,
  data: PropTypes.object,
  onSubmit: PropTypes.func,
}

FaqForm.defaultProps = {
  mode: "create",
}

export default FaqForm
